.fonts-loaded--titillium {
  body.leanbit--new,
  body.leanbit--nosco {
    font-family: $modern-paragraph-font-family;
  }

  body.leanbit--new {
    @include headings {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body.leanbit--new,
body.leanbit--nosco {
  letter-spacing: 0.5px;
}

body.leanbit--new {
  font-size: 18px;

  h2 {
    font-size: 36px;
    margin-bottom: 50px;
  }
}
