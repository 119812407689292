body#iot {
  .label--titleish {
    font-size: 20px;
    background: $primary;
    width: max-content;
    padding: 5px 15px;
    border-radius: 0px 0px 10px 10px;

    strong {
      color: $white;
    }
  }
}

body#iot {
  #hero__image-wrapper {
    position: relative;

    #dashboard-charts-screen {
      width: 90%;
    }

    #softphone-screen {
      position: absolute;
      top: 60%;
      left: 35%;
      width: 60%;
    }
  }
}

@include media-breakpoint-down(sm) {
  body#iot {
    .label--titleish {
      margin-top: 10px;
      border-radius: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  body#iot {
    .label--titleish {
      margin-top: -17px;
    }
  }
}
