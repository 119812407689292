body.leanbit--cases.leanbit--cases-offcar {
  .bg-img-hero {
    background-image: url("../../assets/images/wallpapers/offcar_wallpaper.jpg");
    background-size: contain;
    background-position: top left;
    background-color: #0c0d0f;
    height: 50vh;

    .container {
      display: flex;
      align-items: center;
      height: 100%;

      h3 {
        font-size: 80px;
        text-transform: uppercase;
        text-shadow: 0px 0px 10px black;
        margin-bottom: 0px;
        line-height: 1;
      }
    }
  }
}

.fonts-loaded--source-sans-pro {
  body.leanbit--cases.leanbit--cases-offcar {
    .bg-img-hero {
      .container {
        h3 {
          font-family: $offcar-heading;
        }
      }
    }
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.leanbit--cases.leanbit--cases-offcar {
    .bg-img-hero {
      .container {
        align-items: flex-end;

        h3 {
          font-size: calc(min(8vh, 16vw));
          line-height: 1.2;
        }
      }
    }
  }
}
/*---- END MOBILE ----*/

/*---- MOBILE LANDSCAPE ----*/
@media (max-width: map_get($grid-breakpoints, md)) and (orientation: landscape) {
  body.leanbit--cases.leanbit--cases-offcar {
    .bg-img-hero {
      height: 70vh;

      .container {
        padding-left: 40vw;
        align-items: center;

        h3 {
          font-size: calc(min(15vh, 8vw));
          line-height: 1.2;
        }
      }
    }
  }
}
/*---- END MOBILE LANDSCAPE ----*/

/*---- TABLET ----*/
@include media-breakpoint-only(md) {
  body.leanbit--cases.leanbit--cases-offcar {
    .bg-img-hero {
      height: 40vh;

      .container {
        align-items: center;

        h3 {
          font-size: 60px;
        }
      }
    }
  }
}
/*---- END TABLET ----*/

/*---- TABLET LANDSCAPE ----*/
@media (min-width: map_get($grid-breakpoints, md)) and (max-width: map_get($grid-breakpoints, lg)) and (orientation: landscape) {
  body.leanbit--cases.leanbit--cases-offcar {
    .bg-img-hero {
      height: 70vh;

      .container {
        h3 {
          font-size: calc(min(13vh, 7vw));
          line-height: 1.2;
        }
      }
    }
  }
}
/*---- END TABLET LANDSCAPE ----*/
