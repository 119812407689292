.fonts-loaded--brix-sans
  body.smart-working
    font-family: 'BrixSans', sans-serif

body.smart-working
  font-family: $default-font-family
  h1, h2, h3, h4, h5, h6
    font-weight: 200
  .accordion
    .card
      border-radius: 0
    .card-btn
      color: $primary
      font-weight: normal
      font-size: 20px
      line-height: 22px
    .card-header
      border-bottom: none
    .card-btn-toggle
      font-size: 30px
      line-height: 20px
    .card
      box-shadow: none
    .card-body
      font-size: 15px

  .block
    padding-top: 100px
    padding-bottom: 100px
  .block-1
    padding-top: 170px
    background-size: cover
    h1, h2, h3, p
      color: #ffffff
    h1
      font-size: 50px
    h3
      text-transform: uppercase
      font-size: 20px
    h2
      font-size: 28px
    p
      font-size: 13px
    #aws-badge
      img
        height: 110px
  .block-2
    p
      font-size: 20px
      font-weight: 200
  .block-3
    background-color: $primary
    h2
      color: #ffffff
      font-size: 36px
    .steps
      text-align: center
      img
        margin: 0 auto
        padding: 30px 0
        max-height: 450px
      .step
        position: relative
        top: 50%
        transform: translateY(-50%)
        -webkit-transform: translateY(-50%)
        background-color: #3bafa1
        color: #ffffff
        font-size: 14px
        padding-top: 40px
        height: 120px
        width: 120px
        border-radius: 60px
        display: inline-block
        &.step-big
          background-color: $primary
          border: 5px solid #ffffff
          height: 150px
          width: 150px
          border-radius: 75px
          font-size: 18px
          padding-top: 32px
      .step-wrapper
        position: relative
        height: 100%
        .step-bg
          position: absolute
          height: 100%
          width: 100%
          &::before
            position: absolute
            top: 0
            left: 50%
            height: 100%
            border-left: 1px solid #ffffff
            content: ""
        &.first .step-bg
          height: 50%
          bottom: 0
        &.last .step-bg
          height: 50%
          top: 0

  .block-4
    background-size: cover
    nav
      #nav-tab.nav.nav-tabs
        a.nav-item.nav-link
          border-radius: 0
          color: #ffffff
          background-color: $primary
          border-color: $primary
          text-transform: uppercase
          min-width: 150px
          text-align: center
          &:hover
            background-color: $secondary
            border-color: $secondary
          &.active
            color: $primary
            background-color: #e8f2f1
            cursor: default
            border-color: #e8f2f1
            &:hover
              background-color: #e8f2f1
    .tab-content
      background-color: #e8f2f1
      .left-block
        background-color: #ffffff
        padding: 30px
        height: 100%
        h2
          font-size: 50px
          line-height: 50px
          margin-bottom: 20px
        h4
          text-transform: uppercase
          font-size: 16px
          color: $primary
          font-weight: bold
          margin-bottom: 15px
        p
          font-weight: 200
          margin-bottom: 5px
        ul
          font-weight: 200
          margin-bottom: 0
          padding-left: 20px
      .right-block
        background-color: #e8f2f1
        padding: 50px
        height: 100%
        img
          margin: 0 auto

  .block-5
    p
      color: $primary
      font-size: 26px
      font-weight: 200
    .accordion
      .card-body
        padding: 15px 30px 0

  .block-6
    background-color: #e8f2f1
    h2
      color: $primary
      font-size: 36px
    .small-check
      width: 40px

  .block-7
    h3
      color: $primary
      margin-bottom: 30px
      font-size: 26px
      font-weight: bold
    .left-icon
      height: auto
      width: 100%
      @media (min-width: 576px)
        height: 70px
        width: auto
    .ipad-image
      max-width: 350px
      margin: 0 auto
      width: 100%
    .accordion
      .card-btn
        padding: 0
        font-size: 26px
        padding: 15px 0
        font-weight: 200
      .card-header.card-collapse:hover
        background-color: #ffffff
      .card
        border-bottom: 1px solid $primary
      .card-body
        padding: 5px 0 15px

  .block-personas
    background-color: #f3f8f8
    h2
      color: $primary
    .persona
      img
        width: 100%
        display: block
      .body
        padding: 25px
        h3
          color: #ffffff
          font-size: 28px
          margin-bottom: 0
        h4
          color: #ffffff
          font-size: 15px
          line-height: 24px
          height: 24px
        .btn
          border-radius: 0
      &.romolo
        background-color: #3b445a
      &.laura
        background-color: #d45438

  .block-persona
    background-size: cover
    background-position: 50% 50%
    padding-top: 50px
    padding-bottom: 50px
    display: none
    .close-persona
      color: #bbbbbb
      font-size: 24px
      cursor: pointer
      &:hover
        color: #ffffff
    h4
      color: #ffffff
      font-size: 30px
    p
      color: #ffffff
      font-weight: 200
    &.block-romolo

  .sendgrid-iframe
    iframe
      width: 100%
      height: 600px
      border: none


