body.leanbit--new .carousel {
  --indicators-panel-height: 45px;

  .carousel-indicators {
    margin: 0;
    padding: 0;
    height: var(--indicators-panel-height);
    background: $teal;
    align-items: center;

    li {
      --indicator-size: 11px;
      --indicator-margin-x: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--indicator-size);
      height: var(--indicator-size);
      margin-left: var(--indicator-margin-x);
      margin-right: var(--indicator-margin-x);
      border-radius: 100px;
      background: $white;
      box-sizing: border-box;
      border: none;
    }
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.leanbit--new .carousel {
    .carousel-item {
      background: linear-gradient(90deg, #0c685d, #0e7568, #0e7568);
      min-height: unquote("max(75vh, 60vw)");

      .carousel-caption h2 {
        font-size: 24px;
      }

      img {
        position: absolute;
        bottom: calc(0px + var(--indicators-panel-height));
        right: 0px;
        height: 60%;
      }
    }
  }
}
/*---- END MOBILE ----*/

/*---- MOBILE LANDSCAPE ----*/
@media (max-width: map_get($grid-breakpoints, md)) and (orientation: landscape) {
  body.leanbit--new .carousel {
    .carousel-item {
      min-height: unquote("max(75vh, 50vw)");

      img {
        height: 90%;
      }
    }
  }
}
/*---- END MOBILE LANDSCAPE ----*/
