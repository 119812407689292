#contact_center {
  #omnichannel-inbox-detail {
    background: linear-gradient(0deg, $gray-200, 30%, transparent);
    // margin-bottom: 100px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  #omnichannel-inbox-detail__image-wrapper {
    max-height: 80vh;

    img {
      height: 120vh;
      max-width: unset;
    }
  }
}


