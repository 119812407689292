#explore-nosco-features {
  .card {
    overflow: hidden;
  }

  .card-img-top {
    padding-left: 10px;
    padding-right: 10px;
    height: 225px;
    object-fit: contain;
    border-top: 1px solid $border-color;
  }

  .card-body {
    background: $primary;

    h4 {
      color: $primary;
      color: $white;
      margin-bottom: 0px;
      text-transform: uppercase;
    }
  }

  .nosco-logo {
    width: 80px;
    margin: 0px auto;
  }
}
