body.leanbit--new.leanbit--cases {
  .bg-img-hero {
    height: 50vh;
  }

  h1,
  h2 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .cta-section {
    background: whitesmoke;
    font-size: 1.3rem;

    .lead {
      font-size: 1.3rem;
    }

    .btn.btn-cta {
      font-size: 1.3rem;
    }
  }

  .card-img-top.wp-post-image {
    object-fit: cover;
    object-position: center;
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.leanbit--new.leanbit--cases {
    .bg-img-hero {
      height: 30vh;
    }
  }
}
/*---- END MOBILE ----*/
