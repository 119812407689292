body.leanbit--new,
body.leanbit--nosco,
body.leanbit--solution,
body.leanbit--cases {
  .container-fluid.container--boxed {
    --padding-x: 50px;
    padding-left: var(--padding-x);
    padding-right: var(--padding-x);
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.leanbit--new,
  body.leanbit--nosco,
  body.leanbit--solution,
  body.leanbit--cases {
    .container-fluid.container--boxed {
      --padding-x: 15px;
    }
  }
}
/*---- END MOBILE ----*/
