.fonts-loaded--titillium {
  body.page--amazon-connect {
    h1 {
      font-family: $modern-paragraph-font-family;
    }

    #case-studies {
      .card {
        .card-title {
          font-family: $modern-paragraph-font-family;
        }
      }
    }
  }
}

body.page--amazon-connect {
  #hero {
    // height: calc(100vh - var(--header-height));
    color: $white;
    padding: 40px;
    font-size: 22px;
    background-image: linear-gradient(45deg, #173d52, transparent);

    /* alternative 1 */
    // background-image: linear-gradient(45deg, #173d52, transparent),
    //   url(https://images.unsplash.com/photo-1604869515882-4d10fa4b0492?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80);
    // background-size: 100%, 200%;
    // background-blend-mode: normal, luminosity;
    // background-repeat: no-repeat;

    /* alternative 2 (requires 1)*/
    // background-position: bottom;

    /* alternative 3 (requires 1)*/
    // background-size: 100%, 120%;
    // background-repeat: repeat-y;
    // background-position-y: 0px, 360px;

    h1 {
      @extend %great-heading;
    }

    p.lead {
      font-size: 24px;
      padding-bottom: 10px;
    }

    ul {
      list-style-type: square;

      li {
        padding-bottom: 8px;
      }
    }

    p,
    li {
      letter-spacing: 0.5px;
    }
  }

  .amazon-partner-image.amazon-partner-image--with-shadow {
    box-shadow: 0px 2px 14px -1px rgba(0, 0, 0, 0.18);
    margin: 0px 45px;
  }
}

body.page--amazon-connect {
  #values {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "excellence-text excellence-image" "reliability-image reliability-text";

    #excellence-text {
      grid-area: excellence-text;
    }

    #excellence-image {
      grid-area: excellence-image;
    }

    #reliability-text {
      grid-area: reliability-text;
    }

    #reliability-image {
      grid-area: reliability-image;
    }

    .checker__box {
      width: 100%;

      &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
      }

      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }

    .checker__box:nth-child(odd) {
      text-align: right;
    }

    .checker__box--text {
      padding: 40px;
      color: $text-color;

      .text__content {
        transform: translateY(50%);
      }

      h2 {
        text-transform: uppercase;
      }
    }

    .checker__box--image {
      figure {
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

body.page--amazon-connect {
  #nosco {
    position: relative;
    z-index: 1;

    p {
      font-size: 21px;
    }

    .hexagon--outline {
      height: 30vw;
      position: absolute;
      right: 0px;
      bottom: calc(100px - 25vw);

      img {
        height: 100%;
      }
    }
  }

  #nosco-features {
    margin-top: -100px;
    padding-top: 100px;
    position: relative;

    .hexagon--omnichannel-inbox {
      position: absolute;
      width: 26vw;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      filter: drop-shadow(2px 3px 10px rgba(0, 0, 0, 0.11));
    }

    .nosco-feature {
      $nosco-simbol-size: 30px;

      h4 {
        position: relative;
        font-size: 50px;
        font-weight: 700;
      }

      h4:before {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        top: calc(50% + 2px);
        background-image: url("../svg/logos/nosco_logo_symbol.svg");
        transform: translate(calc(-100% - 10px), -50%);
        height: $nosco-simbol-size;
        width: $nosco-simbol-size;
      }

      p {
        font-size: 20px;
      }
    }

    .nosco-features__cta-line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 22px;
      }

      .btn {
        width: max-content;
        white-space: nowrap;
      }
    }
  }
}

body.page--amazon-connect {
  #case-studies {
    background-image: linear-gradient(0deg, $primary--light, $primary--light 60%, transparent 0%);

    h2 {
      font-size: 56px;
    }

    .card {
      color: $text-color;
      border-radius: 0px;

      @include headings {
        color: $primary;
      }

      .card-title {
        text-transform: uppercase;
        font-size: 18px;
      }
      .card-text {
        font-size: 16px;
      }

      figure {
        img {
          height: 150px;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .card:nth-child(even) {
      figure {
        // float: left;
        // padding: 0px 20px 30px 0px;
      }
    }

    .card:nth-child(odd) {
      figure {
        // float: right;
        // padding: 0px 30px 30px 50px;
      }
    }
  }
}

body.page--amazon-connect {
  #event {
    background: $teal--light-200;

    h2 {
      color: $primary--dark;
    }

    p,
    li {
      font-size: 16px;
    }

    figure {
      position: relative;
      overflow: hidden;
      width: 100%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #d0e6e1;
        // background: $teal--light-200;
        z-index: 1;
        mix-blend-mode: soft-light;
        opacity: 1;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: scale(1.3);
      // mix-blend-mode: luminosity;
      filter: grayscale(0.8) brightness(1.1) contrast(0.9);
    }
  }
}

body.page--amazon-connect {
  #cta-block {
    p {
      font-size: 27px;
      font-weight: 400;
    }

    a {
      font-weight: 700;
    }
  }
}

/*---- DESKTOP XL ----*/
@include media-breakpoint-up(xl) {
  body.page--amazon-connect {
    #nosco {
      .hexagon--outline {
        // height: 526.3px;
        position: absolute;
        // right: calc(-1482px - (1/5 * -300vw));
        // bottom: calc(-250px - 50vw);

        img {
          height: 100%;
        }
      }
    }
  }
}
/*---- END DESKTOP XL ----*/

/*---- TABLET DOWN ----*/
@include media-breakpoint-down(md) {
  body.page--amazon-connect {
    #nosco-features {
      .hexagon--omnichannel-inbox {
        position: relative;
        width: 95%;
        margin: auto;
        left: 0px;
        top: 0px;
        transform: unset;
        filter: drop-shadow(2px 3px 10px rgba(0, 0, 0, 0.11));
      }
    }
  }
}
/*---- END TABLET DOWN ----*/

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.page--amazon-connect {
    #hero {
      min-height: calc(100vh - var(--header-height));
      height: auto;
      padding: 20px 0px;
      font-size: 16px;

      h1 {
        line-height: 1.2;
        font-size: 28px;
        letter-spacing: 1.2px;
        margin-bottom: 30px;
      }

      p.lead {
        font-size: 16px;
        padding-bottom: 10px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  body.page--amazon-connect {
    #values {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: "excellence-text" "excellence-image" "reliability-text" "reliability-image";

      .checker__box:nth-child(odd) {
        text-align: center;
      }

      .checker__box--text {
        .text__content {
          transform: translateY(10%);
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  body.page--amazon-connect {
    #nosco {
      h2 {
        font-size: 35px;
        line-height: 1.2;
      }

      p {
        font-size: 18px;
      }
    }

    #nosco-features {
      .nosco-feature {
        margin-bottom: 30px;

        h4 {
          font-size: 35px;
          line-height: 1.2;
        }
      }

      .nosco-features__cta-line {
        flex-direction: column;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  body.page--amazon-connect {
    #case-studies {
      .card:nth-child(odd) {
        figure {
          float: none;
          padding: 0px 0px 24px 0px;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  body.page--amazon-connect {
    #cta-block {
      p {
        font-size: 24px;
        font-weight: 400;
      }

      a {
        font-weight: 700;
      }
    }
  }
}
/*---- END MOBILE ----*/
