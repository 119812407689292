body.leanbit--new {
  .btn.btn--cta {
    letter-spacing: 0.5px;
    font-size: 22px;

    &.btn--special {
      background: $primary;
      box-shadow: 0 4px 14px rgba(56, 137, 152, 0.3);
      border: none;
      transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
      color: $white;

      &:hover {
        background: linear-gradient(45deg, hsl(184, 100%, 94%), white);
        box-shadow: 0 4px 28px rgba(48, 255, 252, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
        color: $primary;
      }
    }
  }

  [class*="btn-outline"] {
    border-width: 2px;
  }

  .btn-outline-light {
    &:hover {
      color: $primary
    }
  }
}
