body.page--workforce {
  #omnichannel-inbox-detail {
    background: linear-gradient(0deg, $gray-200, 30%, transparent);
    // margin-bottom: 100px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  #omnichannel-inbox-detail__image-wrapper {
    max-height: 80vh;

    img {
      height: 80vh;
      max-width: unset;
    }
  }

  #worklog-reminder-wrapper {
    background: #d9efec;
    border-radius: 10px;

    img {
      box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.10);
    }
  }
}
