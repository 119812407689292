body.page--smart-working {
  #hero {
    @include media-breakpoint-down(md) {
      margin-right: auto;
      min-width: auto;
    }
    #hero__image-wrapper {
      img {
        height: 260px;
        transform: scale(2);
        @include media-breakpoint-down(md) {
          transform: scale(1.5);
        }
      }
    }
  }
}
