footer {
  background-color: $primary-darker;
  color: $white;

  .links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    font-size: 14px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px;
      height: 48px;
      font-size: 14px;
      color: #ffffff;

      &:hover,
      &:focus,
      &:active {
        color: #ffffff;
        text-decoration: underline;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  footer {
    .links {
      display: flex;
      justify-content: center;

      a {
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  footer {
    .links {
      a {
        margin: 0 15px;
      }
    }
  }
}
