.fonts-loaded--brix-sans {
  body.leanbit--nosco {
    @include headings {
      font-family: $title-font-family;
    }
  }
}
body.page--nosco-overview {
  #overview-image-block {
    $isometric-bg: $gray-100;
    $isometric-grid-color: #e6efee;

    background:
      linear-gradient(to bottom, rgba($isometric-grid-color, 1), rgba(0,0,0,0) 20%, rgba(#19a191, 0.2) 100%),
      // radial-gradient(ellipse at center, rgba($isometric-grid-color, 0) 0%, rgba($isometric-grid-color, 0) 100%),
      repeating-linear-gradient(150deg, $isometric-grid-color, $isometric-grid-color 1px, transparent 2px, transparent 80px) 0 0,
      repeating-linear-gradient(30deg, $isometric-grid-color, $isometric-grid-color 1px, transparent 2px, transparent 80px) 0 0;
    background-size: 100%, 100%,160px 93px,160px 93px;
    background-color: $isometric-bg;
    box-shadow: inset 0px 0px 200px 0 rgba(0, 0, 0, 0.12);
  }
}
body.page--nosco-overview {
  font-family: $default-font-family;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: 100;
  }
  h2 {
    margin-bottom: 8px;
    font-size: 30px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .accordion {
    .card {
      background-color: transparent !important;
      border-radius: 0;
    }
    .card-btn {
      color: $primary;
      font-size: 20px;
      line-height: 22px;
    }
    .card-header {
      background-color: transparent !important;
      border-bottom: none;
    }
    .card-btn-toggle {
      font-size: 30px;
      line-height: 20px;
    }
    .card {
      box-shadow: none;
    }
    .card-body {
      font-size: 15px;
    }
  }
  .hidden {
    display: none;
  }
  .block {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .block-1 {
    background-color: $primary;
    padding-bottom: 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: white;
    }
    h1 {
      font-size: 8vw;
    }
    h2 {
      font-size: 4vw;
    }
    .persona {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      overflow: hidden;
    }
    @media (min-width: 1000px) {
      h1 {
        font-size: 100px;
      }
      h2 {
        font-size: 60px;
      }
    }
    @media (max-width: 1000px) {
      .persona {
        height: 240px;
      }
    }
    @media (max-width: 560px) {
      .persona {
        height: 200px;
      }
    }

    .personas {
      * {
        margin: 0;
        padding: 0;
      }
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .block-2 {
    background-size: cover;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    i {
      color: white;
    }
    h2,
    i {
      font-size: 45px;
    }
    a {
      cursor: pointer;
    }
    p {
      font-size: 25px;
    }
    strong {
      color: $primary-lighter;
    }
    .cloud-io-img {
      max-height: 300px;
      margin: auto;
    }
    .slick-slide {
      &.slick-active > div {
        width: 100%;
      }
    }
  }
  .block-3 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $primary;
    }
    .brochure {
      color: $primary;
    }
    .btn:hover {      // background-color: #eaf0ef
      // color: white
    }
  }
}

body.page--nosco-overview {
  .block-4 {
    background-color: #e8f2f1;
    a {
      cursor: pointer;
      font-weight: 100;
    }
    .btn:hover {
      color: white;
    }
    h2 {
      color: $primary;
    }
    .card-header {
      border-top: 1px solid $primary;
    }
    .card-body {
      .card-image {
        max-height: 50px;
        margin-bottom: 10px;
      }
    }
    .brochure {
      color: $primary;
      font-weight: normal;
    }
  }
  .block-5 {
    background-size: cover;
    h2 {
      strong {
        color: $primary-lighter;
      }
    }
    strong {
      color: $primary;
    }
    .prephrases {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: white;
      }
    }
    .system-description {
      padding: 35px 30px 0 30px;
      .arrows {
        position: absolute;
        bottom: 10px;
      }
      @media (max-width: 1000px) {
        .arrows {
          position: relative;
        }
      }
      .persona {
        height: 200px;
      }
      i {
        font-size: 30px;
      }
    }
    .system-image {
      background-color: #e8f2f1;
    }
    .brochure {
      border-color: white;
      color: white;
      margin: auto;
    }
  }
  .block-6 {
    h2 {
      color: $primary;
    }
    h1,
    h3,
    h4,
    h5,
    h6,
    p {
      color: white;
    }
    .persona {
      .picture {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .body {
        padding: 25px;
        h3 {
          font-size: 28px;
          margin-bottom: 0;
        }
        h4 {
          font-size: 15px;
          line-height: 24px;
          height: 24px;
        }
        .btn {
          border-radius: 0;
        }
        &.yellow-bg {
          background-color: #e39c45 !important;
        }
        &.green-bg {
          background-color: #19a191;
        }
        &.orange-bg {
          background-color: #d45438;
        }
        &.blue-bg {
          background-color: #3b445a;
        }
      }
    }
    .brochure {
      color: $primary;
    }
  }
  .block-persona {
    background-size: cover;
    background-position: 50% 50%;
    padding-top: 50px;
    padding-bottom: 50px;
    display: none;
    position: relative;
    .close-persona {
      color: #bbbbbb;
      font-size: 24px;
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
    h4 {
      color: #ffffff;
      font-size: 30px;
    }
    p {
      color: #ffffff;
      font-weight: 200;
    }
  }
}

body.page--nosco-overview#nosco-home {
  .block-3 {
    .btn:hover {
      background-color: #eaf0ef;
    }
  }
  .block-6 {
    .btn:hover {
      color: white;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
