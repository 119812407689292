.fonts-loaded--titillium {
  body.leanbit--new {
    font-family: $modern-paragraph-font-family;
  }
}

body.leanbit {
  @media (max-width: 768px) {
    .pagination .page-item :not([rel=prev]):not([rel=next])  {
      display: none;
    }
  }

  .block {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .white-background {
    background-color: #fff5;
  }

  .white {
    color: white;
  }

  .max-width-8 {
    max-width: 3.5rem;
  }
}

body.leanbit--new {
  padding-top: var(--header-height);

  @include headings {
    color: $primary;
  }

  .bg-dark,
  .bg-primary,
  .bg--primary-dark {
    color: $white;

    @include headings {
      color: $white;
    }
  }

  .bg-light,
  .bg--light,
  .bg--primary-light {
    @include headings {
      color: $primary;
    }
  }

  .card {
    color: $text-color;

    @include headings {
      color: $primary;
    }
  }

  .bg--light {
    background-color: #e5f3f0;
  }

  .bg--primary-light {
    background-color: $primary--light;
  }

  .bg--primary-dark {
    background-color: $primary--dark;
  }
}

body.leanbit--new {
  p {
    strong {
      font-weight: 600;
      color: inherit;
    }
  }
}
