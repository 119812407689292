$text-shadow--light-paragraph: 1px 1px 5px rgba(0, 0, 0, 0.30);

%event {
  // overflow-x: hidden;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $primary;
  }
  .image {
    &.image--rounded {
      border-radius: 6px;
      overflow: hidden;
    }
    &.image--shadow {
      box-shadow: 0px 6px 14px -5px hsla(169, 100%, 11%, 0.22);
    }
  }
}

%event {
  .row.row--edge-to-edge {
    position: relative;
    // position: absolute
    margin-left: -50vw;
    left: 50%;
    width: 100%;
    height: 100%;
  }
  .bg--primary {
    background: $primary; //#e4f4f0;
    color: $white;
    text-shadow: $text-shadow--light-paragraph;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    strong,
    p {
      color: $white;
      text-shadow: $text-shadow--light-paragraph;
    }

    .image {
      &.image--shadow {
        box-shadow: 0px 12px 17px -10px hsla(170deg, 89%, 11%, 0.41);
      }
    }
  }
}

%event {
  #company-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .event__date-and-time,
  .event__date-and-time span {
    font-weight: bold;
    font-size: 20px;
  }
}

%event {
  @include media-breakpoint-down(sm) {
    .h1--smaller {
      font-size: 27px;
    }
    p.lead {
      font-size: 16px;
    }
    #company-logo {
      img {
        width: 70%;
      }
    }
    #hero-image {
      position: relative;
      z-index: 1;
    }

    .badge--evento-online {
      margin-top: 10px;
    }
  }
}

%event {
  @include media-breakpoint-up(md) {
    .h1--smaller {
      font-size: 32px;
    }
    #company-logo {
      padding-top: 40px;
      padding-bottom: 30px;

      img {
        width: 50%;
      }
    }
    #hero-image {
      position: relative;
      z-index: 1;
    }
  }
}

%event {
  #hero-container {
    background: linear-gradient(-45deg , transparent 5%, #008073 0%, #008073 12%, transparent 0, transparent 70%, #008073 70%, #008073 80%, transparent 0, transparent 100%);
    box-shadow: inset 0 -18px 50px -50px;
    padding-bottom: 40px;
  }

  #hero__callout {
    background: white;
    padding: 30px;
    /* border: 2px solid $primary; */
    box-shadow: 0 10px 36px -15px rgba(0, 0, 0, 0.32);
    border-radius: 20px;
  }
}

%event {
  .btn--cta {
    font-size: 20px;
  }

  .btn--special {
    background: linear-gradient(45deg, #ec8f23, #ffc646);
    box-shadow: 0 4px 14px rgba(255, 155, 41, 0.30);
    border: none;
    transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
    color: $white;
    text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.30);

    &:hover {
      // background: linear-gradient(45deg, hsl(49, 100%, 94%), white);
      // box-shadow: 0 4px 28px rgba(255, 155, 41, 0.45)
      box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, .1);
      // color: orange;
    }
  }
}

%event {
  .w--fit-content {
    width: fit-content;
  }

  .w--max-content {
    width: max-content;
  }
}

%event {
  font-size: 18px;
  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 15px;
  }
}

%event {
  .js-slick-carousel {
    .js-slide {
      .card {
        svg {
          #accent-color * {
            fill: #ffc646;
          }
          #base-color * {
            fill: $gray-700;
          }
        }
      }
    }
  }
}

%event {
  @include media-breakpoint-down(md) {
    .js-slick-carousel {
      img {
        height: 100px;
        object-fit: scale-down;
      }
      .js-slide {
        .card {
          height: 100%;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          box-shadow: 0 8px 50px -5px rgba(0, 0, 0, 0.18);
        }
        figure {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .js-slick-carousel__background {
      opacity: 0;
    }
  }
}

%event {
  .badge--evento-online {
    font-size: 14px;
  }
}
%event {
  @include media-breakpoint-up(lg) {
    .js-slick-carousel {
      display: flex;
      justify-content: space-between;

      img {
        height: 100px;
        object-fit: scale-down;
      }
      .js-slide {
        width: 30%;
        font-size: 16px;
        height: auto;

        .card {
          height: 100%;
          box-shadow: 0 8px 145px -5px rgba(0, 0, 0, 0.18);
        }
        figure {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .js-slick-carousel__background {
      box-shadow: 0px 50px 270px -80px hsla(160deg, 65%, 28%, 0.5);
    }
  }
}

body.page--event {
  @extend %event;
}
