body.leanbit.bulloni_digitali {
  footer {
    background-color: white;
    color: gray;
    font-size: 0.5em;
    margin-top: 3rem;
  }

  .more {
    font-family: cursive;
    color: #9179EF;
  }

  h1.h1 {
    margin-top: 3rem;
    max-width: 800px;
    margin-left: auto;    
    margin-right: auto;
    color: #9179EF;
    font-family: 'KarmaticArcade', magrai;
  }

  header.header {
    #navBar .navbar-body{
      margin-left: auto;
      margin-right: 0;
    }
    .navbar .nav-link {
      color: #9179EF;
    }
  }

  .navbar-brand, .navbar-brand > img {
    width: 15rem;
  }

  main#content {
    min-height: 460px;
  }

  .chat--gigi-mario {
    $common-margin-top: 10px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

    .chat__element {
      overflow: unset;
      background: $white;
      border-bottom: none;
      margin-bottom: 15px;

      .element__avatar {
        grid-area: avatar;
        margin-top: $common-margin-top;
        width: 36px;
        height: 36px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 20%;
        }
      }
      .element__name {
        grid-area: name;
        // font-size: 14px;
        white-space: nowrap;
        margin-top: $common-margin-top;
      }
      .element__text {
        grid-area: text;
        // font-size: 14px;
      }
      .element__time {
        grid-area: time;
        display: flex;
        align-items: center;
        margin-top: $common-margin-top;
        color: $text-muted-color;
        font-size: 13px;

        .time__wrapper {
          width: max-content;
        }
      }
      .element__actions {
        grid-area: actions;
        position: relative;
        float: none;
        @include btn-circle();

        i::before {
          color: $gray-500;
        }
      }
      &.chat__element--editing {
        .element__actions {
          display: none;
        }
        .element__text {
          display: none;
        }
        .element__form {
          grid-area: text;

          form {
            margin-bottom: 10px;
          }
          .form-group {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .chat--gigi-mario {
    .chat__element + .chat__element {
      border-top: 1px solid $border-color;
      margin-top: 0px;
      padding-top: 10px;
    }
    .chat__element .element__text p:last-child {
      margin-bottom: 0px;
    }
  }

  .chat--gigi-mario {
    .chat__element {
      display: grid;
      grid-template-areas: "avatar name time actions" "avatar text text text";
      grid-template-columns: max-content min-content 1fr max-content;
      grid-gap: 0px 10px;
      position: relative;
    }
  }
}
