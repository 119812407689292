body.page--nosco-kitchen {
  .hero-container {
    background-image: none;
    background: #272e3a;
    border-bottom: 20px solid #e7270c;
  }

  .btn--cta {
    border-radius: 2px;
  }

  #engine-title #engine-title__logo + #engine-title__name {
    font-size: 22px;
  }

  #engine-title__logo {
    svg {
      height: 40px;

      path {
        // fill: $white !important;
      }
    }
  }

  #hero-screen {
    border-radius: 10px;
  }

  .img--with-shadow {
    border-radius: 2px;
    box-shadow: 0px 25px 40px -25px;
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.page--nosco-kitchen {
    .hero-container {
      h1 {
        font-size: 36px;
      }
    }
  }
}
/*---- END MOBILE ----*/
