#header {
  .navbar-nav-item {
    a {
      i {
        margin-right: 5px;
      }
    }

    &.nav-demo-button {
      .nav-link {
        color: $primary;
      }
    }
  }

  .navbar--always-visible {
    .nav-demo-button .btn{
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

@include media-breakpoint-down(md) {
  #header {
    .navbar-nav-item {
      &.nav-demo-button {
        .btn {
          margin-top: 5px;
        }
      }
    }
  }
}
