.fonts-loaded--titillium {
  body.leanbit--new,
  body.leanbit--nosco {
    footer {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body.leanbit {
  footer {
    font-size: 18px;
    background-color: $primary--dark;

    svg.brand {
      #text {
        fill: $white;
      }

      #payoff {
        fill: $white;
        opacity: 0.8;
      }
    }

    .links {
      display: flex;
      justify-content: center;
      grid-auto-flow: column;
      font-size: 14px;

      a {
        &:hover {
          text-decoration: none;
        }
      }

      &.links--social {
        i {
          font-size: 28px;
        }
      }
    }
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.leanbit {
    footer {
      .links {
        &.links--social {
          display: flex;
          flex-direction: column;
          width: max-content;
          margin: 0 auto;

          a {
            justify-content: flex-start;

            i {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
            }

            .link__text {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

/*---- END MOBILE ----*/
