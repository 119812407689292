// NOTE: do not change order
@import "../../vendor/front_theme/hs-mega-menu/src/scss/hs-mega-menu";
@import "~aos/src/sass/aos";
@import "~bootstrap/scss/functions";
@import "vendor/front_theme/front/variables";
@import "overrides/front_variables_overrides";
@import "~bootstrap/scss/bootstrap";
@import "vendor/front_theme/front/front";
//--

@import "base/base";
@import "base/typography";

@import 'trumbowyg/dist/ui/trumbowyg.min';

@import "components/image";
@import "components/header";
@import "components/footer";

@import "smart_working";

@import "pages/event";
@import "pages/san_marco_2_dicembre.sass";
@import "pages/offcar_10_giugno_2021";

@import "nosco";
@import "blog";
@import "leanbit";
@import "bulloni_digitali";

@import "pages/landings";
@import "pages/solutions";
@import "pages/solutions/customer_service";
@import "pages/solutions/effective_communication";
@import "pages/solutions/smart_working";
@import "pages/nosco_landing";
@import "pages/nosco_pricing";
@import "pages/contact_center_landing";
@import "pages/nosco_kitchen";
@import "pages/workforce";
@import "pages/iot";


.product-keywords-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  .product-keyword + .product-keyword {
    display: flex;
    margin-left: 10px;

    &::before {
      content: '/';
      display: flex;
      margin-right: 10px;
    }
  }
}
