.fonts-loaded--brix-sans {
  body.leanbit--solution {
    @include headings {
      font-family: $title-font-family;
    }
  }
}

.fonts-loaded--titillium {
  body.leanbit--solution {
    p {
      font-family: $modern-paragraph-font-family;
    }

    .block.cta-section {
      .btn.btn--cta {
        font-family: $modern-paragraph-font-family;
      }
    }
  }
}

body.leanbit--solution #blog-posts {
  .card-img-top {
    height: 215px;
    object-fit: cover;
  }
}

body.leanbit--solution {
  background: $gray-200;
}

body.leanbit--solution {
  #hero {
    background: $white;
    position: relative;
    z-index: 1;
    min-width: 95vw;
    box-shadow: 0 15px 36px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(md) {
      margin-right: auto;
      min-width: auto;
    }

    p.lead {
      font-size: 20px;
    }

    #hero__image-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        // height: 260px;
        // transform: scale(2.3);

        @include media-breakpoint-down(md) {
          // transform: scale(1.5);
          margin-top: 20px;
        }
      }

      svg {
        height: 260px;

        path {
          fill: $primary;
        }
      }
    }
  }
}

body.leanbit--solution {
  #subhero {
    p {
      font-size: 26px;
    }
  }
}

body.leanbit--solution {
  h2 {
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 10px;
  }

  h1 {
    color: $primary;
    font-size: 38px;
  }

  p.lead {
    font-size: 24px;

    &.lead--subtitle {
      color: $subtitle-gray;
      font-weight: bolder;
    }
  }

  p {
    font-size: 18px;
  }

  p strong {
    font-weight: bold;
    color: $primary;
  }

  p {
    font-family: $default-font-family;
  }
}

body.leanbit--solution {
  p.quote {
    padding: 15px 12px;
    border-left: 12px solid $gray-300;
    font-style: italic;
    font-size: 20px;
  }

  #problems p.quote {
    border-color: $comet-blue;
  }

  #answers p.quote {
    font-style: normal;
    font-weight: 600;
    color: $primary;
    border-color: $primary;
  }
}

body.leanbit--solution {
  .block--raised {
    position: relative;
    z-index: 1;
  }

  .block--beneath {
    position: relative;
    z-index: 0;
  }
}

body.leanbit--solution {
  #numbers {
    .card-header h4 {
      font-size: 58px;
      font-weight: 600;
    }
  }

  #business-critical, .container--right-angled-band {
    background-image: linear-gradient(-60deg, #009e8e 50%, transparent 0%);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: right top;

    @include media-breakpoint-down(lg) {
      background-size: 50%;
    }

    @include media-breakpoint-down(md) {
      background-image: none;
    }
  }
}

body.leanbit--solution {
  #problems > .row {
    background-image: linear-gradient(90deg, $comet-blue 15%, transparent 0%);

    @include media-breakpoint-down(xs) {
      background-image: none;
    }
  }
}

body.leanbit--solution {
  #answers > .row {
    background-image: linear-gradient(90deg, transparent 85%, $primary 0%);

    @include media-breakpoint-down(xs) {
      background-image: none;
    }
  }
}

body.leanbit--solution {
  #digital-tools {
    background-image: linear-gradient(60deg, #009e8e 30%, transparent 0%);

    @include media-breakpoint-down(md) {
      background-image: none;
    }
  }
}

body.leanbit--solution {
  .isometric-grid-bg {
    $isometric-bg: $primary;
    $isometric-grid-color: #e6efee;
    background:
      // linear-gradient(to bottom, rgba($white, 1), rgba(0,0,0,0) 20%, rgba($white, 1) 100%),
      radial-gradient(ellipse at center, rgba($isometric-bg, 0) 0%, rgba($white, 1) 80%),
      repeating-linear-gradient(150deg, $isometric-grid-color, $isometric-grid-color 1px, transparent 2px, transparent 80px) 0 0,
      repeating-linear-gradient(30deg, $isometric-grid-color, $isometric-grid-color 1px, transparent 2px, transparent 80px) 0 0;
      // radial-gradient(ellipse at center, rgba($isometric-bg, 0.2) 0%, rgba($white, 1) 100%);
  }
}

body.leanbit--solution {
  .block.cta-section {
    background: none;

    .container > div {
      width: 100% !important;
    }

    .btn {
      border-radius: 10px;
    }

    .btn.btn--cta {
      font-weight: bold;
      text-transform: uppercase;
      font-family: $default-font-family;

      &.btn--special {
        background: linear-gradient(45deg, #ec8f23, #ffc646);
        box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
        border: none;
        transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
        color: $white;

        &:hover {
          box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
