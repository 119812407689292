.fonts-loaded--titillium {
  body.leanbit--new,
  body.leanbit--nosco,
  body.leanbit--solution,
  body.leanbit--cases,
  body.leanbit--blog {
    header.header {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body.leanbit--new,
body.leanbit--nosco,
body.leanbit--solution,
body.leanbit--cases,
body.leanbit--blog {
  header.header {
    height: var(--header-height);

    .navbar {
      height: var(--header-height);

      a {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .nav-link {
        height: var(--header-height);
        display: flex;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px;
        // color: $primary;
        font-size: 16px;

        &.active {
          font-weight: 600;
        }

        &.dropdown-toggle::after {
          margin-top: 3px;
          font-family: "Font Awesome 6 Free";
        }
      }
    }

    #navBar {
      .navbar-body {
        margin-right: auto;
        margin-left: 100px;
      }
    }
  }
}

/*---- DESKTOP ----*/
@include media-breakpoint-up(lg) {
  body.leanbit--new,
  body.leanbit--nosco,
  body.leanbit--solution,
  body.leanbit--cases {
    .nav-link {
      padding: 0px 20px;
    }
  }
}
/*---- END DESKTOP ----*/
