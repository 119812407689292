$h2_size: 30px;
$strong-text-color: $primary-darker; //#519089; //#1d5858;
$paragraph-text-color: #555555; //#343c3c;// #4c5757;

$hero-shadow: 0px 6px 14px -5px hsla(169, 100%, 11%, 1);

.fonts-loaded--titillium {
  body#nosco-landing {
    h2,
    p,
    .btn {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body#nosco-landing {
  .block {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .btn {
    border-radius: 8px;

    &.btn-warning {
      color: #806a6a;
    }
  }

  h2,
  p,
  .btn {
    font-family: $default-font-family;
  }

  p {
    color: $paragraph-text-color;
  }

  h1 {
    font-size: unquote("min(8vw, 6vh)");
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: 400;
  }

  h1 {
    line-height: 1.2;
  }

  h2 {
    font-weight: 400;
  }

  .hero__title {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .hero__subtitle {
    margin-bottom: 20px;
  }

  .hero__logo-wrapper {
    display: inline-flex;
    height: 35px;
    // background: $white;
    padding: 6px 0px;
    margin-right: 2px;
    border-radius: 100px;
    // box-shadow: $hero-shadow;
    vertical-align: -4px;

    img {
      height: 100%;
    }
  }
  .row.row--edge-to-edge {
    position: relative;
  }

  .js-slick-carousel {
    .js-slide {
      .card {
        min-height: 270px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 25px 20px rgba(75,75,75,.2);
        background: $white;
        border: 1px solid #efefe0;
        h3 {
          color: $strong-text-color;
          font-size: 28px;
        }
        h4 {
          color: $strong-text-color;
        }
        b, li {
          font-size: 18px;
          font-weight: 400;
          color: $paragraph-text-color;
        }
      }
    }
    .js-slick-carousel__background {
      opacity: 0
    }
  }
}

body#nosco-landing {
  .navbar-toggler {
    margin-left: 10px;
  }
}

body#nosco-landing .screen{
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
  box-shadow: 0px 6px 14px -5px hsla(169, 100%, 11%, 0.22), 0 0 3px hsla(169, 100%, 11%, 0.22);
}

body#nosco-landing {
  .btn {
    &.btn--special {
      background: linear-gradient(45deg, #ec8f23, #ffc646);
      box-shadow: 0 4px 14px rgba(255, 155, 41, 0.30);
      border: none;
      transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
      color: $white;

      &:hover {
        box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, .1);
      }
    }
  }
}

.fonts-loaded--titillium {
  body#nosco-landing {
    .block-1 .benefits {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body#nosco-landing {
  .block-1 {
    background-position: center 32px;
    background-size: 150vw 100vw;
    background-image: url("../images/wallpapers/hexagon.png");
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, hsla(218, 96%, 9%, 0.65), transparent);
    }

    .container {
      position: relative;
      z-index: 1;
    }

    .text-shadow {
      text-shadow: 1px 1px 1px rgb(0, 84, 69);
    }

    .benefit__icon {
      @include size(150px);
      @include border-radius(8px);
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 6px 14px -5px hsla(169, 100%, 11%, 1);
      margin-bottom: 25px;
      position: relative;

      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 14%;
        left: 14%;
        width: 72%;
        height: 22%;
        background: linear-gradient(0, rgba(255, 255, 255, 0.3), transparent);
        border-radius: 0 0 25px 25px;
      }

      img,
      svg {
        @include size(80%);
        padding: 18px;
        border-radius: 8px;
        background: linear-gradient(0deg, hsl(0, 0, 92%), hsl(0, 0, 98%));
        box-shadow: inset 0px 0px 8px #0000002b;
        margin: 0;
      }

      svg {
        #accent-color * {
          fill: hsl(170, 60%, 69%);
        }
        #base-color * {
          fill: $gray-700;
        }
      }
    }

    .hero__action-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      width: 100%;
      z-index: 1;

      .hero__action {
        border-radius: 8px;
        padding: 20px 50px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        background: linear-gradient(45deg, #ec8f23, #ffc646);
        box-shadow: 0 4px 18px rgba(255, 155, 41, 0.45);
        border: none;
        color: $white;
        transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
        opacity: 1;

        &:hover {
          background: linear-gradient(45deg, hsl(49, 100%, 94%), white);
          box-shadow: 0 4px 28px rgba(255, 155, 41, 0.45);
          color: orange;
        }
      }
      .hero__action + .hero__action {
        margin-left: 30px;
      }
    }

    .benefits {
      margin-top: 30px;
      margin-bottom: 10px;
      font-family: $default-font-family;
    }

    .benefit {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 18px;

      span {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

body#nosco-landing {
  #san-marco-case-block {
    .figure {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 10px;
        filter: opacity(0.9) sepia(0);
        box-shadow: 0px 6px 34px -5px hsla(0, 0%, 59%, 0.62);
        max-height: 60vh;
        width: 80%;
        object-fit: cover;
        object-position: bottom;
        border: 4px solid $primary;
      }
    }
  }
}

body#nosco-landing {
  .block-2,
  .block-3 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $primary;
    }

    h2,
    i {
      font-size: $h2_size;
    }

    h2{
      color: $primary-darker;
    }

    h2.slogan {
      text-align: center;
      font-size: 34px;
    }

    p, td {
      font-size: 18px;
    }

    strong {
      color: $strong-text-color;
    }
  }

  .block-3 {
    background-color: #f5f9f9;
  }
}

@media (min-width: 1199px) {
  body#nosco-landing {
    .block-1 {
      h1 {
        font-size: 60px;
      }

      h2 {
        font-size: $h2_size;
      }
    }
  }
}

@media (min-width: 992px) {
  body#nosco-landing .hero {
    padding-bottom: 70px;

    .hero__title {
      font-size: 48px;
      margin-bottom: 20px;
    }

    .hero__subtitle {
      font-size: 26px;
      margin-bottom: 0;
    }

    .benefit__icon{
      height: 120px;
      width: 120px;

      svg {
        height: 90%;
        width: 90%;
      }
    }
  }

  body#nosco-landing {
    .js-slick-carousel {
      display: flex;
      justify-content: space-between;

      .js-slide {
        width: 30%;
        font-size: 14px;
        height: 100%;

        .card {
          height: 100%;

          h3 {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  body#nosco-landing {
    .block-1 {
      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: $h2_size;
      }
    }

    .block-1 {
      background-size: 150vh 100vh;
      padding-bottom: 30px;

      .benefit {
        height: 200px;
      }

      .hero__title {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .hero__subtitle {
        margin-bottom: 0;
        font-size: 20px;
      }

      .hero__logo-wrapper {
        height: 30px;
        vertical-align: -3px;
      }

      .benefit {
        font-size: 18px;
        font-weight: 600;
        // padding: 0 10px;
        height: auto;
        margin-bottom: 20px;

        .benefit__icon {
          @include size(100px);
          margin-bottom: 10px;

          img,
          svg {
            @include size(90%);
            // background: #e2f5f0;
            padding: 12px;
            border-radius: 8px;
            // background: hsl(164, 19%, 98%);
            // background: #f7f7f7;
            background: linear-gradient(0deg, hsl(0, 0, 92%), hsl(0, 0, 98%));
            box-shadow: inset 0px 0px 8px #0000002b;
          }
          &::after {
            display: block;
            content: "";
            position: absolute;
            top: 14%;
            left: 14%;
            width: 72%;
            height: 22%;
            background: linear-gradient(0, rgba(255, 255, 255, 0.3), transparent);
            border-radius: 0 0 25px 25px;
          }
        }

        span {
          strong {
            font-size: 18px;
            padding-bottom: 4px;
          }
        }
      }
    }

    .block-2,
    .block-3 {
      p {
        font-size: 18px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body#nosco-landing {
    .block-1 {
      h1 {
        font-size: 40px;
      }

      h2 {
        font-size: $h2_size;
      }

      .hero__subtitle {
        margin-bottom: 0;
        font-size: 16px;
      }

      .hero__logo-wrapper {
        height: 26px;
        vertical-align: -2px;
      }

      .benefit {
        font-size: 12px;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  body#nosco-landing .hero {
    span {
      /* NOTE: this makes text inside span tags
                go to new line */
      display: block;
    }

    .hero__title,
    .hero__subtitle {
      text-align: center;
    }

    .hero__subtitle {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  body#nosco-landing {
    .container,
    .container-sm {
      max-width: unquote("min(100%, 620px)");
    }
  }
}

@media (max-width: 576px) {
  body#nosco-landing {
    .block-1 {
      .benefits {
        margin-bottom: 60px;

        .benefit {
          font-size: 18px;
          margin-bottom: 30px;

          strong {
            font-size: 24px;
          }
        }
      }
      .hero__action-wrapper {
        flex-direction: column;
        margin-bottom: 20px;
        .hero__action {
          min-width: 307px;
          margin-top: 10px;
        }
        .hero__action + .hero__action {
          margin-left: 0px;
        }

      }
    }
  }
}

body#nosco-home {
  #thank-you-block {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    i {
      color: $primary;
    }
    h1 {
      font-weight: 400;
      color: #009e8e;
    }

    p.lead {
      color: $paragraph-text-color;
      line-height: 1.2;
      font-size: 18px;
      &.justify {
        text-align: justify;
      }
    }

    #latest-posts {
      .card .card-img-top {
        height: 150px;
        object-fit: cover;
      }

      @media screen and (max-width: 767px) {
        .card {
          display: flex;
          flex-direction: row;

          .card-img-top {
            height: 100%;
            width: 210px;
            border-radius: 0.25rem 0 0 0.25rem;
          }
        }

      }
    }
  }
}

@include media-breakpoint-down(sm) {
  body#nosco-landing {
    footer {
      .links {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  body#nosco-landing {
    footer {
      .links {
        display: flex;
        justify-content: center;
        flex-direction: row;
      }
    }
  }
}
