.page--event.page--offcar-10-giugno-2021 {

  #first-post {
    width: 100%;
    display: flex;

    #icon {
      border: none;
    }
  }
}


.page--event.page--offcar-10-giugno-2021 {
  footer {
    padding: 15px;
    background: linear-gradient(45deg, #009e8e, hsl(188, 100%, 31%));
    // background: linear-gradient(181deg, #009e8e, hsl(180, 100%, 21%));
    // background: linear-gradient(181deg, #009e8e, rgba(0, 0, 0, 0)), linear-gradient(45deg, #005177, #194600);

    .links {
      display: flex;
      justify-content: center;

      i {
        font-size: 28px;
        margin: 0;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
}
