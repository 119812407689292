@charset "UTF-8";
.hs-menu-initialized {
  position: relative;
  z-index: 10;
}

.hs-menu-initialized .animated {
  animation-duration: 300ms;
}

.hs-overflow-x-locked {
  overflow-x: hidden;
}

.hs-sub-menu,
.hs-mega-menu {
  display: none;
  background-color: #fff;
}

@media (min-width: 576px) {
  .hs-mega-menu-desktop-sm,
.hs-sub-menu-desktop-sm {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
@media (min-width: 768px) {
  .hs-mega-menu-desktop-md,
.hs-sub-menu-desktop-md {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
@media (min-width: 992px) {
  .hs-mega-menu-desktop-lg,
.hs-sub-menu-desktop-lg {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
@media (min-width: 1200px) {
  .hs-mega-menu-desktop-xl,
.hs-sub-menu-desktop-xl {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
.hs-sub-menu {
  min-width: 180px;
}

.hs-has-sub-menu {
  overflow: hidden;
  position: relative;
}

.hs-sub-menu-opened {
  overflow: visible;
}

.hs-sub-menu .hs-sub-menu,
.hs-mega-menu .hs-sub-menu,
.hs-sub-menu .hs-mega-menu,
.hs-mega-menu .hs-mega-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
}

/*------------------------------------
	Vertical Direction
------------------------------------*/
.hs-menu-vertical .hs-sub-menu,
.hs-menu-vertical .hs-mega-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
}

.hs-menu-vertical .hs-sub-menu {
  width: auto;
}

.hs-menu-vertical .hs-mega-menu {
  height: 100%;
}

/*------------------------------------
	Mobile state
------------------------------------*/
.hs-mobile-state .hs-sub-menu,
.hs-mobile-state .hs-mega-menu {
  position: static;
  visibility: visible;
}

.hs-mobile-state .hs-has-mega-menu[data-max-width] > .hs-mega-menu,
.hs-mobile-state .hs-has-sub-menu[data-max-width] > .hs-sub-menu {
  max-width: initial !important;
}

/*------------------------------------
	RTL
------------------------------------*/
.hs-menu-initialized.hs-rtl {
  direction: rtl;
  unicode-bidi: embed;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu {
  left: auto;
  right: 0;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-mega-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-mega-menu {
  left: auto;
  right: 100%;
}

/*------------------------------------
	Smart Position
------------------------------------*/
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
  left: auto;
  right: 0;
}

.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
  left: auto;
  right: 100%;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
  right: auto;
  left: 0;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
  right: auto;
  left: 100%;
}

/*------------------------------------
	Positions
	(only 'horizontal' direction)
------------------------------------*/
.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-left {
  left: 0;
  right: auto;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-right {
  left: auto;
  right: 0;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-center {
  right: auto;
  left: 50%;
  transform: translate(-50%, 0);
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}
[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}
[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}
[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}
[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}
[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #377dff;
  --indigo: #2d1582;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #de4437;
  --orange: #fd7e14;
  --yellow: #f5ca99;
  --green: #28a745;
  --teal: #00c9a7;
  --cyan: #09a5be;
  --white: #fff;
  --gray: hsl(168deg, 0%, 46%);
  --gray-dark: hsl(168deg, 0%, 23%);
  --primary: hsl(174deg, 100%, 31%);
  --secondary: #56b4a9;
  --success: #00c9a7;
  --info: #09a5be;
  --warning: #f5ca99;
  --danger: #de4437;
  --light: hsl(168deg, 0%, 98%);
  --dark: #1e2022;
  --indigo: #2d1582;
  --navy: #21325b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: hsl(168deg, 0%, 31%);
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: hsl(174deg, 100%, 31%);
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #005249;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: hsl(168deg, 0%, 46%);
  text-align: left;
  caption-side: bottom;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.4;
  color: #1e2022;
}

h1, .h1 {
  font-size: 2.5rem;
}
@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h2, .h2 {
  font-size: 1.875rem;
}
@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.3125rem + 0.75vw);
  }
}

h3, .h3 {
  font-size: 1.3125rem;
}
@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: hsl(168deg, 0%, 46%);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: hsl(168deg, 0%, 46%);
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: hsl(168deg, 0%, 15%);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

pre {
  display: block;
  font-size: 87.5%;
  color: hsl(168deg, 0%, 15%);
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: hsl(168deg, 0%, 31%);
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0.125rem solid hsl(168deg, 0%, 89%);
}
.table tbody + tbody {
  border-top: 0.125rem solid hsl(168deg, 0%, 89%);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.table-bordered th,
.table-bordered td {
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0.125rem;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsl(168deg, 0%, 93%);
}

.table-hover tbody tr:hover {
  color: hsl(168deg, 0%, 31%);
  background-color: rgba(227, 227, 227, 0.4);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e4df;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7acdc4;
}

.table-hover .table-primary:hover {
  background-color: #a6ddd7;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a6ddd7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d0eae7;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a7d8d2;
}

.table-hover .table-secondary:hover {
  background-color: #bee2de;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #bee2de;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f0e6;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae3d1;
}

.table-hover .table-success:hover {
  background-color: #a3ecdf;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3ecdf;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bae6ed;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7fd0dd;
}

.table-hover .table-info:hover {
  background-color: #a6dfe8;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a6dfe8;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf0e2;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fae3ca;
}

.table-hover .table-warning:hover {
  background-color: #fae4cb;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fae4cb;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6cbc7;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ee9e97;
}

.table-hover .table-danger:hover {
  background-color: #f2b7b1;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f2b7b1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc;
}

.table-hover .table-light:hover {
  background-color: #f1f1f1;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c0c1c1;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8a8b8c;
}

.table-hover .table-dark:hover {
  background-color: #b3b4b4;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b3b4b4;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #c4bddc;
}
.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #9285be;
}

.table-hover .table-indigo:hover {
  background-color: #b5acd3;
}
.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #b5acd3;
}

.table-navy,
.table-navy > th,
.table-navy > td {
  background-color: #c1c6d1;
}
.table-navy th,
.table-navy td,
.table-navy thead th,
.table-navy tbody + tbody {
  border-color: #8c94aa;
}

.table-hover .table-navy:hover {
  background-color: #b2b9c6;
}
.table-hover .table-navy:hover > td,
.table-hover .table-navy:hover > th {
  background-color: #b2b9c6;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(227, 227, 227, 0.4);
}

.table-hover .table-active:hover {
  background-color: rgba(214, 214, 214, 0.4);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(214, 214, 214, 0.4);
}

.table .thead-dark th {
  color: #fff;
  background-color: hsl(168deg, 0%, 23%);
  border-color: #4e4e4e;
}
.table .thead-light th {
  color: hsl(168deg, 0%, 31%);
  background-color: hsl(168deg, 0%, 93%);
  border-color: hsl(168deg, 0%, 89%);
}

.table-dark {
  color: #fff;
  background-color: hsl(168deg, 0%, 23%);
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #4e4e4e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.625rem);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #1e2022;
  background-color: #fff;
  border-color: rgba(0, 158, 142, 0.4);
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 158, 142, 0.1);
}
.form-control::placeholder {
  color: hsl(168deg, 0%, 71%);
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: hsl(168deg, 0%, 93%);
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}
select.form-control:focus::-ms-value {
  color: #1e2022;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: 1.1875rem;
  padding-bottom: 1.1875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: hsl(168deg, 0%, 31%);
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1.375rem);
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1.5em + 2.375rem);
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: hsl(168deg, 0%, 46%);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00c9a7;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: #00c9a7;
  border-radius: 0.3125rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00c9a7;
  padding-right: calc(1.6em + 1.5rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.375rem) center;
  background-size: calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 1.5rem);
  background-position: top calc(0.4em + 0.375rem) right calc(0.4em + 0.375rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00c9a7;
  padding-right: calc(0.75em + 3.125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='hsl%28168deg, 0%, 23%%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.8em + 0.75rem) calc(0.8em + 0.75rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00c9a7;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00c9a7;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00c9a7;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00fcd1;
  background-color: #00fcd1;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00c9a7;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00c9a7;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #de4437;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: #de4437;
  border-radius: 0.3125rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #de4437;
  padding-right: calc(1.6em + 1.5rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23de4437' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23de4437' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.375rem) center;
  background-size: calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 1.5rem);
  background-position: top calc(0.4em + 0.375rem) right calc(0.4em + 0.375rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #de4437;
  padding-right: calc(0.75em + 3.125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='hsl%28168deg, 0%, 23%%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23de4437' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23de4437' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.8em + 0.75rem) calc(0.8em + 0.75rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #de4437;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #de4437;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #de4437;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e56d63;
  background-color: #e56d63;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #de4437;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #de4437;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: hsl(168deg, 0%, 31%);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: hsl(168deg, 0%, 31%);
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 142, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.btn-primary:hover {
  color: #fff;
  background-color: #00786c;
  border-color: #006b60;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #00786c;
  border-color: #006b60;
  box-shadow: 0 0 0 0.2rem rgba(38, 173, 159, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #006b60;
  border-color: #005e55;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 173, 159, 0.5);
}

.btn-secondary {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  border-color: #56b4a9;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #469e93;
  border-color: #42958b;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #469e93;
  border-color: #42958b;
  box-shadow: 0 0 0 0.2rem rgba(79, 159, 149, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  border-color: #56b4a9;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #42958b;
  border-color: #3e8c83;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 159, 149, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.btn-success:hover {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00967d;
  border-color: #008972;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}
.btn-info:hover {
  color: #fff;
  background-color: #078599;
  border-color: #077b8d;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #078599;
  border-color: #077b8d;
  box-shadow: 0 0 0 0.2rem rgba(46, 179, 200, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #077b8d;
  border-color: #067081;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 179, 200, 0.5);
}

.btn-warning {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  border-color: #f5ca99;
}
.btn-warning:hover {
  color: hsl(168deg, 0%, 15%);
  background-color: #f2b876;
  border-color: #f0b26b;
}
.btn-warning:focus, .btn-warning.focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #f2b876;
  border-color: #f0b26b;
  box-shadow: 0 0 0 0.2rem rgba(214, 177, 136, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  border-color: #f5ca99;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: #f0b26b;
  border-color: #efac5f;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 177, 136, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.btn-danger:hover {
  color: #fff;
  background-color: #cd2f22;
  border-color: #c22d20;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #cd2f22;
  border-color: #c22d20;
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c22d20;
  border-color: #b72a1e;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}

.btn-light {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}
.btn-light:hover {
  color: hsl(168deg, 0%, 15%);
  background-color: #e7e7e7;
  border-color: #e0e0e0;
}
.btn-light:focus, .btn-light.focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #e7e7e7;
  border-color: #e0e0e0;
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: #e0e0e0;
  border-color: #dadada;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}
.btn-dark:hover {
  color: #fff;
  background-color: #0c0d0e;
  border-color: #060707;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #0c0d0e;
  border-color: #060707;
  box-shadow: 0 0 0 0.2rem rgba(64, 65, 67, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #060707;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 65, 67, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}
.btn-indigo:hover {
  color: #fff;
  background-color: #221061;
  border-color: #1e0e56;
}
.btn-indigo:focus, .btn-indigo.focus {
  color: #fff;
  background-color: #221061;
  border-color: #1e0e56;
  box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
}
.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}
.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active, .show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #1e0e56;
  border-color: #1a0c4b;
}
.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus, .show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
}

.btn-navy {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}
.btn-navy:hover {
  color: #fff;
  background-color: #17233f;
  border-color: #131d36;
}
.btn-navy:focus, .btn-navy.focus {
  color: #fff;
  background-color: #17233f;
  border-color: #131d36;
  box-shadow: 0 0 0 0.2rem rgba(66, 81, 116, 0.5);
}
.btn-navy.disabled, .btn-navy:disabled {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}
.btn-navy:not(:disabled):not(.disabled):active, .btn-navy:not(:disabled):not(.disabled).active, .show > .btn-navy.dropdown-toggle {
  color: #fff;
  background-color: #131d36;
  border-color: #10182c;
}
.btn-navy:not(:disabled):not(.disabled):active:focus, .btn-navy:not(:disabled):not(.disabled).active:focus, .show > .btn-navy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 81, 116, 0.5);
}

.btn-outline-primary {
  color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 142, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: hsl(174deg, 100%, 31%);
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 142, 0.5);
}

.btn-outline-secondary {
  color: #56b4a9;
  border-color: #56b4a9;
}
.btn-outline-secondary:hover {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  border-color: #56b4a9;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 180, 169, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #56b4a9;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  border-color: #56b4a9;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 180, 169, 0.5);
}

.btn-outline-success {
  color: #00c9a7;
  border-color: #00c9a7;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00c9a7;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-info {
  color: #09a5be;
  border-color: #09a5be;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 165, 190, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #09a5be;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 165, 190, 0.5);
}

.btn-outline-warning {
  color: #f5ca99;
  border-color: #f5ca99;
}
.btn-outline-warning:hover {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  border-color: #f5ca99;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f5ca99;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  border-color: #f5ca99;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.btn-outline-danger {
  color: #de4437;
  border-color: #de4437;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #de4437;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.btn-outline-light {
  color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}
.btn-outline-light:hover {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: hsl(168deg, 0%, 98%);
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.btn-outline-dark {
  color: #1e2022;
  border-color: #1e2022;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #1e2022;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.btn-outline-indigo {
  color: #2d1582;
  border-color: #2d1582;
}
.btn-outline-indigo:hover {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}
.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}
.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #2d1582;
  background-color: transparent;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active, .show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.btn-outline-navy {
  color: #21325b;
  border-color: #21325b;
}
.btn-outline-navy:hover {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}
.btn-outline-navy:focus, .btn-outline-navy.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 50, 91, 0.5);
}
.btn-outline-navy.disabled, .btn-outline-navy:disabled {
  color: #21325b;
  background-color: transparent;
}
.btn-outline-navy:not(:disabled):not(.disabled):active, .btn-outline-navy:not(:disabled):not(.disabled).active, .show > .btn-outline-navy.dropdown-toggle {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}
.btn-outline-navy:not(:disabled):not(.disabled):active:focus, .btn-outline-navy:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-navy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 50, 91, 0.5);
}

.btn-link {
  font-weight: 400;
  color: hsl(174deg, 100%, 31%);
  text-decoration: none;
}
.btn-link:hover {
  color: #005249;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: hsl(168deg, 0%, 46%);
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 1rem 0;
  margin: 0.5rem 0 0;
  font-size: 1rem;
  color: hsl(168deg, 0%, 31%);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.5rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.5rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid hsl(168deg, 0%, 89%);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: hsl(168deg, 0%, 31%);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2022;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: hsl(168deg, 0%, 71%);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: hsl(168deg, 0%, 46%);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: hsl(168deg, 0%, 31%);
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.0625rem;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -0.0625rem;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -0.0625rem;
}

.input-group-prepend {
  margin-right: -0.0625rem;
}

.input-group-append {
  margin-left: -0.0625rem;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: hsl(168deg, 0%, 71%);
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2.375rem);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1.375rem);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: hsl(174deg, 100%, 31%);
  background-color: hsl(174deg, 100%, 31%);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 10px rgba(0, 158, 142, 0.1);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(0, 158, 142, 0.4);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #52ffee;
  border-color: #52ffee;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: hsl(168deg, 0%, 46%);
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: hsl(168deg, 0%, 93%);
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 0.0625rem solid hsl(168deg, 0%, 71%);
}
.custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3125rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: hsl(174deg, 100%, 31%);
  background-color: hsl(174deg, 100%, 31%);
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 158, 142, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 158, 142, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 158, 142, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: 0.425rem;
  left: -2.125rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: hsl(168deg, 0%, 71%);
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 158, 142, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem);
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: hsl(168deg, 0%, 71%);
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='hsl%28168deg, 0%, 23%%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
  appearance: none;
}
.custom-select:focus {
  border-color: rgba(0, 158, 142, 0.4);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 142, 0.25);
}
.custom-select:focus::-ms-value {
  color: #1e2022;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: hsl(168deg, 0%, 46%);
  background-color: hsl(168deg, 0%, 93%);
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 hsl(168deg, 0%, 71%);
}

.custom-select-sm {
  height: calc(1.5em + 1.375rem);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.125rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 2.375rem);
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.125rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.625rem);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(0, 158, 142, 0.4);
  box-shadow: 0 0 10px rgba(0, 158, 142, 0.1);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: hsl(168deg, 0%, 93%);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 1.625rem);
  padding: 0.75rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.6;
  color: hsl(168deg, 0%, 46%);
  background-color: #fff;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 1.5rem);
  padding: 0.75rem 1rem;
  line-height: 1.6;
  color: hsl(168deg, 0%, 46%);
  content: "Browse";
  background-color: transparent;
  border-left: inherit;
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(0, 158, 142, 0.1);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(0, 158, 142, 0.1);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(0, 158, 142, 0.1);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: hsl(174deg, 100%, 31%);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #52ffee;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: hsl(168deg, 0%, 89%);
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: hsl(174deg, 100%, 31%);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #52ffee;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: hsl(168deg, 0%, 89%);
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: hsl(174deg, 100%, 31%);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #52ffee;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: hsl(168deg, 0%, 89%);
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: hsl(168deg, 0%, 89%);
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: hsl(168deg, 0%, 71%);
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: hsl(168deg, 0%, 71%);
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: hsl(168deg, 0%, 71%);
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: hsl(168deg, 0%, 46%);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.nav-tabs .nav-link {
  margin-bottom: -0.0625rem;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: hsl(168deg, 0%, 93%) hsl(168deg, 0%, 93%) hsl(168deg, 0%, 89%);
}
.nav-tabs .nav-link.disabled {
  color: hsl(168deg, 0%, 46%);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: hsl(168deg, 0%, 31%);
  background-color: #fff;
  border-color: hsl(168deg, 0%, 89%) hsl(168deg, 0%, 89%) #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.3125rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: hsl(168deg, 0%, 31%);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #005249;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: hsl(168deg, 0%, 31%);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='hsl%28168deg, 0%, 31%%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: hsl(168deg, 0%, 31%);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 1.5rem;
}

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-footer {
  padding: 1.5rem 1.5rem;
  background-color: #fff;
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -0.0625rem;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.3125rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: hsl(168deg, 0%, 71%);
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: hsl(168deg, 0%, 31%);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: hsl(168deg, 0%, 31%);
  background-color: #fff;
  border: 0 solid hsl(168deg, 0%, 89%);
}
.page-link:hover {
  z-index: 2;
  color: hsl(174deg, 100%, 31%);
  text-decoration: none;
  background-color: rgba(0, 158, 142, 0.1);
  border-color: rgba(0, 158, 142, 0.1);
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 142, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.page-item.disabled .page-link {
  color: hsl(168deg, 0%, 46%);
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: hsl(168deg, 0%, 89%);
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.275rem 0.5rem;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.55rem;
  padding-left: 0.55rem;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #006b60;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 142, 0.5);
}

.badge-secondary {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #42958b;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 180, 169, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #00c9a7;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #00967d;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #09a5be;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #077b8d;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 165, 190, 0.5);
}

.badge-warning {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #f0b26b;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #de4437;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c22d20;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.badge-light {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
}
a.badge-light:hover, a.badge-light:focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #e0e0e0;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1e2022;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #060707;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #2d1582;
}
a.badge-indigo:hover, a.badge-indigo:focus {
  color: #fff;
  background-color: #1e0e56;
}
a.badge-indigo:focus, a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.badge-navy {
  color: #fff;
  background-color: #21325b;
}
a.badge-navy:hover, a.badge-navy:focus {
  color: #fff;
  background-color: #131d36;
}
a.badge-navy:focus, a.badge-navy.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 50, 91, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: hsl(168deg, 0%, 93%);
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #00524a;
  background-color: #ccece8;
  border-color: #b8e4df;
}
.alert-primary hr {
  border-top-color: #a6ddd7;
}
.alert-primary .alert-link {
  color: #001f1c;
}

.alert-secondary {
  color: #2d5e58;
  background-color: #ddf0ee;
  border-color: #d0eae7;
}
.alert-secondary hr {
  border-top-color: #bee2de;
}
.alert-secondary .alert-link {
  color: #1c3c38;
}

.alert-success {
  color: #006957;
  background-color: #ccf4ed;
  border-color: #b8f0e6;
}
.alert-success hr {
  border-top-color: #a3ecdf;
}
.alert-success .alert-link {
  color: #00362d;
}

.alert-info {
  color: #055663;
  background-color: #ceedf2;
  border-color: #bae6ed;
}
.alert-info hr {
  border-top-color: #a6dfe8;
}
.alert-info .alert-link {
  color: #032c32;
}

.alert-warning {
  color: #7f6950;
  background-color: #fdf4eb;
  border-color: #fcf0e2;
}
.alert-warning hr {
  border-top-color: #fae4cb;
}
.alert-warning .alert-link {
  color: #604f3c;
}

.alert-danger {
  color: #73231d;
  background-color: #f8dad7;
  border-color: #f6cbc7;
}
.alert-danger hr {
  border-top-color: #f2b7b1;
}
.alert-danger .alert-link {
  color: #4a1713;
}

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe;
}
.alert-light hr {
  border-top-color: #f1f1f1;
}
.alert-light .alert-link {
  color: dimgray;
}

.alert-dark {
  color: #101112;
  background-color: #d2d2d3;
  border-color: #c0c1c1;
}
.alert-dark hr {
  border-top-color: #b3b4b4;
}
.alert-dark .alert-link {
  color: black;
}

.alert-indigo {
  color: #170b44;
  background-color: #d5d0e6;
  border-color: #c4bddc;
}
.alert-indigo hr {
  border-top-color: #b5acd3;
}
.alert-indigo .alert-link {
  color: #080418;
}

.alert-navy {
  color: #111a2f;
  background-color: #d3d6de;
  border-color: #c1c6d1;
}
.alert-navy hr {
  border-top-color: #b2b9c6;
}
.alert-navy .alert-link {
  color: #03050a;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: hsl(168deg, 0%, 93%);
  border-radius: 0.3125rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: hsl(174deg, 100%, 31%);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem;
}

.list-group-item-action {
  width: 100%;
  color: hsl(168deg, 0%, 31%);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: hsl(168deg, 0%, 31%);
  text-decoration: none;
  background-color: hsl(168deg, 0%, 98%);
}
.list-group-item-action:active {
  color: hsl(168deg, 0%, 31%);
  background-color: hsl(168deg, 0%, 93%);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: hsl(168deg, 0%, 46%);
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0.0625rem;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -0.0625rem;
  border-left-width: 0.0625rem;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 0.0625rem;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00524a;
  background-color: #b8e4df;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #00524a;
  background-color: #a6ddd7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #00524a;
  border-color: #00524a;
}

.list-group-item-secondary {
  color: #2d5e58;
  background-color: #d0eae7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #2d5e58;
  background-color: #bee2de;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #2d5e58;
  border-color: #2d5e58;
}

.list-group-item-success {
  color: #006957;
  background-color: #b8f0e6;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #006957;
  background-color: #a3ecdf;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #006957;
  border-color: #006957;
}

.list-group-item-info {
  color: #055663;
  background-color: #bae6ed;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055663;
  background-color: #a6dfe8;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055663;
  border-color: #055663;
}

.list-group-item-warning {
  color: #7f6950;
  background-color: #fcf0e2;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7f6950;
  background-color: #fae4cb;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7f6950;
  border-color: #7f6950;
}

.list-group-item-danger {
  color: #73231d;
  background-color: #f6cbc7;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #73231d;
  background-color: #f2b7b1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #73231d;
  border-color: #73231d;
}

.list-group-item-light {
  color: #828282;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #828282;
  background-color: #f1f1f1;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #828282;
  border-color: #828282;
}

.list-group-item-dark {
  color: #101112;
  background-color: #c0c1c1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #101112;
  background-color: #b3b4b4;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #101112;
  border-color: #101112;
}

.list-group-item-indigo {
  color: #170b44;
  background-color: #c4bddc;
}
.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #170b44;
  background-color: #b5acd3;
}
.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #170b44;
  border-color: #170b44;
}

.list-group-item-navy {
  color: #111a2f;
  background-color: #c1c6d1;
}
.list-group-item-navy.list-group-item-action:hover, .list-group-item-navy.list-group-item-action:focus {
  color: #111a2f;
  background-color: #b2b9c6;
}
.list-group-item-navy.list-group-item-action.active {
  color: #fff;
  background-color: #111a2f;
  border-color: #111a2f;
}

.close {
  float: right;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: #1e2022;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #1e2022;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: hsl(168deg, 0%, 46%);
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}
.modal-header .close {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem 1.5rem-0.25rem;
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #21325b;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #21325b;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #21325b;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #21325b;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #21325b;
  border-radius: 0.3125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3125rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: -0.5625rem;
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 0.0625rem;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.3125rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 0.0625rem;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: -0.5625rem;
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 0.0625rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 93%);
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.3125rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 0.0625rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1e2022;
  background-color: hsl(168deg, 0%, 93%);
  border-bottom: 0.0625rem solid #e0e0e0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1.125rem 1rem;
  color: hsl(168deg, 0%, 31%);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: hsl(174deg, 100%, 31%) !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #006b60 !important;
}

.bg-secondary {
  background-color: #56b4a9 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #42958b !important;
}

.bg-success {
  background-color: #00c9a7 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00967d !important;
}

.bg-info {
  background-color: #09a5be !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #077b8d !important;
}

.bg-warning {
  background-color: #f5ca99 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f0b26b !important;
}

.bg-danger {
  background-color: #de4437 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c22d20 !important;
}

.bg-light {
  background-color: hsl(168deg, 0%, 98%) !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e0e0e0 !important;
}

.bg-dark {
  background-color: #1e2022 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #060707 !important;
}

.bg-indigo {
  background-color: #2d1582 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #1e0e56 !important;
}

.bg-navy {
  background-color: #21325b !important;
}

a.bg-navy:hover, a.bg-navy:focus,
button.bg-navy:hover,
button.bg-navy:focus {
  background-color: #131d36 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 0.0625rem solid hsl(168deg, 0%, 89%) !important;
}

.border-top {
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%) !important;
}

.border-right {
  border-right: 0.0625rem solid hsl(168deg, 0%, 89%) !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%) !important;
}

.border-left {
  border-left: 0.0625rem solid hsl(168deg, 0%, 89%) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: hsl(174deg, 100%, 31%) !important;
}

.border-secondary {
  border-color: #56b4a9 !important;
}

.border-success {
  border-color: #00c9a7 !important;
}

.border-info {
  border-color: #09a5be !important;
}

.border-warning {
  border-color: #f5ca99 !important;
}

.border-danger {
  border-color: #de4437 !important;
}

.border-light {
  border-color: hsl(168deg, 0%, 98%) !important;
}

.border-dark {
  border-color: #1e2022 !important;
}

.border-indigo {
  border-color: #2d1582 !important;
}

.border-navy {
  border-color: #21325b !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1) !important;
}

.shadow {
  box-shadow: 0px 0.375rem 1.5rem 0px rgba(117, 117, 117, 0.125) !important;
}

.shadow-lg {
  box-shadow: 0px 10px 40px 10px rgba(117, 117, 117, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-12 {
  margin: 7.5rem !important;
}

.mt-12,
.my-12 {
  margin-top: 7.5rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 7.5rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 7.5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 7.5rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-12 {
  padding: 7.5rem !important;
}

.pt-12,
.py-12 {
  padding-top: 7.5rem !important;
}

.pr-12,
.px-12 {
  padding-right: 7.5rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 7.5rem !important;
}

.pl-12,
.px-12 {
  padding-left: 7.5rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n12 {
  margin: -7.5rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -7.5rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -7.5rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -7.5rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -7.5rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-12 {
    margin: 7.5rem !important;
  }
  .mt-sm-12,
.my-sm-12 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-12,
.mx-sm-12 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-12,
.my-sm-12 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-12,
.mx-sm-12 {
    margin-left: 7.5rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
.my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
.mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
.mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
.my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
.mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
.my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
.mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
.my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
.mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
.my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
.mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
.my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
.mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
.my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
.mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10,
.my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10,
.mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10,
.my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10,
.mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .mt-sm-11,
.my-sm-11 {
    margin-top: 5rem !important;
  }
  .mr-sm-11,
.mx-sm-11 {
    margin-right: 5rem !important;
  }
  .mb-sm-11,
.my-sm-11 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-11,
.mx-sm-11 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-12 {
    padding: 7.5rem !important;
  }
  .pt-sm-12,
.py-sm-12 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-12,
.px-sm-12 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-12,
.py-sm-12 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-12,
.px-sm-12 {
    padding-left: 7.5rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
.py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
.px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
.px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
.py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
.px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
.py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
.px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
.py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
.px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
.py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
.px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
.py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
.px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
.py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
.px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10,
.py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10,
.px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10,
.py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10,
.px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .pt-sm-11,
.py-sm-11 {
    padding-top: 5rem !important;
  }
  .pr-sm-11,
.px-sm-11 {
    padding-right: 5rem !important;
  }
  .pb-sm-11,
.py-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-11,
.px-sm-11 {
    padding-left: 5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n12 {
    margin: -7.5rem !important;
  }
  .mt-sm-n12,
.my-sm-n12 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n12,
.mx-sm-n12 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n12,
.my-sm-n12 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n12,
.mx-sm-n12 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
.my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
.mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
.my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
.mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
.my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
.mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
.my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
.mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
.my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
.mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
.my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
.mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10,
.my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10,
.mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10,
.my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10,
.mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .mt-sm-n11,
.my-sm-n11 {
    margin-top: -5rem !important;
  }
  .mr-sm-n11,
.mx-sm-n11 {
    margin-right: -5rem !important;
  }
  .mb-sm-n11,
.my-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n11,
.mx-sm-n11 {
    margin-left: -5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-12 {
    margin: 7.5rem !important;
  }
  .mt-md-12,
.my-md-12 {
    margin-top: 7.5rem !important;
  }
  .mr-md-12,
.mx-md-12 {
    margin-right: 7.5rem !important;
  }
  .mb-md-12,
.my-md-12 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-12,
.mx-md-12 {
    margin-left: 7.5rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
.my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
.mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
.my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
.mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
.my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
.mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
.my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
.mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
.my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
.mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
.my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
.mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
.my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
.mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
.my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
.mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10,
.my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10,
.mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10,
.my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10,
.mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .mt-md-11,
.my-md-11 {
    margin-top: 5rem !important;
  }
  .mr-md-11,
.mx-md-11 {
    margin-right: 5rem !important;
  }
  .mb-md-11,
.my-md-11 {
    margin-bottom: 5rem !important;
  }
  .ml-md-11,
.mx-md-11 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-12 {
    padding: 7.5rem !important;
  }
  .pt-md-12,
.py-md-12 {
    padding-top: 7.5rem !important;
  }
  .pr-md-12,
.px-md-12 {
    padding-right: 7.5rem !important;
  }
  .pb-md-12,
.py-md-12 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-12,
.px-md-12 {
    padding-left: 7.5rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
.py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
.px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
.py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
.px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
.py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
.px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
.py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
.px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
.py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
.px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
.py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
.px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
.py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
.px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
.py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
.px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10,
.py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10,
.px-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-10,
.py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10,
.px-md-10 {
    padding-left: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .pt-md-11,
.py-md-11 {
    padding-top: 5rem !important;
  }
  .pr-md-11,
.px-md-11 {
    padding-right: 5rem !important;
  }
  .pb-md-11,
.py-md-11 {
    padding-bottom: 5rem !important;
  }
  .pl-md-11,
.px-md-11 {
    padding-left: 5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n12 {
    margin: -7.5rem !important;
  }
  .mt-md-n12,
.my-md-n12 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n12,
.mx-md-n12 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n12,
.my-md-n12 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n12,
.mx-md-n12 {
    margin-left: -7.5rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
.my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
.mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
.mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
.my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
.mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
.my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
.mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
.my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
.mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
.my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
.mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
.my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
.mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
.my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
.mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10,
.my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10,
.mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10,
.my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10,
.mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .mt-md-n11,
.my-md-n11 {
    margin-top: -5rem !important;
  }
  .mr-md-n11,
.mx-md-n11 {
    margin-right: -5rem !important;
  }
  .mb-md-n11,
.my-md-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n11,
.mx-md-n11 {
    margin-left: -5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-12 {
    margin: 7.5rem !important;
  }
  .mt-lg-12,
.my-lg-12 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-12,
.mx-lg-12 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-12,
.my-lg-12 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-12,
.mx-lg-12 {
    margin-left: 7.5rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
.my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
.mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
.mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
.my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
.mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
.my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
.mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
.my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
.mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
.my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
.mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
.my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
.mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
.my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
.mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10,
.my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10,
.mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10,
.my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10,
.mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .mt-lg-11,
.my-lg-11 {
    margin-top: 5rem !important;
  }
  .mr-lg-11,
.mx-lg-11 {
    margin-right: 5rem !important;
  }
  .mb-lg-11,
.my-lg-11 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-11,
.mx-lg-11 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-12 {
    padding: 7.5rem !important;
  }
  .pt-lg-12,
.py-lg-12 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-12,
.px-lg-12 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-12,
.py-lg-12 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-12,
.px-lg-12 {
    padding-left: 7.5rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
.py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
.px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
.px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
.py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
.px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
.py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
.px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
.py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
.px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
.py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
.px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
.py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
.px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
.py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
.px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10,
.py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10,
.px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10,
.py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10,
.px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .pt-lg-11,
.py-lg-11 {
    padding-top: 5rem !important;
  }
  .pr-lg-11,
.px-lg-11 {
    padding-right: 5rem !important;
  }
  .pb-lg-11,
.py-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-11,
.px-lg-11 {
    padding-left: 5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n12 {
    margin: -7.5rem !important;
  }
  .mt-lg-n12,
.my-lg-n12 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n12,
.mx-lg-n12 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n12,
.my-lg-n12 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n12,
.mx-lg-n12 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
.my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
.mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
.my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
.mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
.my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
.mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
.my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
.mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
.my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
.mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
.my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
.mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10,
.my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10,
.mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10,
.my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10,
.mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .mt-lg-n11,
.my-lg-n11 {
    margin-top: -5rem !important;
  }
  .mr-lg-n11,
.mx-lg-n11 {
    margin-right: -5rem !important;
  }
  .mb-lg-n11,
.my-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n11,
.mx-lg-n11 {
    margin-left: -5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-12 {
    margin: 7.5rem !important;
  }
  .mt-xl-12,
.my-xl-12 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-12,
.mx-xl-12 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-12,
.my-xl-12 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-12,
.mx-xl-12 {
    margin-left: 7.5rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
.my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
.mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
.mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
.my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
.mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
.my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
.mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
.my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
.mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
.my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
.mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
.my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
.mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
.my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
.mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10,
.my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10,
.mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10,
.my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10,
.mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .mt-xl-11,
.my-xl-11 {
    margin-top: 5rem !important;
  }
  .mr-xl-11,
.mx-xl-11 {
    margin-right: 5rem !important;
  }
  .mb-xl-11,
.my-xl-11 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-11,
.mx-xl-11 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-12 {
    padding: 7.5rem !important;
  }
  .pt-xl-12,
.py-xl-12 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-12,
.px-xl-12 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-12,
.py-xl-12 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-12,
.px-xl-12 {
    padding-left: 7.5rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
.py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
.px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
.px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
.py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
.px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
.py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
.px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
.py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
.px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
.py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
.px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
.py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
.px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
.py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
.px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10,
.py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10,
.px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10,
.py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10,
.px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .pt-xl-11,
.py-xl-11 {
    padding-top: 5rem !important;
  }
  .pr-xl-11,
.px-xl-11 {
    padding-right: 5rem !important;
  }
  .pb-xl-11,
.py-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-11,
.px-xl-11 {
    padding-left: 5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n12 {
    margin: -7.5rem !important;
  }
  .mt-xl-n12,
.my-xl-n12 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n12,
.mx-xl-n12 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n12,
.my-xl-n12 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n12,
.mx-xl-n12 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
.my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
.mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
.my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
.mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
.my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
.mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
.my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
.mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
.my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
.mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
.my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
.mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10,
.my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10,
.mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10,
.my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10,
.mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .mt-xl-n11,
.my-xl-n11 {
    margin-top: -5rem !important;
  }
  .mr-xl-n11,
.mx-xl-n11 {
    margin-right: -5rem !important;
  }
  .mb-xl-n11,
.my-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n11,
.mx-xl-n11 {
    margin-left: -5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: hsl(174deg, 100%, 31%) !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #005249 !important;
}

.text-secondary {
  color: #56b4a9 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #3a837b !important;
}

.text-success {
  color: #00c9a7 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #007d67 !important;
}

.text-info {
  color: #09a5be !important;
}

a.text-info:hover, a.text-info:focus {
  color: #066675 !important;
}

.text-warning {
  color: #f5ca99 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #eea653 !important;
}

.text-danger {
  color: #de4437 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ac281c !important;
}

.text-light {
  color: hsl(168deg, 0%, 98%) !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d4d4d4 !important;
}

.text-dark {
  color: #1e2022 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-indigo {
  color: #2d1582 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #160a40 !important;
}

.text-navy {
  color: #21325b !important;
}

a.text-navy:hover, a.text-navy:focus {
  color: #0d1323 !important;
}

.text-body {
  color: hsl(168deg, 0%, 31%) !important;
}

.text-muted {
  color: hsl(168deg, 0%, 46%) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 0.0625rem solid hsl(168deg, 0%, 71%);
    page-break-inside: avoid;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.0625rem solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid hsl(168deg, 0%, 89%) !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: hsl(168deg, 0%, 89%);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: hsl(168deg, 0%, 89%);
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
}
@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
}
.space-0,
.space-top-0 {
  padding-top: 0 !important;
}

.space-0,
.space-bottom-0 {
  padding-bottom: 0 !important;
}

.space-1,
.space-top-1 {
  padding-top: 2rem !important;
}

.space-1,
.space-bottom-1 {
  padding-bottom: 2rem !important;
}

.space-2,
.space-top-2 {
  padding-top: 4rem !important;
}

.space-2,
.space-bottom-2 {
  padding-bottom: 4rem !important;
}

.space-3,
.space-top-3 {
  padding-top: 8rem !important;
}

.space-3,
.space-bottom-3 {
  padding-bottom: 8rem !important;
}

.space-4,
.space-top-4 {
  padding-top: 12.5rem !important;
}

.space-4,
.space-bottom-4 {
  padding-bottom: 12.5rem !important;
}

.space-5,
.space-top-5 {
  padding-top: 15rem !important;
}

.space-5,
.space-bottom-5 {
  padding-bottom: 15rem !important;
}

@media (min-width: 576px) {
  .space-sm-0,
.space-top-sm-0 {
    padding-top: 0 !important;
  }
  .space-sm-0,
.space-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
  .space-sm-1,
.space-top-sm-1 {
    padding-top: 2rem !important;
  }
  .space-sm-1,
.space-bottom-sm-1 {
    padding-bottom: 2rem !important;
  }
  .space-sm-2,
.space-top-sm-2 {
    padding-top: 4rem !important;
  }
  .space-sm-2,
.space-bottom-sm-2 {
    padding-bottom: 4rem !important;
  }
  .space-sm-3,
.space-top-sm-3 {
    padding-top: 8rem !important;
  }
  .space-sm-3,
.space-bottom-sm-3 {
    padding-bottom: 8rem !important;
  }
  .space-sm-4,
.space-top-sm-4 {
    padding-top: 12.5rem !important;
  }
  .space-sm-4,
.space-bottom-sm-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-sm-5,
.space-top-sm-5 {
    padding-top: 15rem !important;
  }
  .space-sm-5,
.space-bottom-sm-5 {
    padding-bottom: 15rem !important;
  }
}
@media (min-width: 768px) {
  .space-md-0,
.space-top-md-0 {
    padding-top: 0 !important;
  }
  .space-md-0,
.space-bottom-md-0 {
    padding-bottom: 0 !important;
  }
  .space-md-1,
.space-top-md-1 {
    padding-top: 2rem !important;
  }
  .space-md-1,
.space-bottom-md-1 {
    padding-bottom: 2rem !important;
  }
  .space-md-2,
.space-top-md-2 {
    padding-top: 4rem !important;
  }
  .space-md-2,
.space-bottom-md-2 {
    padding-bottom: 4rem !important;
  }
  .space-md-3,
.space-top-md-3 {
    padding-top: 8rem !important;
  }
  .space-md-3,
.space-bottom-md-3 {
    padding-bottom: 8rem !important;
  }
  .space-md-4,
.space-top-md-4 {
    padding-top: 12.5rem !important;
  }
  .space-md-4,
.space-bottom-md-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-md-5,
.space-top-md-5 {
    padding-top: 15rem !important;
  }
  .space-md-5,
.space-bottom-md-5 {
    padding-bottom: 15rem !important;
  }
}
@media (min-width: 992px) {
  .space-lg-0,
.space-top-lg-0 {
    padding-top: 0 !important;
  }
  .space-lg-0,
.space-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
  .space-lg-1,
.space-top-lg-1 {
    padding-top: 2rem !important;
  }
  .space-lg-1,
.space-bottom-lg-1 {
    padding-bottom: 2rem !important;
  }
  .space-lg-2,
.space-top-lg-2 {
    padding-top: 4rem !important;
  }
  .space-lg-2,
.space-bottom-lg-2 {
    padding-bottom: 4rem !important;
  }
  .space-lg-3,
.space-top-lg-3 {
    padding-top: 8rem !important;
  }
  .space-lg-3,
.space-bottom-lg-3 {
    padding-bottom: 8rem !important;
  }
  .space-lg-4,
.space-top-lg-4 {
    padding-top: 12.5rem !important;
  }
  .space-lg-4,
.space-bottom-lg-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-lg-5,
.space-top-lg-5 {
    padding-top: 15rem !important;
  }
  .space-lg-5,
.space-bottom-lg-5 {
    padding-bottom: 15rem !important;
  }
}
@media (min-width: 1200px) {
  .space-xl-0,
.space-top-xl-0 {
    padding-top: 0 !important;
  }
  .space-xl-0,
.space-bottom-xl-0 {
    padding-bottom: 0 !important;
  }
  .space-xl-1,
.space-top-xl-1 {
    padding-top: 2rem !important;
  }
  .space-xl-1,
.space-bottom-xl-1 {
    padding-bottom: 2rem !important;
  }
  .space-xl-2,
.space-top-xl-2 {
    padding-top: 4rem !important;
  }
  .space-xl-2,
.space-bottom-xl-2 {
    padding-bottom: 4rem !important;
  }
  .space-xl-3,
.space-top-xl-3 {
    padding-top: 8rem !important;
  }
  .space-xl-3,
.space-bottom-xl-3 {
    padding-bottom: 8rem !important;
  }
  .space-xl-4,
.space-top-xl-4 {
    padding-top: 12.5rem !important;
  }
  .space-xl-4,
.space-bottom-xl-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-xl-5,
.space-top-xl-5 {
    padding-top: 15rem !important;
  }
  .space-xl-5,
.space-bottom-xl-5 {
    padding-bottom: 15rem !important;
  }
}
/*------------------------------------
  Default Styles
------------------------------------*/
main {
  position: relative;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1e2022;
}

figure {
  margin-bottom: 0;
}

svg {
  margin-bottom: -8px;
  vertical-align: baseline;
}

sup {
  right: -0.5rem;
}

table th {
  font-weight: 600;
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

::selection {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

.bg-primary ::-moz-selection {
  color: hsl(174deg, 100%, 31%);
  background-color: #fff;
}

.bg-primary ::selection {
  color: hsl(174deg, 100%, 31%);
  background-color: #fff;
}

/*------------------------------------
  Accessibility
------------------------------------*/
:focus,
a:focus,
button:focus {
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}

.btn:focus, .btn.focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-select:focus,
.page-link:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-control-input:checked ~ .custom-control-label::before, .custom-control-input:focus ~ .custom-control-label::before, .custom-control-input:active ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 transparent;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

/*------------------------------------
  Print styles
------------------------------------*/
@media print {
  header, footer, .btn {
    display: none;
  }
}
/*------------------------------------
  Skippy
------------------------------------*/
.skippy {
  display: none;
}

/*------------------------------------
  List Article
------------------------------------*/
.list-article > li:not(:last-child) {
  padding-bottom: 1rem;
}
.list-article .list-article > li:first-child {
  padding-top: 1rem;
}
.list-article .list-article > li:last-child {
  padding-bottom: 0;
}

.list-sm-article > li:not(:last-child) {
  padding-bottom: 0.5rem;
}
.list-sm-article .list-sm-article > li:first-child {
  padding-top: 0.5rem;
}
.list-sm-article .list-sm-article > li:last-child {
  padding-bottom: 0;
}

.list-lg-article > li:not(:last-child) {
  padding-bottom: 2rem;
}
.list-lg-article .list-lg-article > li:first-child {
  padding-top: 2rem;
}
.list-lg-article .list-lg-article > li:last-child {
  padding-bottom: 0;
}

/*------------------------------------
  Links
------------------------------------*/
.link {
  display: flex;
}
.link-icon {
  position: relative;
  line-height: 1;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 1.375rem;
  height: 1.375rem;
  font-size: 1rem;
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
  border-radius: 50%;
  transition: 0.3s;
}
.link:hover .link-icon {
  transform: rotate(90deg);
}

/* Collapse */
.link-collapse[aria-expanded=false] .link-collapse-default {
  display: inline-block;
}
.link-collapse[aria-expanded=false] .link-collapse-active {
  display: none;
}

.link-collapse[aria-expanded=true] .link-collapse-default {
  display: none;
}
.link-collapse[aria-expanded=true] .link-collapse-active {
  display: inline-block;
}

/* Link Underline */
.link-underline {
  color: hsl(168deg, 0%, 46%);
  border-bottom: 0.0625rem dashed hsl(168deg, 0%, 71%);
}
.link-underline:hover {
  border-color: #005249;
}
.link-underline-toggle-default {
  display: inline-block;
}
.link-underline-toggle-toggled {
  display: none;
}
.link-underline.toggled .link-underline-toggle-default {
  display: none;
}
.link-underline.toggled .link-underline-toggle-toggled {
  display: inline-block;
}

/* Link Underline Light */
.link-underline-light {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.0625rem dashed rgba(255, 255, 255, 0.7);
}
.link-underline-light:hover {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Header Default
------------------------------------*/
.header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}
.header-section {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
.header-section-divider {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
}
.header-box-shadow .header-section {
  box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
}
.header-box-shadow-on-scroll.scrolled .header-section {
  box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
}

/* Navbar Brand */
.navbar-brand, .navbar-brand > img {
  width: 8.5rem;
}

/* Navbar Toggler */
.navbar-toggler {
  background-color: hsl(168deg, 0%, 93%);
}
.navbar-toggler .navbar-toggler-default {
  display: flex;
}
.navbar-toggler .navbar-toggler-toggled {
  display: none;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-default {
  display: none;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-toggled {
  display: flex;
}

/* Nav Item */
.header .navbar-nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-nav-item:hover .nav-link, .navbar-nav-item:focus .nav-link {
  color: hsl(174deg, 100%, 31%);
}

/* Nav Link */
.header .active > .nav-link {
  color: hsl(174deg, 100%, 31%);
}

.nav-link {
  color: hsl(168deg, 0%, 31%);
}
.nav-link-toggle {
  position: relative;
}
.nav-link-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 0.75rem;
  font-weight: 900;
  margin-left: 0.5rem;
  margin-right: 0.625rem;
  content: "\f107";
  transition: 0.3s ease;
}

/* Dropdown */
.header .navbar-nav .dropdown-menu {
  margin-top: -0.125rem;
}
.header .navbar-nav .dropdown-menu.hs-mega-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.header .navbar-nav .dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.header .dropdown-item-toggle {
  position: relative;
  display: flex;
  align-items: center;
}
.header .dropdown-item-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 0.75rem;
  font-weight: 900;
  content: "\f105";
  margin-left: auto;
  transition: 0.3s ease;
}
.header .hs-mega-menu .dropdown-item {
  padding-right: 0;
  padding-left: 0;
}

/* Promo */
.navbar-promo {
  background-color: hsl(168deg, 0%, 93%);
}
.navbar-promo-inner {
  color: inherit;
  padding: 2rem 2rem;
}
.navbar-promo-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.navbar-promo-item ~ .navbar-promo-item {
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.navbar-promo-link {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.navbar-promo-link.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.navbar-promo-link:hover .navbar-promo-title {
  color: hsl(174deg, 100%, 31%);
}
.navbar-promo-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}
.navbar-promo-card-deck {
  display: block;
}
.navbar-promo-card-deck:not(:last-child) .navbar-promo-item {
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.navbar-promo-card-deck .navbar-promo-item {
  border-top: none;
}
.navbar-promo-card-body {
  flex: 1 1 auto;
}
.navbar-promo-icon {
  width: 100%;
  max-width: 2.75rem;
  margin-right: 1rem;
}
.navbar-promo-title {
  display: block;
  color: #1e2022;
  font-size: 1rem;
  font-weight: 600;
}
.navbar-promo-text {
  display: block;
  font-size: 0.875rem;
  color: hsl(168deg, 0%, 31%);
}
.navbar-promo-footer {
  background-color: hsl(168deg, 0%, 93%);
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.navbar-promo-footer-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.navbar-promo-footer-text {
  display: block;
  font-size: 0.8125rem;
}
.navbar-promo-footer-ver-divider {
  position: relative;
}
.navbar-promo-footer-ver-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
  content: "";
}

/* Banner */
.navbar-banner {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-bottom-left-radius: 0.3125rem;
}
.navbar-banner::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(30, 32, 34, 0.5);
  border-bottom-left-radius: 0.3125rem;
  content: "";
}
.navbar-banner-content {
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

/* Product Banner */
.navbar-product-banner {
  background-color: hsl(168deg, 0%, 93%);
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-right: 1.5rem;
}
.navbar-product-banner-content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/*------------------------------------
  Header Helpers
------------------------------------*/
.header-invisible {
  display: none;
}
.header-moved-up {
  transform: translate3d(0, -100%, 0);
}
.header-moved-up.header-hide-topbar {
  transform: translate3d(0, -36px, 0);
}
.header-faded {
  opacity: 0;
  visibility: hidden;
}
.header-section-hidden {
  position: relative;
}
.header[data-hs-header-options*=fixMoment] {
  transition: 0.3s ease;
}
.header.header-untransitioned {
  transition: none;
}
.header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}
.header.js-header-fix-moment .header-hide-content {
  display: none;
}
.header-fix-top {
  position: fixed;
}
.header.header-fix-top[data-hs-header-options*=effectCompensation] {
  transition: none;
}

/*------------------------------------
  Nav Alignments
------------------------------------*/
@media (min-width: 992px) {
  .header .navbar-body,
.header .navbar-nav {
    margin-left: auto;
  }
  .left-aligned-navbar .navbar-body,
.left-aligned-navbar .navbar-nav {
    width: 100%;
    margin-left: 0;
    margin-right: auto;
  }
  .left-aligned-navbar .navbar-brand {
    margin-left: 0;
    margin-right: 3rem;
  }
  .left-aligned-navbar .navbar-nav-last-item {
    margin-left: auto;
  }
  .center-aligned-navbar .navbar-body,
.center-aligned-navbar .navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .center-aligned-navbar.header-abs-top-sm .navbar-body,
.center-aligned-navbar.header-abs-top-sm .navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
}
/*------------------------------------
  Header Default
------------------------------------*/
.navbar-nav-scroll {
  max-width: 100%;
  height: 4.625rem;
  overflow: hidden;
}
.navbar-nav-scroll .navbar-nav {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/*------------------------------------
  Nav Wrap
------------------------------------*/
.navbar-nav-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-expand .navbar-nav-wrap {
  justify-content: space-between;
}
.navbar-expand .navbar-nav-wrap-content {
  order: 3;
}
.navbar-expand .navbar-nav-wrap-toggler {
  order: 3;
  text-align: right;
}
.navbar-expand .navbar-nav-wrap-collapse {
  order: 2;
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav-wrap {
    justify-content: space-between;
  }
  .navbar-expand-sm .navbar-nav-wrap-content {
    order: 3;
  }
  .navbar-expand-sm .navbar-nav-wrap-toggler {
    order: 3;
    text-align: right;
  }
  .navbar-expand-sm .navbar-nav-wrap-collapse {
    order: 2;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav-wrap {
    justify-content: space-between;
  }
  .navbar-expand-md .navbar-nav-wrap-content {
    order: 3;
  }
  .navbar-expand-md .navbar-nav-wrap-toggler {
    order: 3;
    text-align: right;
  }
  .navbar-expand-md .navbar-nav-wrap-collapse {
    order: 2;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav-wrap {
    justify-content: space-between;
  }
  .navbar-expand-lg .navbar-nav-wrap-content {
    order: 3;
  }
  .navbar-expand-lg .navbar-nav-wrap-toggler {
    order: 3;
    text-align: right;
  }
  .navbar-expand-lg .navbar-nav-wrap-collapse {
    order: 2;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav-wrap {
    justify-content: space-between;
  }
  .navbar-expand-xl .navbar-nav-wrap-content {
    order: 3;
  }
  .navbar-expand-xl .navbar-nav-wrap-toggler {
    order: 3;
    text-align: right;
  }
  .navbar-expand-xl .navbar-nav-wrap-collapse {
    order: 2;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap {
    justify-content: flex-end;
  }
  .navbar-expand-sm .navbar-nav-wrap-brand {
    margin-right: auto;
  }
  .navbar-expand-sm .navbar-nav-wrap-content {
    text-align: center;
    margin-right: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap {
    justify-content: flex-end;
  }
  .navbar-expand-md .navbar-nav-wrap-brand {
    margin-right: auto;
  }
  .navbar-expand-md .navbar-nav-wrap-content {
    text-align: center;
    margin-right: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap {
    justify-content: flex-end;
  }
  .navbar-expand-lg .navbar-nav-wrap-brand {
    margin-right: auto;
  }
  .navbar-expand-lg .navbar-nav-wrap-content {
    text-align: center;
    margin-right: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap {
    justify-content: flex-end;
  }
  .navbar-expand-xl .navbar-nav-wrap-brand {
    margin-right: auto;
  }
  .navbar-expand-xl .navbar-nav-wrap-content {
    text-align: center;
    margin-right: 0.5rem;
  }
}
.navbar-expand .navbar-nav-wrap {
  justify-content: flex-end;
}
.navbar-expand .navbar-nav-wrap-brand {
  margin-right: auto;
}
.navbar-expand .navbar-nav-wrap-content {
  text-align: center;
  margin-right: 0.5rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .navbar-nav-wrap-brand {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*------------------------------------
  Fullscreen
------------------------------------*/
.fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
}
.fullscreen-container, .fullscreen-overlay {
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
.fullscreen-container {
  position: relative;
  z-index: 1000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}
.fullscreen-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.9) 0%, rgba(33, 50, 91, 0.9) 100%);
  background-repeat: repeat-x;
  transition-delay: 0.5s;
}
.fullscreen-body {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}
.fullscreen-body::-webkit-scrollbar {
  width: 0.5rem;
}
.fullscreen-body::-webkit-scrollbar-thumb {
  background-color: rgba(212, 212, 212, 0.6);
  visibility: hidden;
}
.fullscreen-body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.fullscreen.fullscreen-showed {
  visibility: visible;
}
.fullscreen.fullscreen-showed .fullscreen-container,
.fullscreen.fullscreen-showed .fullscreen-overlay {
  opacity: 1;
  visibility: visible;
}
.fullscreen.fullscreen-showed .fullscreen-overlay {
  transition-delay: 0s;
}
.fullscreen.fullscreen-showed .fullscreen-container {
  transition-delay: 0.5s;
}

.header .fullscreen-toggler.navbar-toggler {
  display: inline-flex;
  z-index: 1001;
}
.header .fullscreen-toggler.navbar-toggler .navbar-toggler-default {
  display: flex;
}
.header .fullscreen-toggler.navbar-toggler .navbar-toggler-toggled {
  display: none;
}
.header .fullscreen-toggler.navbar-toggler[aria-pressed=true] .navbar-toggler-default {
  display: none;
}
.header .fullscreen-toggler.navbar-toggler[aria-pressed=true] .navbar-toggler-toggled {
  display: flex;
}

/*------------------------------------
  Header Fullscreen Style
------------------------------------*/
.fullscreen-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.fullscreen-nav-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.fullscreen-nav-link {
  display: inline-block;
  font-size: 1.375rem;
  color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .fullscreen-nav-link {
    font-size: 1.75rem;
  }
}
.fullscreen-nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.fullscreen-submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}
.fullscreen-submenu .fullscreen-submenu .fullscreen-submenu-list {
  margin-left: 1rem;
}
.fullscreen-submenu .fullscreen-nav-list {
  border-left: 0.125rem solid rgba(255, 255, 255, 0.6);
  padding-left: 0;
}
.fullscreen-submenu-nav-link {
  display: inline-block;
  color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.fullscreen-submenu-nav-link.disabled {
  color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
}
.fullscreen-submenu-nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.fullscreen-submenu-list {
  list-style: none;
  border-left: 0.125rem solid rgba(255, 255, 255, 0.6);
  padding-left: 0;
  margin-bottom: 0;
}
.fullscreen-submenu-list-link {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
}
.fullscreen-submenu-list-link:hover {
  color: #fff;
}

/*------------------------------------
  Transparent Header
------------------------------------*/
.header-bg-transparent:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
  background-color: #fff;
}
.header-bg-transparent:not(.js-header-fix-moment) .header-section {
  background-color: transparent;
}
.header-bg-transparent .navbar-brand-collapsed,
.header-bg-transparent .navbar-brand-on-scroll {
  display: none;
}
.header-bg-transparent.js-header-fix-moment .navbar-brand-default {
  display: none;
}
.header-bg-transparent.js-header-fix-moment .navbar-brand-on-scroll {
  display: inline-block;
}
@media (min-width: 576px) and (min-width: 576px) {
  .header-bg-transparent-sm:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
    background-color: #fff;
  }
  .header-bg-transparent-sm:not(.js-header-fix-moment) .header-section {
    background-color: transparent;
  }
}
@media (min-width: 576px) {
  .header-bg-transparent-sm .navbar-brand-collapsed,
.header-bg-transparent-sm .navbar-brand-on-scroll {
    display: none;
  }
}
@media (min-width: 576px) {
  .header-bg-transparent-sm.js-header-fix-moment .navbar-brand-default {
    display: none;
  }
  .header-bg-transparent-sm.js-header-fix-moment .navbar-brand-on-scroll {
    display: inline-block;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .header-bg-transparent-md:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
    background-color: #fff;
  }
  .header-bg-transparent-md:not(.js-header-fix-moment) .header-section {
    background-color: transparent;
  }
}
@media (min-width: 768px) {
  .header-bg-transparent-md .navbar-brand-collapsed,
.header-bg-transparent-md .navbar-brand-on-scroll {
    display: none;
  }
}
@media (min-width: 768px) {
  .header-bg-transparent-md.js-header-fix-moment .navbar-brand-default {
    display: none;
  }
  .header-bg-transparent-md.js-header-fix-moment .navbar-brand-on-scroll {
    display: inline-block;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .header-bg-transparent-lg:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
    background-color: #fff;
  }
  .header-bg-transparent-lg:not(.js-header-fix-moment) .header-section {
    background-color: transparent;
  }
}
@media (min-width: 992px) {
  .header-bg-transparent-lg .navbar-brand-collapsed,
.header-bg-transparent-lg .navbar-brand-on-scroll {
    display: none;
  }
}
@media (min-width: 992px) {
  .header-bg-transparent-lg.js-header-fix-moment .navbar-brand-default {
    display: none;
  }
  .header-bg-transparent-lg.js-header-fix-moment .navbar-brand-on-scroll {
    display: inline-block;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .header-bg-transparent-xl:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
    background-color: #fff;
  }
  .header-bg-transparent-xl:not(.js-header-fix-moment) .header-section {
    background-color: transparent;
  }
}
@media (min-width: 1200px) {
  .header-bg-transparent-xl .navbar-brand-collapsed,
.header-bg-transparent-xl .navbar-brand-on-scroll {
    display: none;
  }
}
@media (min-width: 1200px) {
  .header-bg-transparent-xl.js-header-fix-moment .navbar-brand-default {
    display: none;
  }
  .header-bg-transparent-xl.js-header-fix-moment .navbar-brand-on-scroll {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  .header-bg-transparent-sm .navbar-brand-default,
.header-bg-transparent-sm .navbar-brand-on-scroll {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .header-bg-transparent-md .navbar-brand-default,
.header-bg-transparent-md .navbar-brand-on-scroll {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .header-bg-transparent-lg .navbar-brand-default,
.header-bg-transparent-lg .navbar-brand-on-scroll {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .header-bg-transparent-xl .navbar-brand-default,
.header-bg-transparent-xl .navbar-brand-on-scroll {
    display: none;
  }
}
.header-bg-transparent .navbar-brand-default,
.header-bg-transparent .navbar-brand-on-scroll {
  display: none;
}

.header-bg-transparent .navbar-brand-default {
  display: flex;
}

@media (max-width: 991.98px) {
  .header-bg-transparent .navbar-nav,
.header-bg-transparent-sm .navbar-nav,
.header-bg-transparent-md .navbar-nav,
.header-bg-transparent-lg .navbar-nav {
    background-color: #fff;
    padding: 0.875rem 1.5rem;
  }
}

/*------------------------------------
  Header On Scroll
------------------------------------*/
.header-white-bg-on-scroll.scrolled .header-section {
  background-color: #fff;
}

/*------------------------------------
  Header White Nav Links
------------------------------------*/
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .navbar-nav-item:hover .nav-link {
  color: #fff;
}
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
  color: #fff;
}
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
  color: rgba(255, 255, 255, 0.7);
}
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
.header-white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
  color: #fff;
}
@media (min-width: 576px) {
  .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .navbar-nav-item:hover .nav-link {
    color: #fff;
  }
  .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
    color: #fff;
  }
  .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
.header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
.header-white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: #fff;
  }
}
@media (min-width: 768px) {
  .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .navbar-nav-item:hover .nav-link {
    color: #fff;
  }
  .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
    color: #fff;
  }
  .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
.header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
.header-white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: #fff;
  }
}
@media (min-width: 992px) {
  .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .navbar-nav-item:hover .nav-link {
    color: #fff;
  }
  .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
    color: #fff;
  }
  .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
.header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
.header-white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .navbar-nav-item:hover .nav-link {
    color: #fff;
  }
  .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .nav-link:hover {
    color: #fff;
  }
  .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
.header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.7);
  }
  .header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
.header-white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: #fff;
  }
}

/*------------------------------------
  Navbar Expand
------------------------------------*/
.navbar-expand {
  flex-wrap: wrap;
}
.navbar-expand .navbar-navbar-toggler {
  display: none;
}
.navbar-expand .navbar-nav {
  align-items: center;
}
.navbar-expand .navbar-nav .navbar-nav-last-item {
  text-align: right;
  padding-left: 1rem;
  padding-right: 0;
}
.navbar-expand .navbar-nav .nav-link {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.navbar-expand .navbar-nav .dropdown-menu {
  border-top: 0.1875rem solid hsl(174deg, 100%, 31%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}
.navbar-expand .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
  margin-top: -1.1875rem;
}
.navbar-expand .mega-menu-position-right-fix.dropdown-menu, .navbar-expand .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
  right: 20%;
  left: auto;
}
.navbar-expand .mega-menu-body {
  padding: 2.5rem 2.5rem;
  margin-left: 0;
  margin-right: 0;
}
.navbar-expand .navbar-promo {
  border-bottom-right-radius: 0.3125rem;
}
.navbar-expand .navbar-promo-card {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
}
.navbar-expand .navbar-promo-card-deck {
  display: flex;
  flex-flow: row wrap;
}
.navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
  position: relative;
}
.navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
  height: 100%;
  content: "";
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: wrap;
  }
  .navbar-expand-sm .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .navbar-nav {
    align-items: center;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid hsl(174deg, 100%, 31%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-sm .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-sm .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-sm .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-sm .navbar-promo-card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-promo-card-deck {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
    height: 100%;
    content: "";
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: wrap;
  }
  .navbar-expand-md .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-md .navbar-nav {
    align-items: center;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid hsl(174deg, 100%, 31%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-md .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-md .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-md .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-md .navbar-promo-card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-md .navbar-promo-card-deck {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
    height: 100%;
    content: "";
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: wrap;
  }
  .navbar-expand-lg .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid hsl(174deg, 100%, 31%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-lg .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-lg .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-lg .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-lg .navbar-promo-card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-promo-card-deck {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
    height: 100%;
    content: "";
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: wrap;
  }
  .navbar-expand-xl .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .navbar-nav {
    align-items: center;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid hsl(174deg, 100%, 31%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-xl .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-xl .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-xl .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-xl .navbar-promo-card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-promo-card-deck {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
    height: 100%;
    content: "";
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm .navbar-brand, .navbar-expand-sm .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-sm .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-sm .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-sm .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-expand-sm .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
    transform: rotate(-180deg);
  }
  .navbar-expand-sm .dropdown-item-toggle::after {
    transform: rotate(90deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
    transform: rotate(-90deg);
  }
  .navbar-expand-sm .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-sm .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-sm .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid hsl(168deg, 0%, 89%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-sm .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-sm .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md .navbar-brand, .navbar-expand-md .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-md .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-md .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-md .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-expand-md .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
    transform: rotate(-180deg);
  }
  .navbar-expand-md .dropdown-item-toggle::after {
    transform: rotate(90deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
    transform: rotate(-90deg);
  }
  .navbar-expand-md .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-md .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-md .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid hsl(168deg, 0%, 89%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-md .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-md .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg .navbar-brand, .navbar-expand-lg .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-lg .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-lg .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-lg .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
    transform: rotate(-180deg);
  }
  .navbar-expand-lg .dropdown-item-toggle::after {
    transform: rotate(90deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-lg .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid hsl(168deg, 0%, 89%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-lg .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-lg .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl .navbar-brand, .navbar-expand-xl .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-xl .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-xl .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-xl .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xl .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
    transform: rotate(-180deg);
  }
  .navbar-expand-xl .dropdown-item-toggle::after {
    transform: rotate(90deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-xl .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-xl .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid hsl(168deg, 0%, 89%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-xl .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-xl .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
}
.navbar-expand.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-expand .navbar-brand, .navbar-expand .navbar-brand > img {
  width: 7rem;
}
.navbar-expand .navbar-nav {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.navbar-expand .navbar-nav-last-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.navbar-expand .nav-link {
  display: flex;
  align-items: center;
}
.navbar-expand .nav-link-toggle::after {
  margin-left: auto;
}
.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
}
.navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
  transform: rotate(-180deg);
}
.navbar-expand .dropdown-item-toggle::after {
  transform: rotate(90deg);
}
.navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
  transform: rotate(-90deg);
}
.navbar-expand .mega-menu-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.navbar-expand .dropdown-menu {
  box-shadow: none;
}
.navbar-expand .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
  border-left: 0.1875rem solid hsl(168deg, 0%, 89%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.navbar-expand .dropdown-menu .mega-menu-body {
  padding-left: 1.5rem;
}
.navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
  margin-left: 1.5rem;
}
.navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
  padding-right: 0.6125rem;
}
.navbar-expand .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
}

@media (max-width: 767.98px) {
  .navbar-expand-sm .header-nav-last-item {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-xs .navbar-banner,
.navbar-expand-xs .navbar-product-banner,
.navbar-expand-sm .navbar-banner,
.navbar-expand-sm .navbar-product-banner,
.navbar-expand-md .navbar-banner,
.navbar-expand-md .navbar-product-banner,
.navbar-expand-lg .navbar-banner,
.navbar-expand-lg .navbar-product-banner {
    display: none;
  }
  .navbar-expand .navbar-brand {
    padding-top: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-banner,
.navbar-expand-xl .navbar-product-banner {
    display: none;
  }
}
@media (min-width: 1200px) {
  .header .mega-menu-position-right-fix-xl.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .navbar-brand {
    padding-top: 0.5rem;
  }
  .header .mega-menu-position-right-fix-md.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}
@media (min-width: 992px) {
  [class*=navbar-expand]:not(.navbar-expand-xl) {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg-collapse-block .navbar-collapse {
    display: block !important;
  }
}
/*------------------------------------
  Header Floating
------------------------------------*/
.header-floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2.5rem;
}
.header-floating.js-header-fix-moment {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
  margin-top: 0;
}
.header-floating.js-header-fix-moment .header-floating-inner {
  box-shadow: none;
}
.header-floating-inner {
  border-radius: 0.3125rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 576px) {
  .header-floating-sm {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .header-floating-sm.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
    margin-top: 0;
  }
  .header-floating-sm.js-header-fix-moment .header-floating-inner {
    box-shadow: none;
  }
  .header-floating-inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 768px) {
  .header-floating-md {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .header-floating-md.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
    margin-top: 0;
  }
  .header-floating-md.js-header-fix-moment .header-floating-inner {
    box-shadow: none;
  }
  .header-floating-inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 992px) {
  .header-floating-lg {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .header-floating-lg.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
    margin-top: 0;
  }
  .header-floating-lg.js-header-fix-moment .header-floating-inner {
    box-shadow: none;
  }
  .header-floating-inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .header-floating-xl {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .header-floating-xl.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
    margin-top: 0;
  }
  .header-floating-xl.js-header-fix-moment .header-floating-inner {
    box-shadow: none;
  }
  .header-floating-inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575.98px) {
  .header-floating-sm {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
  }
  .header-floating-sm.header-box-shadow .header-section, .header-floating-sm.header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: none;
  }
}
@media (max-width: 767.98px) {
  .header-floating-md {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
  }
  .header-floating-md.header-box-shadow .header-section, .header-floating-md.header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: none;
  }
}
@media (max-width: 991.98px) {
  .header-floating-lg {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
  }
  .header-floating-lg.header-box-shadow .header-section, .header-floating-lg.header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: none;
  }
}
@media (max-width: 1199.98px) {
  .header-floating-xl {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
  }
  .header-floating-xl.header-box-shadow .header-section, .header-floating-xl.header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: none;
  }
}
.header-floating {
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(117, 117, 117, 0.125);
}
.header-floating.header-box-shadow .header-section, .header-floating.header-box-shadow-on-scroll.scrolled .header-section {
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .header-floating-inner {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .header-floating-md .header-floating-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-floating-md .header-floating-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-floating-md .header-floating-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-floating-md .header-floating-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .header-floating .header-floating-inner,
.header-floating-sm .header-floating-inner,
.header-floating-lg .header-floating-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-floating .header-floating-inner::-webkit-scrollbar,
.header-floating-sm .header-floating-inner::-webkit-scrollbar,
.header-floating-lg .header-floating-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-floating .header-floating-inner::-webkit-scrollbar-thumb,
.header-floating-sm .header-floating-inner::-webkit-scrollbar-thumb,
.header-floating-lg .header-floating-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-floating .header-floating-inner:hover::-webkit-scrollbar-thumb,
.header-floating-sm .header-floating-inner:hover::-webkit-scrollbar-thumb,
.header-floating-lg .header-floating-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 1199.98px) {
  .header-floating-xl .header-floating-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-floating-xl .header-floating-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-floating-xl .header-floating-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-floating-xl .header-floating-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Absolute Positions
------------------------------------*/
.header-abs-top {
  position: absolute;
  top: 0;
  bottom: auto;
}
@media (min-width: 576px) {
  .header-abs-top-sm {
    position: absolute;
    top: 0;
    bottom: auto;
  }
}
@media (min-width: 768px) {
  .header-abs-top-md {
    position: absolute;
    top: 0;
    bottom: auto;
  }
}
@media (min-width: 992px) {
  .header-abs-top-lg {
    position: absolute;
    top: 0;
    bottom: auto;
  }
}
@media (min-width: 1200px) {
  .header-abs-top-xl {
    position: absolute;
    top: 0;
    bottom: auto;
  }
}

@media (max-width: 767.98px) {
  .header-abs-top-md .header-abs-top-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-abs-top-md .header-abs-top-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-abs-top-md .header-abs-top-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-abs-top-md .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .header-abs-top .header-abs-top-inner,
.header-abs-top-sm .header-abs-top-inner,
.header-abs-top-lg .header-abs-top-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-abs-top .header-abs-top-inner::-webkit-scrollbar,
.header-abs-top-sm .header-abs-top-inner::-webkit-scrollbar,
.header-abs-top-lg .header-abs-top-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-abs-top .header-abs-top-inner::-webkit-scrollbar-thumb,
.header-abs-top-sm .header-abs-top-inner::-webkit-scrollbar-thumb,
.header-abs-top-lg .header-abs-top-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-abs-top .header-abs-top-inner:hover::-webkit-scrollbar-thumb,
.header-abs-top-sm .header-abs-top-inner:hover::-webkit-scrollbar-thumb,
.header-abs-top-lg .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 1199.98px) {
  .header-abs-top-xl .header-abs-top-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-abs-top-xl .header-abs-top-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-abs-top-xl .header-abs-top-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-abs-top-xl .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Sticky Positions
------------------------------------*/
.header-sticky-top {
  position: fixed;
  bottom: auto;
  top: 0;
}
@media (min-width: 576px) {
  .header-sticky-top-sm {
    position: fixed;
    bottom: auto;
    top: 0;
  }
}
@media (min-width: 768px) {
  .header-sticky-top-md {
    position: fixed;
    bottom: auto;
    top: 0;
  }
}
@media (min-width: 992px) {
  .header-sticky-top-lg {
    position: fixed;
    bottom: auto;
    top: 0;
  }
}
@media (min-width: 1200px) {
  .header-sticky-top-xl {
    position: fixed;
    bottom: auto;
    top: 0;
  }
}

@media (max-width: 767.98px) {
  .header-sticky-top-md .header-sticky-top-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-sticky-top-md .header-sticky-top-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-sticky-top-md .header-sticky-top-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-sticky-top-md .header-sticky-top-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .header-sticky-top .header-sticky-top-inner,
.header-sticky-top-sm .header-sticky-top-inner,
.header-sticky-top-lg .header-sticky-top-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-sticky-top .header-sticky-top-inner::-webkit-scrollbar,
.header-sticky-top-sm .header-sticky-top-inner::-webkit-scrollbar,
.header-sticky-top-lg .header-sticky-top-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-sticky-top .header-sticky-top-inner::-webkit-scrollbar-thumb,
.header-sticky-top-sm .header-sticky-top-inner::-webkit-scrollbar-thumb,
.header-sticky-top-lg .header-sticky-top-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-sticky-top .header-sticky-top-inner:hover::-webkit-scrollbar-thumb,
.header-sticky-top-sm .header-sticky-top-inner:hover::-webkit-scrollbar-thumb,
.header-sticky-top-lg .header-sticky-top-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 1199.98px) {
  .header-sticky-top-xl .header-sticky-top-inner {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .header-sticky-top-xl .header-sticky-top-inner::-webkit-scrollbar {
    width: 0.5rem;
  }
  .header-sticky-top-xl .header-sticky-top-inner::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .header-sticky-top-xl .header-sticky-top-inner:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Animation
------------------------------------*/
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

/*------------------------------------
  Alert Styles
------------------------------------*/
.alert-primary {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.alert-primary hr {
  border-top-color: #008577;
}
.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  border-color: #56b4a9;
}
.alert-secondary hr {
  border-top-color: #4aa79c;
}
.alert-secondary .alert-link {
  color: hsl(168deg, 0%, 15%);
}

.alert-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.alert-success hr {
  border-top-color: #00b092;
}
.alert-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}
.alert-info hr {
  border-top-color: #0890a6;
}
.alert-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  border-color: #f5ca99;
}
.alert-warning hr {
  border-top-color: #f3be82;
}
.alert-warning .alert-link {
  color: hsl(168deg, 0%, 15%);
}

.alert-danger {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.alert-danger hr {
  border-top-color: #d83224;
}
.alert-danger .alert-link {
  color: #fff;
}

.alert-light {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}
.alert-light hr {
  border-top-color: #ededed;
}
.alert-light .alert-link {
  color: hsl(168deg, 0%, 15%);
}

.alert-dark {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}
.alert-dark hr {
  border-top-color: #121314;
}
.alert-dark .alert-link {
  color: #fff;
}

.alert-indigo {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}
.alert-indigo hr {
  border-top-color: #25116c;
}
.alert-indigo .alert-link {
  color: #fff;
}

.alert-navy {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}
.alert-navy hr {
  border-top-color: #1a2848;
}
.alert-navy .alert-link {
  color: #fff;
}

/* primary soft */
.alert-soft-primary {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}

/* secondary soft */
.alert-soft-secondary {
  color: #56b4a9;
  background-color: rgba(86, 180, 169, 0.1);
}

/* success soft */
.alert-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

/* info soft */
.alert-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

/* warning soft */
.alert-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

/* danger soft */
.alert-soft-danger {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
}

/* light soft */
.alert-soft-light {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
}

/* dark soft */
.alert-soft-dark {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
}

/* indigo soft */
.alert-soft-indigo {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
}

/* navy soft */
.alert-soft-navy {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Avatar
------------------------------------*/
.avatar {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
}
.avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 0.3125rem;
}
.avatar-initials {
  font-size: 1rem;
  font-weight: 600;
}
.avatar-circle {
  border-radius: 50%;
}
.avatar-circle .avatar-img {
  border-radius: 50%;
}

/*------------------------------------
  Avatar Group
------------------------------------*/
.avatar-group {
  display: flex;
}
.avatar-group .avatar-circle .avatar-initials {
  border-radius: 50%;
}
.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials,
.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials,
.avatar-group .avatar .avatar-img,
.avatar-group .avatar .avatar-initials {
  border: 2px solid #fff;
}
.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
  border: 5px solid #fff;
}
.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
  border: 7px solid #fff;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.6125rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.875rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1.5rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Initials
------------------------------------*/
.avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/*------------------------------------
  Avatar Sizes
------------------------------------*/
.avatar.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}
.avatar.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
}
.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}
.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.1171875rem;
  right: -0.1171875rem;
}
.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-sm {
  width: 2.6875rem;
  height: 2.6875rem;
}
.avatar-sm .avatar-initials {
  font-size: 0.8125rem;
}
.avatar-sm.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -0.09375rem;
  right: -0.09375rem;
}
.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -0.40625rem;
  right: -0.40625rem;
}

.avatar-lg {
  width: 4.25rem;
  height: 4.25rem;
}
.avatar-lg .avatar-initials {
  font-size: 1.25rem;
}
.avatar-lg.avatar-circle .avatar-status {
  bottom: -0.13125rem;
  right: -0.13125rem;
}
.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 0.09375rem;
  right: 0.09375rem;
}
.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.203125rem;
  right: -0.203125rem;
}

.avatar-xl {
  width: 5.625rem;
  height: 5.625rem;
}
.avatar-xl .avatar-initials {
  font-size: 2rem;
}
.avatar-xl.avatar-circle .avatar-status {
  bottom: 0.1640625rem;
  right: 0.1640625rem;
}
.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 0.234375rem;
  right: 0.234375rem;
}
.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 0.1354166667rem;
  right: 0.1354166667rem;
}

/*------------------------------------
  Avatar Status
------------------------------------*/
.avatar-status {
  position: absolute;
  bottom: -0.525rem;
  right: -0.525rem;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -0.2625rem;
  right: -0.2625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  font-size: 0.5rem;
}

.avatar-lg-status {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.75rem;
}

/*------------------------------------
  Avatar Styles
------------------------------------*/
/* primary soft */
.avatar-primary {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

/* secondary soft */
.avatar-secondary {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
}

/* success soft */
.avatar-success {
  color: #fff;
  background-color: #00c9a7;
}

/* info soft */
.avatar-info {
  color: #fff;
  background-color: #09a5be;
}

/* warning soft */
.avatar-warning {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
}

/* danger soft */
.avatar-danger {
  color: #fff;
  background-color: #de4437;
}

/* light soft */
.avatar-light {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
}

/* dark soft */
.avatar-dark {
  color: #fff;
  background-color: #1e2022;
}

/* indigo soft */
.avatar-indigo {
  color: #fff;
  background-color: #2d1582;
}

/* navy soft */
.avatar-navy {
  color: #fff;
  background-color: #21325b;
}

/* primary soft */
.avatar-soft-primary {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}

/* secondary soft */
.avatar-soft-secondary {
  color: #56b4a9;
  background-color: rgba(86, 180, 169, 0.1);
}

/* success soft */
.avatar-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

/* info soft */
.avatar-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

/* warning soft */
.avatar-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

/* danger soft */
.avatar-soft-danger {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
}

/* light soft */
.avatar-soft-light {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
}

/* dark soft */
.avatar-soft-dark {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
}

/* indigo soft */
.avatar-soft-indigo {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
}

/* navy soft */
.avatar-soft-navy {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Badge Soft
------------------------------------*/
/* primary soft */
.badge-soft-primary {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}
a.badge-soft-primary:hover, a.badge-soft-primary:focus {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

/* secondary soft */
.badge-soft-secondary {
  color: #56b4a9;
  background-color: rgba(86, 180, 169, 0.1);
}
a.badge-soft-secondary:hover, a.badge-soft-secondary:focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
}

/* success soft */
.badge-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
a.badge-soft-success:hover, a.badge-soft-success:focus {
  color: #fff;
  background-color: #00c9a7;
}

/* info soft */
.badge-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}
a.badge-soft-info:hover, a.badge-soft-info:focus {
  color: #fff;
  background-color: #09a5be;
}

/* warning soft */
.badge-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
a.badge-soft-warning:hover, a.badge-soft-warning:focus {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
}

/* danger soft */
.badge-soft-danger {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
}
a.badge-soft-danger:hover, a.badge-soft-danger:focus {
  color: #fff;
  background-color: #de4437;
}

/* light soft */
.badge-soft-light {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
}
a.badge-soft-light:hover, a.badge-soft-light:focus {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
}

/* dark soft */
.badge-soft-dark {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
}
a.badge-soft-dark:hover, a.badge-soft-dark:focus {
  color: #fff;
  background-color: #1e2022;
}

/* indigo soft */
.badge-soft-indigo {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
}
a.badge-soft-indigo:hover, a.badge-soft-indigo:focus {
  color: #fff;
  background-color: #2d1582;
}

/* navy soft */
.badge-soft-navy {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}
a.badge-soft-navy:hover, a.badge-soft-navy:focus {
  color: #fff;
  background-color: #21325b;
}

/*------------------------------------
  Custom Buttons
------------------------------------*/
/* primary button :hover, :focus, :active effects */
.btn-primary[href]:hover, .btn-primary[href]:focus, .btn-primary[href]:active, .btn-primary[type]:hover, .btn-primary[type]:focus, .btn-primary[type]:active {
  box-shadow: 0 4px 11px rgba(0, 158, 142, 0.35);
}
.btn-primary:not(label.btn), .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-primary:not([href]), .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}

.btn-primary:hover {
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}

/* secondary button :hover, :focus, :active effects */
.btn-secondary[href]:hover, .btn-secondary[href]:focus, .btn-secondary[href]:active, .btn-secondary[type]:hover, .btn-secondary[type]:focus, .btn-secondary[type]:active {
  box-shadow: 0 4px 11px rgba(86, 180, 169, 0.35);
}
.btn-secondary:not(label.btn), .btn-secondary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-secondary:not([href]), .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #56b4a9;
  border-color: #56b4a9;
}

.btn-secondary:hover {
  background-color: #56b4a9;
  border-color: #56b4a9;
}

/* success button :hover, :focus, :active effects */
.btn-success[href]:hover, .btn-success[href]:focus, .btn-success[href]:active, .btn-success[type]:hover, .btn-success[type]:focus, .btn-success[type]:active {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}
.btn-success:not(label.btn), .btn-success:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-success:not([href]), .btn-success:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:hover {
  background-color: #00c9a7;
  border-color: #00c9a7;
}

/* info button :hover, :focus, :active effects */
.btn-info[href]:hover, .btn-info[href]:focus, .btn-info[href]:active, .btn-info[type]:hover, .btn-info[type]:focus, .btn-info[type]:active {
  box-shadow: 0 4px 11px rgba(9, 165, 190, 0.35);
}
.btn-info:not(label.btn), .btn-info:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-info:not([href]), .btn-info:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #09a5be;
  border-color: #09a5be;
}

.btn-info:hover {
  background-color: #09a5be;
  border-color: #09a5be;
}

/* warning button :hover, :focus, :active effects */
.btn-warning[href]:hover, .btn-warning[href]:focus, .btn-warning[href]:active, .btn-warning[type]:hover, .btn-warning[type]:focus, .btn-warning[type]:active {
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.35);
}
.btn-warning:not(label.btn), .btn-warning:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-warning:not([href]), .btn-warning:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-warning:hover {
  background-color: #f5ca99;
  border-color: #f5ca99;
}

/* danger button :hover, :focus, :active effects */
.btn-danger[href]:hover, .btn-danger[href]:focus, .btn-danger[href]:active, .btn-danger[type]:hover, .btn-danger[type]:focus, .btn-danger[type]:active {
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}
.btn-danger:not(label.btn), .btn-danger:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-danger:not([href]), .btn-danger:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #de4437;
  border-color: #de4437;
}

.btn-danger:hover {
  background-color: #de4437;
  border-color: #de4437;
}

/* light button :hover, :focus, :active effects */
.btn-light[href]:hover, .btn-light[href]:focus, .btn-light[href]:active, .btn-light[type]:hover, .btn-light[type]:focus, .btn-light[type]:active {
  box-shadow: 0 4px 11px rgba(250, 250, 250, 0.35);
}
.btn-light:not(label.btn), .btn-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-light:not([href]), .btn-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}

.btn-light:hover {
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}

/* dark button :hover, :focus, :active effects */
.btn-dark[href]:hover, .btn-dark[href]:focus, .btn-dark[href]:active, .btn-dark[type]:hover, .btn-dark[type]:focus, .btn-dark[type]:active {
  box-shadow: 0 4px 11px rgba(30, 32, 34, 0.35);
}
.btn-dark:not(label.btn), .btn-dark:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-dark:not([href]), .btn-dark:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #1e2022;
  border-color: #1e2022;
}

.btn-dark:hover {
  background-color: #1e2022;
  border-color: #1e2022;
}

/* indigo button :hover, :focus, :active effects */
.btn-indigo[href]:hover, .btn-indigo[href]:focus, .btn-indigo[href]:active, .btn-indigo[type]:hover, .btn-indigo[type]:focus, .btn-indigo[type]:active {
  box-shadow: 0 4px 11px rgba(45, 21, 130, 0.35);
}
.btn-indigo:not(label.btn), .btn-indigo:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-indigo:not([href]), .btn-indigo:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #2d1582;
  border-color: #2d1582;
}

.btn-indigo:hover {
  background-color: #2d1582;
  border-color: #2d1582;
}

/* navy button :hover, :focus, :active effects */
.btn-navy[href]:hover, .btn-navy[href]:focus, .btn-navy[href]:active, .btn-navy[type]:hover, .btn-navy[type]:focus, .btn-navy[type]:active {
  box-shadow: 0 4px 11px rgba(33, 50, 91, 0.35);
}
.btn-navy:not(label.btn), .btn-navy:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-navy:not([href]), .btn-navy:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #21325b;
  border-color: #21325b;
}

.btn-navy:hover {
  background-color: #21325b;
  border-color: #21325b;
}

/*------------------------------------
  Custom Button Toggle
------------------------------------*/
/* primary custom toggle button */
.btn-custom-toggle-primary {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-primary:hover {
  color: hsl(174deg, 100%, 31%);
  background-color: transparent;
  border-color: hsl(174deg, 100%, 31%);
}
.btn-custom-toggle-primary:not(:disabled):not(.disabled):active, .btn-custom-toggle-primary:not(:disabled):not(.disabled).active, .btn-custom-toggle-primary:not(:disabled):not(.disabled):active, .btn-custom-toggle-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}

/* secondary custom toggle button */
.btn-custom-toggle-secondary {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-secondary:hover {
  color: #56b4a9;
  background-color: transparent;
  border-color: #56b4a9;
}
.btn-custom-toggle-secondary:not(:disabled):not(.disabled):active, .btn-custom-toggle-secondary:not(:disabled):not(.disabled).active, .btn-custom-toggle-secondary:not(:disabled):not(.disabled):active, .btn-custom-toggle-secondary:not(:disabled):not(.disabled).active {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  border-color: #56b4a9;
}

/* success custom toggle button */
.btn-custom-toggle-success {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-success:hover {
  color: #00c9a7;
  background-color: transparent;
  border-color: #00c9a7;
}
.btn-custom-toggle-success:not(:disabled):not(.disabled):active, .btn-custom-toggle-success:not(:disabled):not(.disabled).active, .btn-custom-toggle-success:not(:disabled):not(.disabled):active, .btn-custom-toggle-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

/* info custom toggle button */
.btn-custom-toggle-info {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-info:hover {
  color: #09a5be;
  background-color: transparent;
  border-color: #09a5be;
}
.btn-custom-toggle-info:not(:disabled):not(.disabled):active, .btn-custom-toggle-info:not(:disabled):not(.disabled).active, .btn-custom-toggle-info:not(:disabled):not(.disabled):active, .btn-custom-toggle-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

/* warning custom toggle button */
.btn-custom-toggle-warning {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-warning:hover {
  color: #f5ca99;
  background-color: transparent;
  border-color: #f5ca99;
}
.btn-custom-toggle-warning:not(:disabled):not(.disabled):active, .btn-custom-toggle-warning:not(:disabled):not(.disabled).active, .btn-custom-toggle-warning:not(:disabled):not(.disabled):active, .btn-custom-toggle-warning:not(:disabled):not(.disabled).active {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  border-color: #f5ca99;
}

/* danger custom toggle button */
.btn-custom-toggle-danger {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-danger:hover {
  color: #de4437;
  background-color: transparent;
  border-color: #de4437;
}
.btn-custom-toggle-danger:not(:disabled):not(.disabled):active, .btn-custom-toggle-danger:not(:disabled):not(.disabled).active, .btn-custom-toggle-danger:not(:disabled):not(.disabled):active, .btn-custom-toggle-danger:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}

/* light custom toggle button */
.btn-custom-toggle-light {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-light:hover {
  color: #fff;
  background-color: transparent;
  border-color: hsl(168deg, 0%, 98%);
}
.btn-custom-toggle-light:not(:disabled):not(.disabled):active, .btn-custom-toggle-light:not(:disabled):not(.disabled).active, .btn-custom-toggle-light:not(:disabled):not(.disabled):active, .btn-custom-toggle-light:not(:disabled):not(.disabled).active {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  border-color: hsl(168deg, 0%, 98%);
}

/* dark custom toggle button */
.btn-custom-toggle-dark {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-dark:hover {
  color: #1e2022;
  background-color: transparent;
  border-color: #1e2022;
}
.btn-custom-toggle-dark:not(:disabled):not(.disabled):active, .btn-custom-toggle-dark:not(:disabled):not(.disabled).active, .btn-custom-toggle-dark:not(:disabled):not(.disabled):active, .btn-custom-toggle-dark:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}

/* indigo custom toggle button */
.btn-custom-toggle-indigo {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-indigo:hover {
  color: #2d1582;
  background-color: transparent;
  border-color: #2d1582;
}
.btn-custom-toggle-indigo:not(:disabled):not(.disabled):active, .btn-custom-toggle-indigo:not(:disabled):not(.disabled).active, .btn-custom-toggle-indigo:not(:disabled):not(.disabled):active, .btn-custom-toggle-indigo:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}

/* navy custom toggle button */
.btn-custom-toggle-navy {
  color: hsl(168deg, 0%, 71%);
  border-color: hsl(168deg, 0%, 89%);
}
.btn-custom-toggle-navy:hover {
  color: #21325b;
  background-color: transparent;
  border-color: #21325b;
}
.btn-custom-toggle-navy:not(:disabled):not(.disabled):active, .btn-custom-toggle-navy:not(:disabled):not(.disabled).active, .btn-custom-toggle-navy:not(:disabled):not(.disabled):active, .btn-custom-toggle-navy:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.btn-custom-toggle-light {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Soft Button Styles
------------------------------------*/
/* primary soft */
.btn-soft-primary {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
  border-color: transparent;
}
.btn-soft-primary:hover, .btn-soft-primary:focus, .btn-soft-primary:active, .btn-soft-primary.active {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  box-shadow: 0 4px 11px rgba(0, 158, 142, 0.1);
}
.btn-soft-primary.disabled, .btn-soft-primary:disabled {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
  box-shadow: none;
}
.btn-soft-primary:not(:disabled):not(.disabled):active, .btn-soft-primary:not(:disabled):not(.disabled).active, .show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

/* secondary soft */
.btn-soft-secondary {
  color: #56b4a9;
  background-color: rgba(86, 180, 169, 0.1);
  border-color: transparent;
}
.btn-soft-secondary:hover, .btn-soft-secondary:focus, .btn-soft-secondary:active, .btn-soft-secondary.active {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
  box-shadow: 0 4px 11px rgba(86, 180, 169, 0.1);
}
.btn-soft-secondary.disabled, .btn-soft-secondary:disabled {
  color: #56b4a9;
  background-color: rgba(86, 180, 169, 0.1);
  box-shadow: none;
}
.btn-soft-secondary:not(:disabled):not(.disabled):active, .btn-soft-secondary:not(:disabled):not(.disabled).active, .show > .btn-soft-secondary.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
}

/* success soft */
.btn-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}
.btn-soft-success:hover, .btn-soft-success:focus, .btn-soft-success:active, .btn-soft-success.active {
  color: #fff;
  background-color: #00c9a7;
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.1);
}
.btn-soft-success.disabled, .btn-soft-success:disabled {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  box-shadow: none;
}
.btn-soft-success:not(:disabled):not(.disabled):active, .btn-soft-success:not(:disabled):not(.disabled).active, .show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
}

/* info soft */
.btn-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  border-color: transparent;
}
.btn-soft-info:hover, .btn-soft-info:focus, .btn-soft-info:active, .btn-soft-info.active {
  color: #fff;
  background-color: #09a5be;
  box-shadow: 0 4px 11px rgba(9, 165, 190, 0.1);
}
.btn-soft-info.disabled, .btn-soft-info:disabled {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  box-shadow: none;
}
.btn-soft-info:not(:disabled):not(.disabled):active, .btn-soft-info:not(:disabled):not(.disabled).active, .show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #09a5be;
}

/* warning soft */
.btn-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}
.btn-soft-warning:hover, .btn-soft-warning:focus, .btn-soft-warning:active, .btn-soft-warning.active {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.1);
}
.btn-soft-warning.disabled, .btn-soft-warning:disabled {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  box-shadow: none;
}
.btn-soft-warning:not(:disabled):not(.disabled):active, .btn-soft-warning:not(:disabled):not(.disabled).active, .show > .btn-soft-warning.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
}

/* danger soft */
.btn-soft-danger {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
  border-color: transparent;
}
.btn-soft-danger:hover, .btn-soft-danger:focus, .btn-soft-danger:active, .btn-soft-danger.active {
  color: #fff;
  background-color: #de4437;
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.1);
}
.btn-soft-danger.disabled, .btn-soft-danger:disabled {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
  box-shadow: none;
}
.btn-soft-danger:not(:disabled):not(.disabled):active, .btn-soft-danger:not(:disabled):not(.disabled).active, .show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #de4437;
}

/* light soft */
.btn-soft-light {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
  border-color: transparent;
}
.btn-soft-light:hover, .btn-soft-light:focus, .btn-soft-light:active, .btn-soft-light.active {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
  box-shadow: 0 4px 11px rgba(250, 250, 250, 0.1);
}
.btn-soft-light.disabled, .btn-soft-light:disabled {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
  box-shadow: none;
}
.btn-soft-light:not(:disabled):not(.disabled):active, .btn-soft-light:not(:disabled):not(.disabled).active, .show > .btn-soft-light.dropdown-toggle {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
}

/* dark soft */
.btn-soft-dark {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
  border-color: transparent;
}
.btn-soft-dark:hover, .btn-soft-dark:focus, .btn-soft-dark:active, .btn-soft-dark.active {
  color: #fff;
  background-color: #1e2022;
  box-shadow: 0 4px 11px rgba(30, 32, 34, 0.1);
}
.btn-soft-dark.disabled, .btn-soft-dark:disabled {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
  box-shadow: none;
}
.btn-soft-dark:not(:disabled):not(.disabled):active, .btn-soft-dark:not(:disabled):not(.disabled).active, .show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #1e2022;
}

/* indigo soft */
.btn-soft-indigo {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
  border-color: transparent;
}
.btn-soft-indigo:hover, .btn-soft-indigo:focus, .btn-soft-indigo:active, .btn-soft-indigo.active {
  color: #fff;
  background-color: #2d1582;
  box-shadow: 0 4px 11px rgba(45, 21, 130, 0.1);
}
.btn-soft-indigo.disabled, .btn-soft-indigo:disabled {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
  box-shadow: none;
}
.btn-soft-indigo:not(:disabled):not(.disabled):active, .btn-soft-indigo:not(:disabled):not(.disabled).active, .show > .btn-soft-indigo.dropdown-toggle {
  color: #fff;
  background-color: #2d1582;
}

/* navy soft */
.btn-soft-navy {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  border-color: transparent;
}
.btn-soft-navy:hover, .btn-soft-navy:focus, .btn-soft-navy:active, .btn-soft-navy.active {
  color: #fff;
  background-color: #21325b;
  box-shadow: 0 4px 11px rgba(33, 50, 91, 0.1);
}
.btn-soft-navy.disabled, .btn-soft-navy:disabled {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  box-shadow: none;
}
.btn-soft-navy:not(:disabled):not(.disabled):active, .btn-soft-navy:not(:disabled):not(.disabled).active, .show > .btn-soft-navy.dropdown-toggle {
  color: #fff;
  background-color: #21325b;
}

.btn-outline-secondary {
  border-color: hsl(168deg, 0%, 89%);
}

/*------------------------------------
  Button Sizes
------------------------------------*/
/* Wide */
.btn-wide {
  min-width: 10rem;
}

/* Extra Small */
.btn-xs {
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.275rem 0.75rem;
}

/* Icons */
.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 0.8125rem;
  width: 2.6875rem;
  height: 2.6875rem;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/
/* primary ghost button */
.btn-ghost-primary {
  color: hsl(174deg, 100%, 31%);
  background-color: transparent;
}
.btn-ghost-primary:hover, .btn-ghost-primary:focus, .btn-ghost-primary:active, .btn-ghost-primary.active {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}

/* secondary ghost button */
.btn-ghost-secondary {
  color: #56b4a9;
  background-color: transparent;
}
.btn-ghost-secondary:hover, .btn-ghost-secondary:focus, .btn-ghost-secondary:active, .btn-ghost-secondary.active {
  color: #56b4a9;
  background-color: rgba(0, 158, 142, 0.1);
}

/* success ghost button */
.btn-ghost-success {
  color: #00c9a7;
  background-color: transparent;
}
.btn-ghost-success:hover, .btn-ghost-success:focus, .btn-ghost-success:active, .btn-ghost-success.active {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

/* info ghost button */
.btn-ghost-info {
  color: #09a5be;
  background-color: transparent;
}
.btn-ghost-info:hover, .btn-ghost-info:focus, .btn-ghost-info:active, .btn-ghost-info.active {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

/* warning ghost button */
.btn-ghost-warning {
  color: #f5ca99;
  background-color: transparent;
}
.btn-ghost-warning:hover, .btn-ghost-warning:focus, .btn-ghost-warning:active, .btn-ghost-warning.active {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

/* danger ghost button */
.btn-ghost-danger {
  color: #de4437;
  background-color: transparent;
}
.btn-ghost-danger:hover, .btn-ghost-danger:focus, .btn-ghost-danger:active, .btn-ghost-danger.active {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
}

/* light ghost button */
.btn-ghost-light {
  color: hsl(168deg, 0%, 98%);
  background-color: transparent;
}
.btn-ghost-light:hover, .btn-ghost-light:focus, .btn-ghost-light:active, .btn-ghost-light.active {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
}

/* dark ghost button */
.btn-ghost-dark {
  color: #1e2022;
  background-color: transparent;
}
.btn-ghost-dark:hover, .btn-ghost-dark:focus, .btn-ghost-dark:active, .btn-ghost-dark.active {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
}

/* indigo ghost button */
.btn-ghost-indigo {
  color: #2d1582;
  background-color: transparent;
}
.btn-ghost-indigo:hover, .btn-ghost-indigo:focus, .btn-ghost-indigo:active, .btn-ghost-indigo.active {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
}

/* navy ghost button */
.btn-ghost-navy {
  color: #21325b;
  background-color: transparent;
}
.btn-ghost-navy:hover, .btn-ghost-navy:focus, .btn-ghost-navy:active, .btn-ghost-navy.active {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.btn-ghost-secondary:hover, .btn-ghost-secondary:focus, .btn-ghost-secondary:active {
  color: hsl(174deg, 100%, 31%);
}

/*------------------------------------
  Button Groups
------------------------------------*/
.btn-group-segment {
  position: relative;
  display: inline-flex;
  justify-content: center;
  background-color: hsl(168deg, 0%, 93%);
  border-radius: 0.3125rem;
  padding: 0.25rem 0.25rem;
}
.btn-group-segment .btn {
  color: hsl(168deg, 0%, 31%);
}
.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}
.btn-group-segment .btn:hover {
  color: hsl(174deg, 100%, 31%);
}
.btn-group-segment .btn.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}
.btn-group-segment .btn:not(:disabled):not(.disabled):active, .btn-group-segment .btn:not(:disabled):not(.disabled).active {
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}
.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:not(:first-child),
.btn-group-segment > .btn-group:not(:first-child) > .btn {
  border-radius: 0.3125rem;
}
.btn-group-segment.btn-group-pills {
  border-radius: 6.1875rem;
}
.btn-group-segment.btn-group-pills > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Button Status
------------------------------------*/
.btn-status {
  position: absolute;
  top: -0.4375rem;
  right: -0.4375rem;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}
.btn-status-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn svg {
  margin-bottom: 0;
}

label.btn {
  cursor: pointer !important;
}

.btn-pill {
  border-radius: 6.1875rem;
}

.btn-link {
  font-weight: 600;
}

.btn-icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}

/* Toggle */
.btn-toggle-default {
  display: inline-block;
}
.btn-toggle-toggled {
  display: none;
}
.btn.toggled .btn-toggle-default {
  display: none;
}
.btn.toggled .btn-toggle-toggled {
  display: inline-block;
}

/*------------------------------------
  Brand
------------------------------------*/
.brand {
  width: 100%;
  max-width: 7.5rem;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/
.breadcrumb-white {
  background-color: transparent;
}
.breadcrumb-white .breadcrumb-item {
  color: #fff;
}
.breadcrumb-white .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.7);
}
.breadcrumb-white .breadcrumb-link {
  color: rgba(255, 255, 255, 0.7);
}

.breadcrumb-no-gutter {
  padding: 0;
}

/*------------------------------------
  Card
------------------------------------*/
.card-2-count {
  column-count: 2;
}

.card-3-count {
  column-count: 3;
}

.card-4-count {
  column-count: 4;
}

.card-1-gap {
  column-gap: 0.5rem;
}

.card-2-gap {
  column-gap: 2rem;
}

.card-3-gap {
  column-gap: 3rem;
}

.card-4-gap {
  column-gap: 4rem;
}

.card-5-gap {
  column-gap: 5rem;
}

.card-columns {
  orphans: 1;
  widows: 1;
}
.card-columns .card {
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .card-sm-2-count {
    column-count: 2;
  }
  .card-sm-3-count {
    column-count: 3;
  }
  .card-sm-4-count {
    column-count: 4;
  }
  .card-sm-1-gap {
    column-gap: 0.5rem;
  }
  .card-sm-2-gap {
    column-gap: 2rem;
  }
  .card-sm-3-gap {
    column-gap: 3rem;
  }
  .card-sm-4-gap {
    column-gap: 4rem;
  }
  .card-sm-5-gap {
    column-gap: 5rem;
  }
  .card-sm-columns {
    orphans: 1;
    widows: 1;
  }
  .card-sm-columns .card {
    display: inline-block;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .card-md-2-count {
    column-count: 2;
  }
  .card-md-3-count {
    column-count: 3;
  }
  .card-md-4-count {
    column-count: 4;
  }
  .card-md-1-gap {
    column-gap: 0.5rem;
  }
  .card-md-2-gap {
    column-gap: 2rem;
  }
  .card-md-3-gap {
    column-gap: 3rem;
  }
  .card-md-4-gap {
    column-gap: 4rem;
  }
  .card-md-5-gap {
    column-gap: 5rem;
  }
  .card-md-columns {
    orphans: 1;
    widows: 1;
  }
  .card-md-columns .card {
    display: inline-block;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .card-lg-2-count {
    column-count: 2;
  }
  .card-lg-3-count {
    column-count: 3;
  }
  .card-lg-4-count {
    column-count: 4;
  }
  .card-lg-1-gap {
    column-gap: 0.5rem;
  }
  .card-lg-2-gap {
    column-gap: 2rem;
  }
  .card-lg-3-gap {
    column-gap: 3rem;
  }
  .card-lg-4-gap {
    column-gap: 4rem;
  }
  .card-lg-5-gap {
    column-gap: 5rem;
  }
  .card-lg-columns {
    orphans: 1;
    widows: 1;
  }
  .card-lg-columns .card {
    display: inline-block;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .card-xl-2-count {
    column-count: 2;
  }
  .card-xl-3-count {
    column-count: 3;
  }
  .card-xl-4-count {
    column-count: 4;
  }
  .card-xl-1-gap {
    column-gap: 0.5rem;
  }
  .card-xl-2-gap {
    column-gap: 2rem;
  }
  .card-xl-3-gap {
    column-gap: 3rem;
  }
  .card-xl-4-gap {
    column-gap: 4rem;
  }
  .card-xl-5-gap {
    column-gap: 5rem;
  }
  .card-xl-columns {
    orphans: 1;
    widows: 1;
  }
  .card-xl-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.card {
  border-width: 0;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}

@media (min-width: 576px) {
  .card-group > .card + .card {
    border-left: 1px solid hsl(168deg, 0%, 89%);
  }
}
/* Background Color */
.card-bg-light {
  background-color: hsl(168deg, 0%, 93%);
}
.card-bg-light:hover {
  background-color: #ebebeb;
}

/* Frame */
.card-frame {
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  box-shadow: none;
  transition: 0.3s;
}
.card-frame-highlighted, .card-frame:hover {
  border-color: rgba(0, 158, 142, 0.7);
}

/* Shadow On Hover */
.card-hover-shadow {
  box-shadow: none;
}
.card-hover-shadow:hover {
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}

/* Collapse */
.card-collapse {
  padding: 0;
  transition: 0.3s ease-in-out;
}
.card-collapse:hover {
  background-color: hsl(168deg, 0%, 93%);
}
.card-collapse .card-body {
  color: hsl(168deg, 0%, 31%);
}
.card-btn {
  color: #1e2022;
  text-align: left;
  white-space: inherit;
}
.card-btn-toggle {
  display: inline-block;
  color: hsl(174deg, 100%, 31%);
}
.card-btn-toggle-default {
  display: none;
}
.card-btn-toggle-active {
  display: block;
}
.card-btn.collapsed .card-btn-toggle-default {
  display: block;
}
.card-btn.collapsed .card-btn-toggle-active {
  display: none;
}

/* IE image rendering fix */
.card-img-top,
.card-img-bottom {
  min-height: 1px;
}

/*------------------------------------
  Card Gutters
------------------------------------*/
@media (min-width: 576px) {
  .card-sm-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-sm-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-sm-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-sm-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-sm-gutters-3 {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .card-sm-gutters-3 .card {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}
@media (min-width: 768px) {
  .card-md-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-md-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-md-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-md-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-md-gutters-3 {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .card-md-gutters-3 .card {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}
@media (min-width: 992px) {
  .card-lg-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-lg-gutters-1 .card {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .card-lg-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-lg-gutters-2 .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .card-lg-gutters-3 {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .card-lg-gutters-3 .card {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}
/*------------------------------------
  Card Group - Break
------------------------------------*/
@media (max-width: 767.98px) {
  .card-group-sm-break {
    display: block;
  }
  .card-group-sm-break > .card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-sm-break > .card + .card {
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
  .card-group-sm-break > .card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:first-child .card-img-top,
.card-group-sm-break > .card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-sm-break > .card:first-child .card-img-bottom,
.card-group-sm-break > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-img-top,
.card-group-sm-break > .card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-img-bottom,
.card-group-sm-break > .card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .card-group-md-break {
    display: block;
  }
  .card-group-md-break > .card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-md-break > .card + .card {
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
  .card-group-md-break > .card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:first-child .card-img-top,
.card-group-md-break > .card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-md-break > .card:first-child .card-img-bottom,
.card-group-md-break > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-img-top,
.card-group-md-break > .card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-img-bottom,
.card-group-md-break > .card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .card-group-lg-break {
    display: block;
  }
  .card-group-lg-break > .card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-lg-break > .card + .card {
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
  }
  .card-group-lg-break > .card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:first-child .card-img-top,
.card-group-lg-break > .card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-lg-break > .card:first-child .card-img-bottom,
.card-group-lg-break > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-img-top,
.card-group-lg-break > .card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-img-bottom,
.card-group-lg-break > .card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}
/*------------------------------------
  Sidebar Navigation
------------------------------------*/
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1003;
  width: 23.4375rem;
  height: 100%;
  background-color: #fff;
  box-shadow: -5px 0px 12px rgba(79, 79, 79, 0.1);
}
.sidebar-body, .sidebar-content {
  height: 100%;
}
.sidebar-left {
  right: auto;
  left: 0;
  box-shadow: 5px 0px 12px rgba(79, 79, 79, 0.1);
}
.sidebar-scroller {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.sidebar-container {
  position: relative;
  height: 100%;
  min-height: 100%;
}
.sidebar-footer {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.sidebar-footer-offset {
  height: 100%;
  box-sizing: border-box;
}

/*------------------------------------
  Clients
------------------------------------*/
.clients {
  width: 100%;
  max-width: 6rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/*------------------------------------
  Column Divider
------------------------------------*/
.column-divider {
  position: relative;
}
.column-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
  content: "";
}
@media (min-width: 576px) {
  .column-divider-sm {
    position: relative;
  }
  .column-divider-sm::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
    content: "";
  }
}
@media (min-width: 768px) {
  .column-divider-md {
    position: relative;
  }
  .column-divider-md::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
    content: "";
  }
}
@media (min-width: 992px) {
  .column-divider-lg {
    position: relative;
  }
  .column-divider-lg::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
    content: "";
  }
}
@media (min-width: 1200px) {
  .column-divider-xl {
    position: relative;
  }
  .column-divider-xl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid hsl(168deg, 0%, 89%);
    content: "";
  }
}

.column-divider-20deg::before {
  transform: rotate(20deg);
  left: -1rem;
}

/*------------------------------------
  Divider
------------------------------------*/
.divider {
  position: relative;
  display: inline-block;
  font-size: 1rem;
}
.divider::before, .divider::after {
  position: absolute;
  top: 50%;
  content: "";
}
.divider::before {
  right: 100%;
  background-image: linear-gradient(45deg, transparent, #d4d4d4);
  background-repeat: repeat-x;
}
.divider::after {
  left: 100%;
  background-image: linear-gradient(45deg, #d4d4d4, transparent);
  background-repeat: repeat-x;
}

/*------------------------------------
  Divider Sizes
------------------------------------*/
.divider::before, .divider::after {
  width: 10rem;
  height: 0.0625rem;
  transform: translateY(-50%);
}
.divider::before {
  margin-right: 0.625rem;
}
.divider::after {
  margin-left: 0.625rem;
}
@media (max-width: 767.98px) {
  .divider::before, .divider::after {
    width: 4rem;
  }
}
.divider-xs {
  font-size: 0.75rem;
}
.divider-xs::before, .divider-xs::after {
  width: 2.5rem;
  height: 0.0625rem;
  transform: translateY(-50%);
}

/*------------------------------------
  Divider Styles
------------------------------------*/
/* Text */
.divider-text {
  color: hsl(168deg, 0%, 46%);
}

/*------------------------------------
  Dropdown
------------------------------------*/
/* Menu */
.dropdown-menu {
  box-shadow: 0 5px 9px rgba(0, 158, 142, 0.075), 0 5px 9px rgba(79, 79, 79, 0.075);
  font-size: 0.875rem;
}

/* Nav Link */
.dropdown-nav-link {
  color: hsl(168deg, 0%, 31%);
  font-size: 0.875rem;
}
.dropdown-nav-link:hover {
  color: #1e2022;
}

/* Item */
.dropdown-item {
  font-size: 0.875rem;
}
.dropdown-item:hover {
  color: hsl(174deg, 100%, 31%);
}
.dropdown-item.active {
  color: hsl(174deg, 100%, 31%);
}
.dropdown-item-icon {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  min-width: 1rem;
  max-width: 1rem;
  margin-right: 0.25rem;
}

/* Toggle */
.dropdown-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f107";
  margin-left: 0.5rem;
}
.dropdown-toggle-collapse::after {
  transition: 0.3s;
}
.dropdown-toggle-collapse[aria-expanded=true]::after {
  transform: rotate(-180deg);
}

/* Dropdown Positions */
.dropdown-menu-top {
  top: 0;
}

.dropdown-menu-bottom {
  top: auto;
  bottom: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-top {
    top: 0;
  }
  .dropdown-menu-sm-bottom {
    top: auto;
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-top {
    top: 0;
  }
  .dropdown-menu-md-bottom {
    top: auto;
    bottom: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-top {
    top: 0;
  }
  .dropdown-menu-lg-bottom {
    top: auto;
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-top {
    top: 0;
  }
  .dropdown-menu-xl-bottom {
    top: auto;
    bottom: 0;
  }
}
/*------------------------------------
  Dropdown Card
------------------------------------*/
.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .dropdown-card {
    min-width: 25rem;
  }
}

/*------------------------------------
  Dropdown Sizes
------------------------------------*/
@media (min-width: 576px) {
  .dropdown-menu-size-md {
    width: 440px;
  }
  .dropdown-menu-size-lg {
    width: 340px;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-size-lg {
    width: 440px;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-size-lg {
    width: 740px;
  }
}
/*------------------------------------
  Form
------------------------------------*/
.input-group .input-group-prepend,
.input-group .input-group-append {
  z-index: 4;
}

.custom-select:focus,
.form-control:focus {
  box-shadow: 0 0 10px rgba(0, 158, 142, 0.1);
}

.input-group-text {
  border-color: hsl(168deg, 0%, 89%);
}

/* Input Label */
.input-label {
  display: block;
  color: #1e2022;
  font-size: 0.875rem;
}

/* Form Borderless */
.input-group-borderless .form-control,
.input-group-borderless .input-group-text {
  border: none;
}
.input-group-borderless .form-control:focus,
.input-group-borderless .input-group-text:focus {
  box-shadow: none;
}

/*------------------------------------
  Merge
------------------------------------*/
.input-group-merge {
  position: relative;
}
.input-group-merge .input-group-prepend,
.input-group-merge .input-group-append {
  position: absolute;
  top: 0;
  bottom: 0;
}
.input-group-merge .input-group-prepend {
  left: 0;
}
.input-group-merge .input-group-prepend .input-group-text {
  padding: 0 0 0 1rem;
}
.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-merge .input-group-append {
  right: 0;
}
.input-group-merge .input-group-append .input-group-text {
  padding: 0 1rem 0 0;
}
.input-group-merge .input-group-append .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-merge .input-group-text {
  z-index: 4;
  border: none;
}
.input-group-merge .custom-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 2.5rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-merge .custom-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 2.5rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/*------------------------------------
  Form Pill
------------------------------------*/
.input-group-pill {
  border-radius: 6.1875rem;
}
.input-group-pill > .form-control:first-child {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill > .form-control:last-child {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill > .input-group-prepend > .btn {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill > .input-group-append > .btn {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .form-control:not(:last-child) {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .input-group-prepend > .input-group-text,
.input-group-pill.input-group-merge .input-group-prepend > .btn {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .form-control:not(:first-child) {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .input-group-append > .input-group-text,
.input-group-pill.input-group-merge .input-group-append > .btn {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

/*------------------------------------
  Form Sizes
------------------------------------*/
.input-group-sm.input-group-merge .custom-select:not(:first-child),
.input-group-sm.input-group-merge .form-control:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group-sm.input-group-merge .custom-select:not(:last-child),
.input-group-sm.input-group-merge .form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group-sm.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group-sm.input-group-merge .input-group-append .input-group-text:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.input-group-lg.input-group-merge .custom-select:not(:first-child),
.input-group-lg.input-group-merge .form-control:not(:first-child) {
  padding-left: 3rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-lg.input-group-merge .custom-select:not(:last-child),
.input-group-lg.input-group-merge .form-control:not(:last-child) {
  padding-right: 3rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.input-group-lg.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-lg.input-group-merge .input-group-append .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/*------------------------------------
  Flush
------------------------------------*/
.input-group-flush .form-control {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.input-group-flush .form-control.is-valid, .input-group-flush .form-control.is-valid:focus, .input-group-flush .form-control.is-invalid, .input-group-flush .form-control.is-invalid:focus {
  box-shadow: none;
}
.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group-merge .input-group-prepend .input-group-text {
  padding: 0;
}
.input-group-flush.input-group .form-control:not(:first-child), .input-group-flush.input-group .form-control:not(:last-child), .input-group-flush.input-group .input-group-append .input-group-text:first-child, .input-group-flush.input-group .input-group-append .input-group-text:last-child {
  border-radius: 0;
}

/*------------------------------------
  States
------------------------------------*/
.form-control.is-valid {
  background-size: 1rem 1rem;
}
.form-control.is-valid, .form-control.is-valid:focus {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}
.form-control.is-invalid {
  background-size: 1rem 1rem;
}
.form-control.is-invalid, .form-control.is-invalid:focus {
  box-shadow: 0 0 10px rgba(222, 68, 55, 0.1);
}

/*------------------------------------
  Bookmark Checkbox
------------------------------------*/
.bookmark-checkbox {
  position: relative;
  display: block;
  line-height: 0;
}
.bookmark-checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.bookmark-checkbox-label {
  position: relative;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.75rem;
  color: hsl(168deg, 0%, 31%);
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 0;
  transition: 0.3s;
}
.bookmark-checkbox-label::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "\f02e";
  font-family: "Font Awesome 5 Free";
}
.bookmark-checkbox-input:checked ~ .bookmark-checkbox-label {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  box-shadow: 0 3px 6px 0 rgba(0, 158, 142, 0.25);
  transform: scale(1.1);
}

/*------------------------------------
  Checkbox Outline
------------------------------------*/
.checkbox-outline {
  padding-left: 0;
  margin-right: 0;
}
.checkbox-outline-label {
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  cursor: pointer;
}
.checkbox-outline-input:checked ~ .checkbox-outline-label {
  border-color: hsl(174deg, 100%, 31%);
}

/*------------------------------------
  Checkbox Icon
------------------------------------*/
.checkbox-icon-label::after {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: none;
  color: hsl(174deg, 100%, 31%);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  line-height: normal;
  content: "\f058";
}
.checkbox-icon-input:checked ~ .checkbox-icon-label::after {
  display: block;
  border-color: hsl(174deg, 100%, 31%);
}

/*------------------------------------
  Custom Checkbox
------------------------------------*/
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: rgba(0, 158, 142, 0);
}

/*------------------------------------
  Toggle Switch
------------------------------------*/
.toggle-switch {
  display: flex;
  margin-bottom: 0;
}

.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.toggle-switch-content {
  flex: 1;
  margin-left: 0.5rem;
}

.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: hsl(168deg, 0%, 89%);
  background-clip: content-box;
  border: 0.125rem solid transparent;
  border-radius: 6.1875rem;
  transition: 0.3s;
}

.toggle-switch-indicator {
  position: absolute;
  left: 0.125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  transform: initial;
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
  border-radius: 50%;
  transform: translate3d(0, 50%, 0);
  transition: 0.3s;
}

.toggle-switch-input:checked + .toggle-switch-label {
  background-color: hsl(174deg, 100%, 31%);
}

.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  transform: translate3d(1.025rem, 50%, 0);
}

.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(227, 227, 227, 0.5);
}

.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(0, 158, 142, 0.5);
}

/*------------------------------------
  File Attachment Button
------------------------------------*/
.file-attachment-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.file-attachment-btn-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

/*------------------------------------
  File Attachment Input
------------------------------------*/
.file-attachment-input {
  position: relative;
  display: block;
  background-color: hsl(168deg, 0%, 93%);
  border: 0.125rem dashed hsl(168deg, 0%, 89%);
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0.3125rem;
  padding: 4rem 4rem;
  margin-bottom: 0;
}
.file-attachment-input-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}
.file-attachment-input:hover {
  background-color: hsl(168deg, 0%, 89%);
}

/*------------------------------------
  Go To
------------------------------------*/
.go-to {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 2.6875rem;
  height: 2.6875rem;
  background-color: rgba(86, 180, 169, 0.1);
  color: hsl(168deg, 0%, 31%);
  font-size: 0.8125rem;
  opacity: 0.5;
  border-radius: 50%;
  transition: 0.3s ease-out;
}
.go-to:hover, .go-to:focus:hover {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  opacity: 1;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
}
.icon-circle {
  border-radius: 50%;
}
.icon.icon-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.icon.icon-sm {
  font-size: 0.8125rem;
  width: 2.6875rem;
  height: 2.6875rem;
}
.icon.icon-lg {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

/*------------------------------------
  Icon
------------------------------------*/
/* primary */
.icon-primary {
  color: #fff;
  border-color: hsl(174deg, 100%, 31%);
  background-color: hsl(174deg, 100%, 31%);
}

/* secondary */
.icon-secondary {
  color: hsl(168deg, 0%, 15%);
  border-color: #56b4a9;
  background-color: #56b4a9;
}

/* success */
.icon-success {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}

/* info */
.icon-info {
  color: #fff;
  border-color: #09a5be;
  background-color: #09a5be;
}

/* warning */
.icon-warning {
  color: hsl(168deg, 0%, 15%);
  border-color: #f5ca99;
  background-color: #f5ca99;
}

/* danger */
.icon-danger {
  color: #fff;
  border-color: #de4437;
  background-color: #de4437;
}

/* light */
.icon-light {
  color: hsl(168deg, 0%, 15%);
  border-color: hsl(168deg, 0%, 98%);
  background-color: hsl(168deg, 0%, 98%);
}

/* dark */
.icon-dark {
  color: #fff;
  border-color: #1e2022;
  background-color: #1e2022;
}

/* indigo */
.icon-indigo {
  color: #fff;
  border-color: #2d1582;
  background-color: #2d1582;
}

/* navy */
.icon-navy {
  color: #fff;
  border-color: #21325b;
  background-color: #21325b;
}

/* primary soft */
.icon-soft-primary {
  color: hsl(174deg, 100%, 31%);
  background: rgba(0, 158, 142, 0.1);
}

/* secondary soft */
.icon-soft-secondary {
  color: #56b4a9;
  background: rgba(86, 180, 169, 0.1);
}

/* success soft */
.icon-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}

/* info soft */
.icon-soft-info {
  color: #09a5be;
  background: rgba(9, 165, 190, 0.1);
}

/* warning soft */
.icon-soft-warning {
  color: #f5ca99;
  background: rgba(245, 202, 153, 0.1);
}

/* danger soft */
.icon-soft-danger {
  color: #de4437;
  background: rgba(222, 68, 55, 0.1);
}

/* light soft */
.icon-soft-light {
  color: hsl(168deg, 0%, 98%);
  background: rgba(250, 250, 250, 0.1);
}

/* dark soft */
.icon-soft-dark {
  color: #1e2022;
  background: rgba(30, 32, 34, 0.1);
}

/* indigo soft */
.icon-soft-indigo {
  color: #2d1582;
  background: rgba(45, 21, 130, 0.1);
}

/* navy soft */
.icon-soft-navy {
  color: #21325b;
  background: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.media-viewer {
  position: relative;
  display: block;
}
.media-viewer:hover .media-viewer-icon {
  opacity: 1;
  transform: scale(1);
}
.media-viewer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.media-viewer-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.7);
  transition: 0.3s;
}
.media-viewer-icon:hover, .media-viewer-icon:focus {
  color: #fff;
}
.media-viewer-icon-active {
  opacity: 1;
  transform: scale(1);
}

/*------------------------------------
  Video Player
------------------------------------*/
.video-player {
  position: relative;
}
.video-player-preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}
.video-player-played .video-player-preview {
  opacity: 0;
  pointer-events: none;
}
.video-player-btn {
  z-index: 3;
  transition: transform 0.3s ease-in-out;
}
.video-player-played .video-player-btn {
  animation: videoPlayerButton 0.4s ease-in-out forwards;
  pointer-events: none;
}
.video-player-btn:hover .video-player-icon {
  color: hsl(174deg, 100%, 31%);
  transform: scale(1.1);
}
.video-player-icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 4.25rem;
  height: 4.25rem;
  font-size: 0.8125rem;
  border-radius: 50%;
  color: #1e2022;
  background-color: #fff;
  transition: 0.3s;
}

/* Positions */
.video-player-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}
/*------------------------------------
  Device Mockups
------------------------------------*/
.device {
  position: relative;
}

.device-pointer {
  width: 65%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.device-macbook-screen {
  position: absolute;
  top: 6.8%;
  left: 12.3%;
  width: 75.1%;
  height: 81.1%;
  object-fit: cover;
}

.device-iphone-x {
  border-radius: 2.75rem;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}
.device-iphone-x-frame {
  position: relative;
  z-index: 2;
  max-width: 100%;
  height: auto;
}
.device-iphone-x-screen {
  position: absolute;
  top: 2.4%;
  left: 4%;
  width: 92.4%;
  height: 96%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.device-half-iphone-x {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}
.device-half-iphone-x-frame {
  position: relative;
  z-index: 2;
  max-width: 100%;
  height: auto;
}
.device-half-iphone-x-screen {
  position: absolute;
  top: 2.6%;
  left: 4.1%;
  width: 91.8%;
  height: 97.1%;
  object-fit: cover;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
}

.device-ipad {
  border-radius: 1.25rem;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}
.device-ipad-frame {
  position: relative;
  z-index: 2;
  max-width: 100%;
  height: auto;
}
.device-ipad-screen {
  position: absolute;
  top: 2.6%;
  left: 4.2%;
  width: 91.8%;
  height: 94.2%;
  object-fit: cover;
}

.device-horizontal-ipad {
  border-radius: 1.5rem;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}
.device-horizontal-ipad-frame {
  position: relative;
  z-index: 2;
  max-width: 100%;
  height: auto;
}
.device-horizontal-ipad-screen {
  position: absolute;
  top: 3.8%;
  left: 3.2%;
  width: 93.8%;
  height: 92%;
  object-fit: cover;
}

.device-browser {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 85%;
  overflow: hidden;
  box-shadow: 0 0.625rem 2.5rem 0.625rem rgba(117, 117, 117, 0.175);
  border-radius: 0.5rem;
}
.device-browser-screen {
  position: absolute;
  top: 6%;
  left: 1.2%;
  width: 97.7%;
  height: 100%;
  object-fit: cover;
}

.device-wrapper {
  position: relative;
}
.device-wrapper .device-iphone-x {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 22%;
}

/*------------------------------------
  Modal
------------------------------------*/
.modal-header {
  align-items: center;
}

.modal-footer {
  padding: 1rem 1.5rem;
}

/*------------------------------------
  Page Preloader
------------------------------------*/
.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background-color: #fff;
}
.page-preloader-content-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-link {
  cursor: pointer;
  text-align: center;
  min-width: 2.25rem;
}

.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/* Sizes */
.pagination-sm .page-link {
  border-radius: 0.25rem;
}

.pagination-lg .page-link {
  border-radius: 0.5rem;
}

/*------------------------------------
  Navs
------------------------------------*/
.nav .nav-link {
  color: hsl(168deg, 0%, 31%);
}
.nav .nav-link:not(.active):hover {
  color: hsl(174deg, 100%, 31%);
}

.nav:not(.nav-pills) .nav-link.active {
  color: hsl(174deg, 100%, 31%);
}

/* Small Size */
.nav-sm .nav-link {
  font-size: 0.875rem;
}

/* No Gutters */
.nav-x-0 .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-y-0 .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-x-sm .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

/* Shadow */
.nav-shadow {
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}

/* Classic */
.nav-classic {
  border-bottom: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.nav-classic .nav-link {
  color: hsl(168deg, 0%, 31%);
  border-bottom: 0.1875rem solid transparent;
  padding: 1rem 1.5rem;
  margin-bottom: -0.125rem;
  border-radius: 0;
  transition: 0.3s;
}
.nav-classic .nav-link:hover {
  color: hsl(174deg, 100%, 31%);
}
.nav-classic .nav-link.active {
  color: hsl(174deg, 100%, 31%);
  border-bottom-color: hsl(174deg, 100%, 31%);
}

/* Rounded */
.nav-rounded {
  border-radius: 0.3125rem;
}
.nav-rounded .nav-item:first-child .nav-link {
  border-bottom-left-radius: 0.3125rem;
}
.nav-rounded .nav-item:last-child .nav-link {
  border-bottom-right-radius: 0.3125rem;
}

/* White Color */
.nav-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.nav-white .nav-link.active {
  color: hsl(174deg, 100%, 31%);
  background-color: #fff;
}
.nav-white .nav-link:not(.active):hover {
  color: #fff;
}

/* White Border Color */
.nav.nav-border-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.125rem solid transparent;
}
.nav.nav-border-white .nav-link.active {
  color: #fff;
  border-bottom-color: #fff;
}
.nav.nav-border-white .nav-link:not(.active):hover {
  color: #fff;
}

/* Box */
.nav-box .nav-link {
  color: #1e2022;
  border-radius: 0.3125rem;
}
.nav-box .nav-link.active {
  color: hsl(174deg, 100%, 31%);
  background-color: #fff;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
}

/* Segment */
.nav-segment {
  position: relative;
  background-color: hsl(168deg, 0%, 93%);
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;
}
.nav-segment:not(.nav-fill) {
  display: inline-flex;
}
.nav-segment .nav-link {
  color: hsl(168deg, 0%, 31%);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.625rem 1.125rem;
  border-radius: 0.3125rem;
}
.nav-segment .nav-link:hover {
  color: hsl(174deg, 100%, 31%);
}
.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}
.nav-segment.nav-pills {
  border-radius: 6.1875rem;
}
.nav-segment.nav-pills .nav-link {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  background-color: hsl(168deg, 0%, 93%);
  height: 12.5rem;
}

/*------------------------------------
  Popover
------------------------------------*/
.popover {
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}

.popover-header {
  font-weight: 600;
}

/*------------------------------------
  Scrollbar
------------------------------------*/
.scrollbar {
  overflow: hidden;
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar {
  width: 0.5rem;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(212, 212, 212, 0.6);
  visibility: hidden;
}
.scrollbar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.scrollbar-horizontal {
  display: flex;
  max-width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.scrollbar-horizontal::-webkit-scrollbar {
  height: 0.25rem;
}
.scrollbar-horizontal::-webkit-scrollbar-thumb {
  background-color: rgba(212, 212, 212, 0.6);
  visibility: hidden;
}
.scrollbar-horizontal:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*------------------------------------
  Search Form - Push Top
------------------------------------*/
.search-push-top {
  display: none;
  background-color: hsl(168deg, 0%, 93%);
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: inset 0 -10px 20px -10px rgba(181, 181, 181, 0.05);
}
.search-push-top-content {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}
.search-push-top-close-btn {
  position: absolute;
  top: -1.5625rem;
  right: 0.4375rem;
}
@media (max-width: 575.98px) {
  .search-push-top {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

/* Banner */
.search-push-top-banner {
  background-color: #fff;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
  padding-right: 1.5625rem;
  padding-top: 1.875rem;
  overflow: hidden;
}
.search-push-top-banner-container {
  position: relative;
  width: 75%;
  min-height: 11.5625rem;
}
.search-push-top-banner-img {
  position: absolute;
  bottom: -0.625rem;
  left: -0.625rem;
  transition: all 0.3s ease-in-out;
}
.search-push-top-banner:hover .search-push-top-banner-img:first-child {
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Search Form - Slide Down
------------------------------------*/
.search-slide-down {
  position: fixed;
  z-index: 1003;
  top: 5rem;
  left: calc(50% - 18rem) !important;
  width: 36rem;
}
@media (max-width: 767.98px) {
  .search-slide-down {
    left: 5% !important;
    right: 5% !important;
    width: 90%;
  }
}
.search-slide-down-trigger.active .search-slide-down-trigger-icon::before {
  content: "\f00d";
}
.search-slide-down-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  display: none;
  width: 100%;
  height: 100%;
}
.search-slide-down .search-slide-down-input {
  opacity: 0;
  transform: translateY(-2rem);
  transition: transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
}
.search-slide-down .search-slide-down-suggestions {
  opacity: 0;
  transform: translateY(-3rem);
  transition: transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
}
.search-slide-down-show .search-slide-down-input, .search-slide-down-show .search-slide-down-suggestions {
  opacity: 1;
  transform: none;
}
.search-slide-down-show .search-slide-down-suggestions {
  transition-delay: 0.2s;
}

/*------------------------------------
  Slick
------------------------------------*/
.slick {
  position: relative;
}

.slick-vertical .slick-slide {
  border-width: 0;
}

.slick-transform-off.slick-transform-off .slick-track {
  transform: none !important;
}

.slick-thumb-progress {
  width: 110%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-center-mode-right {
  margin-right: calc(-1px - (100vw - 100%) / 2 + 16px) !important;
}
.slick-center-mode-right-offset .draggable {
  padding-right: 5rem;
}

/*------------------------------------
  Slick Equal Height
------------------------------------*/
.slick-equal-height .slick-list {
  height: 100%;
}
.slick-equal-height .slick-track {
  display: flex;
  height: 100%;
}
.slick-equal-height .slick-track .slick-slide {
  display: flex;
  height: auto;
}

/*------------------------------------
  Slick Gutters
------------------------------------*/
/* Gutters X */
.slick-gutters-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.slick-gutters-1 .slick-slide {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.slick-gutters-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.slick-gutters-2 .slick-slide {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.slick-gutters-3 {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.slick-gutters-3 .slick-slide {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

/*------------------------------------
  Slick Arrows
------------------------------------*/
.slick-arrow {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #1e2022;
  background-color: #fff;
  width: 2.6875rem;
  height: 2.6875rem;
  font-size: 0.8125rem;
  cursor: pointer;
  transition: 0.3s;
}
.slick-arrow:hover {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}
.slick-arrow::before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.slick-arrow-left {
  left: 0;
}
.slick-arrow-right {
  right: 0;
}

/* Primary */
.slick-arrow-primary {
  color: #fff;
  background-color: rgba(0, 158, 142, 0.8);
}

/* Soft White */
.slick-arrow-soft-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.slick-arrow-soft-white:hover {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

/* Offset */
@media (min-width: 992px) {
  .slick-arrow-offset.slick-arrow-left {
    left: -2.6875rem;
  }
  .slick-arrow-offset.slick-arrow-right {
    right: -2.6875rem;
  }
}
/* Vertical Center Alignment */
.slick-arrow-centered-y {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

/*------------------------------------
  Slick Counter
------------------------------------*/
.slick-counter {
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.slick-counter-current {
  color: #fff;
  font-size: 3.875rem;
  line-height: 1;
}
.slick-counter-divider {
  color: rgba(255, 255, 255, 0.7);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.slick-counter-total {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Slick Dot Lined
------------------------------------*/
.slick-dots {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-dots li {
  cursor: pointer;
  margin: 0 0.3125rem;
}
.slick-dots .dot-line {
  position: relative;
  display: block;
  width: 2.75rem;
  height: 0.1875rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.slick-dots .dot-line-helper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: hsl(174deg, 100%, 31%);
  transition: none;
}

.slick-line-dots-ready .slick-dots li.slick-active .dot-line-helper {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

/*------------------------------------
  Slick Pagination
------------------------------------*/
.slick-pagination {
  padding-left: 0;
  margin-bottom: 0;
}
.slick-pagination:not(.slick-pagination-vertical) {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.slick-pagination li {
  display: flex;
  pointer-events: all;
  margin: 0 0.25rem;
  cursor: pointer;
}
.slick-pagination li span {
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  box-sizing: border-box;
  background-color: hsl(174deg, 100%, 31%);
  border: 0.1875rem solid transparent;
  border-radius: 50%;
  transform: scale(0.6);
  transition: 0.3s;
}
.slick-pagination li.slick-active span {
  background-color: transparent;
  border-color: hsl(174deg, 100%, 31%);
  transform: scale(0.9);
}

/* White Version */
.slick-pagination-white li span {
  background-color: #fff;
}
.slick-pagination-white li.slick-active span {
  border-color: #fff;
}

.slick-pagination-vertical li {
  display: block;
}

/*------------------------------------
  Slick Pagination Interactive
------------------------------------*/
.slick-pagination-interactive-title {
  color: #fff;
}
.slick-pagination-interactive-text {
  color: rgba(255, 255, 255, 0.7);
}
.slick-pagination-interactive .slick-slide {
  cursor: pointer;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}
.slick-pagination-interactive .slick-slide:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.slick-pagination-interactive .slick-slide:hover.slick-center {
  background-color: #fff;
}
.slick-pagination-interactive .slick-center {
  background-color: #fff;
}
.slick-pagination-interactive .slick-center .slick-pagination-interactive-title {
  color: hsl(174deg, 100%, 31%);
}
.slick-pagination-interactive .slick-center .slick-pagination-interactive-text {
  color: hsl(168deg, 0%, 31%);
}

/*------------------------------------
  Slick Pagination Modern
------------------------------------*/
.slick-pagination-modern {
  width: 100%;
  max-width: 12.5rem;
}
.slick-pagination-modern .slick-slide {
  cursor: pointer;
  opacity: 0.7;
  transform: scale(0.7);
  backface-visibility: hidden;
  transition: 0.3s;
}
.slick-pagination-modern .slick-current {
  opacity: 1;
  transform: scale(1);
}

/*------------------------------------
  Slick Pagination Line
------------------------------------*/
.slick-pagination-line .slick-slide {
  opacity: 0.5;
  cursor: pointer;
  font-weight: 400;
  padding-bottom: 0.25rem;
}
.slick-pagination-line .slick-slide.slick-current {
  opacity: 1;
}
.slick-pagination-line-progress {
  position: relative;
  display: block;
  width: 100%;
  height: 0.1875rem;
  background-color: #fff;
  margin-top: 0.5rem;
}
.slick-pagination-line-progress-helper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  transition: none;
}
.slick-pagination-line.slick-dots-ready .slick-current .slick-pagination-line-progress-helper {
  width: 100%;
  background-color: hsl(174deg, 100%, 31%);
  transition-property: width;
  transition-timing-function: linear;
}

@media (max-width: 991.98px) {
  .slick-pagination-line-wrapper {
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }
}
/*------------------------------------
  Spinner
------------------------------------*/
.spinner-border,
.spinner-grow {
  vertical-align: middle;
}

/*------------------------------------
  Stats Progress
-------------------------------------*/
.stats-progress {
  position: relative;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  box-shadow: 0px 0.375rem 1.5rem 0px rgba(117, 117, 117, 0.125);
  margin-left: auto;
  margin-right: auto;
}
.stats-progress-info {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 2rem 2rem;
  transform: translate(0, -50%);
}

/*------------------------------------
  Step
------------------------------------*/
.step {
  position: relative;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}
.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}
.step .step-item {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1.5rem;
}
.step .step-content-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}
.step .step-content {
  flex: 1;
}

/*------------------------------------
  Step Icon
------------------------------------*/
.step .step-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bolder;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.step .step-icon::after {
  position: absolute;
  top: 3.59375rem;
  left: 1.5625rem;
  height: calc(100% - 2.65625rem);
  border-left: 0.125rem solid hsl(168deg, 0%, 89%);
  content: "";
}
.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: hsl(168deg, 0%, 71%);
  border-radius: 50%;
  content: "";
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/
.step-icon-xs.step-icon {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.step-icon-xs.step-icon::after {
  top: 2.21875rem;
  left: 0.8125rem;
  width: 1.0625rem;
  height: calc(100% - 1.28125rem);
}

.step-icon-sm.step-icon {
  font-size: 0.8125rem;
  width: 2.6875rem;
  height: 2.6875rem;
}
.step-icon-sm.step-icon::after {
  top: 3.15625rem;
  left: 1.28125rem;
  width: 1.0625rem;
  height: calc(100% - 2.21875rem);
}

.step-icon-lg.step-icon {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}
.step-icon-lg.step-icon::after {
  top: 4.71875rem;
  left: 2.0625rem;
  width: 1.0625rem;
  height: calc(100% - 3.78125rem);
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    border-left: none;
  }
  .step-sm .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-sm .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-sm .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    border-left: none;
  }
  .step-md .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-md .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-md .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    border-left: none;
  }
  .step-lg .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-lg .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-lg .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}
@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    border-left: none;
  }
  .step-xl .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-xl .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-xl .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}
/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-sm.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-sm.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-sm.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-md.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-md.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-md.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}
/*------------------------------------
  Step States
------------------------------------*/
.step .step-is-valid-icon,
.step .step-is-invalid-icon {
  display: none;
}
.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}
.step .is-valid .step-icon {
  color: #fff;
  background-color: #00c9a7;
}
.step .is-valid .step-is-valid-icon {
  display: inline-flex;
}
.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}
.step .is-invalid .step-icon {
  color: #fff;
  background-color: #de4437;
}
.step .is-invalid .step-is-invalid-icon {
  display: inline-flex;
}
.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}

/*------------------------------------
  Step Colors
------------------------------------*/
/* primary */
.step-icon-primary {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}
.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}

/* secondary */
.step-icon-secondary {
  color: hsl(168deg, 0%, 15%);
  background-color: #56b4a9;
}
.step-icon-secondary.step-icon-pseudo::before {
  background-color: hsl(168deg, 0%, 15%);
}

/* success */
.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}
.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}

/* info */
.step-icon-info {
  color: #fff;
  background-color: #09a5be;
}
.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}

/* warning */
.step-icon-warning {
  color: hsl(168deg, 0%, 15%);
  background-color: #f5ca99;
}
.step-icon-warning.step-icon-pseudo::before {
  background-color: hsl(168deg, 0%, 15%);
}

/* danger */
.step-icon-danger {
  color: #fff;
  background-color: #de4437;
}
.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}

/* light */
.step-icon-light {
  color: hsl(168deg, 0%, 15%);
  background-color: hsl(168deg, 0%, 98%);
}
.step-icon-light.step-icon-pseudo::before {
  background-color: hsl(168deg, 0%, 15%);
}

/* dark */
.step-icon-dark {
  color: #fff;
  background-color: #1e2022;
}
.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}

/* indigo */
.step-icon-indigo {
  color: #fff;
  background-color: #2d1582;
}
.step-icon-indigo.step-icon-pseudo::before {
  background-color: #fff;
}

/* navy */
.step-icon-navy {
  color: #fff;
  background-color: #21325b;
}
.step-icon-navy.step-icon-pseudo::before {
  background-color: #fff;
}

/* primary soft */
.step-icon-soft-primary {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}
.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: hsl(174deg, 100%, 31%);
}

/* secondary soft */
.step-icon-soft-secondary {
  color: #56b4a9;
  background-color: rgba(86, 180, 169, 0.1);
}
.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #56b4a9;
}

/* success soft */
.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}

/* info soft */
.step-icon-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}
.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #09a5be;
}

/* warning soft */
.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}

/* danger soft */
.step-icon-soft-danger {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
}
.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #de4437;
}

/* light soft */
.step-icon-soft-light {
  color: hsl(168deg, 0%, 98%);
  background-color: rgba(250, 250, 250, 0.1);
}
.step-icon-soft-light.step-icon-pseudo::before {
  background-color: hsl(168deg, 0%, 98%);
}

/* dark soft */
.step-icon-soft-dark {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
}
.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #1e2022;
}

/* indigo soft */
.step-icon-soft-indigo {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
}
.step-icon-soft-indigo.step-icon-pseudo::before {
  background-color: #2d1582;
}

/* navy soft */
.step-icon-soft-navy {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}
.step-icon-soft-navy.step-icon-pseudo::before {
  background-color: #21325b;
}

/*------------------------------------
  Step Inline
------------------------------------*/
.step-inline .step-content-wrapper {
  align-items: center;
}
.step-inline .step-item:last-child .step-inline-title::after {
  display: none;
}
.step-inline .step-inline-title {
  display: inline-block;
  color: #1e2022;
  font-weight: 600;
}

@media (min-width: 576px) {
  .step-sm.step-inline.step-dashed .step-inline-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-inline-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    margin-left: 0.9375rem;
    content: "";
  }
  .step-sm.step-inline .step-icon-xs + .step-content .step-inline-title::after {
    top: 0.875rem;
  }
  .step-sm.step-inline .step-icon-sm + .step-content .step-inline-title::after {
    top: 1.34375rem;
  }
  .step-sm.step-inline .step-icon-lg + .step-content .step-inline-title::after {
    top: 2.125rem;
  }
}
@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-inline-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-inline-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    margin-left: 0.9375rem;
    content: "";
  }
  .step-md.step-inline .step-icon-xs + .step-content .step-inline-title::after {
    top: 0.875rem;
  }
  .step-md.step-inline .step-icon-sm + .step-content .step-inline-title::after {
    top: 1.34375rem;
  }
  .step-md.step-inline .step-icon-lg + .step-content .step-inline-title::after {
    top: 2.125rem;
  }
}
@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-inline-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-inline-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    margin-left: 0.9375rem;
    content: "";
  }
  .step-lg.step-inline .step-icon-xs + .step-content .step-inline-title::after {
    top: 0.875rem;
  }
  .step-lg.step-inline .step-icon-sm + .step-content .step-inline-title::after {
    top: 1.34375rem;
  }
  .step-lg.step-inline .step-icon-lg + .step-content .step-inline-title::after {
    top: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-inline-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-inline-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid hsl(168deg, 0%, 89%);
    margin-left: 0.9375rem;
    content: "";
  }
  .step-xl.step-inline .step-icon-xs + .step-content .step-inline-title::after {
    top: 0.875rem;
  }
  .step-xl.step-inline .step-icon-sm + .step-content .step-inline-title::after {
    top: 1.34375rem;
  }
  .step-xl.step-inline .step-icon-lg + .step-content .step-inline-title::after {
    top: 2.125rem;
  }
}
/*------------------------------------
  Step Dots
------------------------------------*/
.step-dots {
  position: relative;
}
@media (min-width: 768px) {
  .step-dots::after {
    position: absolute;
    right: -2.1875rem;
    top: 50%;
    width: 2.4375rem;
    height: 0.75rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 16'%3E %3Ccircle fill='hsl(168deg, 0%, 89%)' cx='27.7' cy='8.1' r='7.7'/%3E %3Ccircle fill='hsl(168deg, 0%, 89%)' cx='5' cy='8.1' r='5'/%3E %3Ccircle fill='hsl(168deg, 0%, 89%)' cx='50' cy='8.1' r='5'/%3E %3C/svg%3E");
    background-repeat: no-repeat;
    content: "";
    margin-top: -0.375rem;
  }
}

/*------------------------------------
  Step Flow
------------------------------------*/
.step-flow {
  position: relative;
  padding-left: 2.5rem;
}
.step-flow::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -0.25rem;
  width: 2rem;
  height: 0.0625rem;
  border-top: 0.0625rem solid hsl(168deg, 0%, 89%);
  margin: auto auto auto 0;
  content: "";
}
.step-flow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -0.3125rem;
  width: 0.0625rem;
  height: 100%;
  border-right: 0.0625rem solid hsl(168deg, 0%, 89%);
  margin: auto auto auto 0;
  content: "";
}
.step-flow:first-child::after {
  height: 50%;
  top: auto;
}
.step-flow:last-child::after {
  height: 50%;
  bottom: auto;
}
.step-flow-inner {
  min-width: 3rem;
}

/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -2.125rem;
  }
}
@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -2.125rem;
  }
}
@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -2.125rem;
  }
}
@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -2.125rem;
  }
}
/*------------------------------------
  Tables
------------------------------------*/
.table .btn {
  white-space: nowrap;
}

.table-heighlighted thead th {
  border-bottom-color: #1e2022;
  border-top: none;
}
.table-heighlighted tfoot td {
  border-top: 0.125rem solid #1e2022;
}

.table-light-bordered {
  border-color: rgba(255, 255, 255, 0.3);
}
.table-light-bordered thead th,
.table-light-bordered th,
.table-light-bordered td {
  border-color: rgba(255, 255, 255, 0.3);
}

/*------------------------------------
  Sticky Blocks
------------------------------------*/
[class*=sticky-block] {
  max-width: 100%;
}

/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .media {
    width: 100%;
  }
  .ie-slick-equal-height .slick-track .slick-slide {
    display: block;
  }
  .ie-main-hero {
    height: 686px;
  }
  .ie-showcase-mockup {
    height: 373px;
  }
  .ie-device-and-mobile {
    height: 638px;
  }
  .ie-curved-y {
    height: 26px;
  }
  .ie-curved-x {
    width: 26px;
    height: 370px;
    margin-right: -4px;
  }
}
/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-y-sm-primary {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 158, 142, 0.075) 100%);
  background-repeat: repeat-x;
}

.gradient-y-gray {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(227, 227, 227, 0.5) 100%);
  background-repeat: repeat-x;
}

.gradient-y-lg-white {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*=gradient-x-overlay-],
[class*=gradient-y-overlay-] {
  position: relative;
  z-index: 1;
}
[class*=gradient-x-overlay-]::before,
[class*=gradient-y-overlay-]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}

.gradient-x-overlay-lg-navy-video::before {
  z-index: 2;
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.95) 0%, rgba(33, 50, 91, 0.95) 100%);
  background-repeat: repeat-x;
}

.gradient-x-overlay-sm-navy::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.25) 0%, rgba(30, 32, 34, 0.25) 100%);
  background-repeat: repeat-x;
}

.gradient-x-overlay-lg-navy::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.9) 0%, rgba(33, 50, 91, 0.9) 100%);
  background-repeat: repeat-x;
}

.gradient-x-overlay-sm-indigo::before {
  background-image: linear-gradient(45deg, transparent, rgba(45, 21, 130, 0.05));
  background-repeat: repeat-x;
}

.gradient-y-overlay-sm-dark::before {
  background-image: linear-gradient(to bottom, rgba(30, 32, 34, 0.3) 0%, rgba(33, 50, 91, 0.2) 100%);
  background-repeat: repeat-x;
}

.gradient-y-overlay-lg-white::before {
  background-image: linear-gradient(to bottom, #fff 0%, rgba(255, 255, 255, 0.9) 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-hero-bottom {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-hero-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-img-hero-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/*------------------------------------
  Background Soft Colors
------------------------------------*/
.bg-soft-primary {
  background-color: rgba(0, 158, 142, 0.1);
}

.bg-soft-secondary {
  background-color: rgba(86, 180, 169, 0.1);
}

.bg-soft-success {
  background-color: rgba(0, 201, 167, 0.1);
}

.bg-soft-info {
  background-color: rgba(9, 165, 190, 0.1);
}

.bg-soft-warning {
  background-color: rgba(245, 202, 153, 0.1);
}

.bg-soft-danger {
  background-color: rgba(222, 68, 55, 0.1);
}

.bg-soft-light {
  background-color: rgba(250, 250, 250, 0.1);
}

.bg-soft-dark {
  background-color: rgba(30, 32, 34, 0.1);
}

.bg-soft-indigo {
  background-color: rgba(45, 21, 130, 0.1);
}

.bg-soft-navy {
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Borders-radius
------------------------------------*/
.rounded-pseudo {
  border-radius: 0.3125rem;
}
.rounded-pseudo::before, .rounded-pseudo::after {
  border-radius: 0.3125rem;
}

/*------------------------------------
  Borders
------------------------------------*/
.border-dashed {
  border: 0.0625rem dashed hsl(168deg, 0%, 89%);
}

.border-3 {
  border-width: 0.1875rem !important;
}

/*------------------------------------
  Position Spaces
------------------------------------*/
.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

.right-auto {
  right: auto;
}

.left-auto {
  left: auto;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-50 {
  top: 50%;
}

.right-50 {
  right: 50%;
}

.bottom-50 {
  bottom: 50%;
}

.left-50 {
  left: 50%;
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute;
  }
}
@media (min-width: 768px) {
  .top-md-0 {
    top: 0;
  }
  .right-md-0 {
    right: 0;
  }
  .bottom-md-0 {
    bottom: 0;
  }
  .left-md-0 {
    left: 0;
  }
  .position-md-absolute {
    position: absolute;
  }
}
@media (min-width: 992px) {
  .top-lg-0 {
    top: 0;
  }
  .right-lg-0 {
    right: 0;
  }
  .bottom-lg-0 {
    bottom: 0;
  }
  .left-lg-0 {
    left: 0;
  }
  .position-lg-absolute {
    position: absolute;
  }
}
/*------------------------------------
  Content Centered
------------------------------------*/
.content-centered-y {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@media (min-width: 768px) {
  .content-centered-y-md {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
@media (min-width: 992px) {
  .content-centered-y-lg {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
/*------------------------------------
  Opacity
------------------------------------*/
.opacity-xs {
  opacity: 0.2;
}

.opacity-sm {
  opacity: 0.4;
}

.opacity {
  opacity: 0.6;
}

.opacity-lg {
  opacity: 0.8;
}

/*------------------------------------
  Box Shadow
------------------------------------*/
.shadow-primary-lg {
  box-shadow: 0px 0px 50px rgba(0, 158, 142, 0.4) !important;
}

.shadow-soft {
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25) !important;
}

/*------------------------------------
  Width
------------------------------------*/
.min-w-3rem {
  min-width: 1rem;
}

.min-w-4rem {
  min-width: 1.5rem;
}

.min-w-8rem {
  min-width: 3.5rem;
}

.min-w-21rem {
  min-width: 10rem;
}

.max-w-3rem {
  max-width: 1rem;
}

.max-w-4rem {
  max-width: 1.5rem;
}

.max-w-5rem {
  max-width: 2rem;
}

.max-w-6rem {
  max-width: 2.5rem;
}

.max-w-7rem {
  max-width: 3rem;
}

.max-w-8rem {
  max-width: 3.5rem;
}

.max-w-9rem {
  max-width: 4rem;
}

.max-w-10rem {
  max-width: 4.5rem;
}

.max-w-11rem {
  max-width: 5rem;
}

.max-w-13rem {
  max-width: 6rem;
}

.max-w-15rem {
  max-width: 7rem;
}

.max-w-19rem {
  max-width: 9rem;
}

.max-w-23rem {
  max-width: 11rem;
}

.max-w-27rem {
  max-width: 13rem;
}

.max-w-33rem {
  max-width: 16rem;
}

.max-w-35rem {
  max-width: 17rem;
}

.max-w-40rem {
  max-width: 19.5rem;
}

.max-w-50rem {
  max-width: 24.5rem;
}

/*------------------------------------
  Height
------------------------------------*/
.h-4rem {
  height: 0.25rem;
}

.h-250rem {
  height: 15.625rem;
}

.h-380rem {
  height: 23.75rem;
}

.min-h-270rem {
  min-height: 16.875rem;
}

.min-h-300rem {
  min-height: 18.75rem;
}

.min-h-380rem {
  min-height: 23.75rem;
}

.min-h-450rem {
  min-height: 28.125rem;
}

.min-h-500rem {
  min-height: 31.25rem;
}

.min-h-620rem {
  min-height: 38.75rem;
}

@media (min-width: 576px) {
  .max-w-sm-15rem {
    max-width: 7rem;
  }
  .max-w-sm-40rem {
    max-width: 19.5rem;
  }
}
@media (min-width: 768px) {
  .vh-md-30 {
    height: 30vh;
  }
  .vh-md-70 {
    height: 70vh;
  }
  .min-vh-md-100 {
    min-height: 100vh;
  }
  .max-w-md-13rem {
    max-width: 6rem;
  }
}
@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh;
  }
  .min-vh-lg-100 {
    min-height: 100vh;
  }
  .min-h-lg-600rem {
    min-height: 37.5rem;
  }
}
/*------------------------------------
  Typography
------------------------------------*/
.text-inherit {
  color: inherit;
}

.text-cap {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

.text-lh-sm {
  line-height: 1.2;
}

.text-lh-lg {
  line-height: 1.8;
}

.font-size-1 {
  font-size: 0.875rem;
}

.font-size-2 {
  font-size: 1.5rem;
}

.font-size-3 {
  font-size: 2rem;
}

.font-size-4 {
  font-size: 2.5rem;
}

.font-size-5 {
  font-size: 3rem;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}
.text-white-70[href]:hover {
  color: #fff;
}

a:hover .text-hover-primary {
  color: #005249 !important;
}

/*------------------------------------
  Transform Rotate
------------------------------------*/
.transform-rotate-1 {
  transform: rotate(-40deg);
  transform-origin: 30% 20%;
}

.transform-rotate-2 {
  transform: rotate(-17deg);
  transform-origin: 0% 0%;
}

.transform-rotate-3 {
  transform: rotate(15deg);
  transform-origin: 90% -20%;
}

.transform-rotate-4 {
  transform-origin: 100% 0;
  transform: translateY(-50%);
}

.transform-rotate-5 {
  transform: rotate(-22deg);
}

.transform-rotate-6 {
  transform: translate3d(20%, -55%, 0);
}

/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}
.transition-3d-hover:hover, .transition-3d-hover:focus {
  transform: translateY(-3px);
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-n1 {
  z-index: -1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-4 {
  z-index: 4;
}

.z-index-999 {
  z-index: 999;
}

/*------------------------------------
  Unfold
------------------------------------*/
.hs-unfold {
  position: relative;
  display: inline-block;
}

.hs-unfold-content {
  display: block !important;
  opacity: 0;
  pointer-events: none;
}

.hs-unfold-content-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.hs-unfold-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

.hs-unfold-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background-color: rgba(33, 50, 91, 0.125);
}

.hs-unfold-content.hs-unfold-reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0;
}

/*------------------------------------
  Video Background
------------------------------------*/
/*------------------------------------
  Video Background
------------------------------------*/
.hs-video-bg-video video {
  object-fit: cover;
}

/*------------------------------------
  Select
------------------------------------*/
.js-selectpicker {
  display: none;
}

.dropdown-select.bootstrap-select li .dropdown-item.opt {
  padding-left: 1.5rem;
}
.dropdown-select.bootstrap-select .bs-searchbox {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.dropdown-select.bootstrap-select .dropdown-header .text {
  font-size: 80%;
  font-weight: 600;
  color: #1e2022;
  text-transform: uppercase;
}
.dropdown-select.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 10px;
}
.dropdown-select.bootstrap-select .dropdown-menu li a span.check-mark {
  font-size: 0.75rem;
}
.dropdown-select .form-control {
  margin-bottom: 0.5rem;
}

/*------------------------------------
  Chartjs
------------------------------------*/
.hs-chartjs-tooltip-wrap {
  position: absolute;
  z-index: 3;
  transition: opacity 0.2s ease-in-out;
}

.hs-chartjs-tooltip {
  background-color: #fff;
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  box-shadow: 0px 0.375rem 1.5rem 0px rgba(117, 117, 117, 0.125);
  border-radius: 0.3125rem;
  padding: 1rem 1rem;
}

.hs-chartjs-tooltip-header {
  font-weight: 600;
}

.hs-chartjs-tooltip-body {
  color: hsl(168deg, 0%, 31%);
}

/*------------------------------------
  Cubeportfolio
------------------------------------*/
@media (max-width: 991.98px) {
  .cbp-filter-scrollbar {
    max-width: 100%;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .cbp-filter-scrollbar::-webkit-scrollbar {
    height: 0.25rem;
  }
  .cbp-filter-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 0.6);
    visibility: hidden;
  }
  .cbp-filter-scrollbar:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

.cbp-filter-item-active.label.label-soft-secondary {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
}

.cbp-filter-item-active.nav-link {
  color: hsl(174deg, 100%, 31%);
}

.cbp-l-loadMore-stop {
  pointer-events: none;
}

.cbp-caption-zoom .cbp-caption-defaultWrap {
  transition: transform 0.2s ease-out;
}
.cbp-caption-zoom .cbp-caption-activeWrap {
  background-color: rgba(33, 50, 91, 0.5);
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-defaultWrap {
  transform: scale(1.1);
}

/*------------------------------------
  Datatable
------------------------------------*/
.datatable .dataTables_length,
.datatable .dataTables_filter,
.datatable .dataTables_info,
.datatable .dataTables_paginate {
  display: none;
}
.datatable-striped .odd {
  background-color: hsl(168deg, 0%, 93%);
}
.datatable-content td[colspan] {
  padding-left: 0;
  padding-right: 0;
}
.datatable .datatable-trigger {
  cursor: pointer;
}
.datatable .datatable-trigger-icon::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  margin-right: 0.75rem;
  transform: rotate(-180deg);
}
.datatable .opened .datatable-trigger-icon::before {
  content: "\f106";
}
.datatable-thead-icon {
  display: block;
  line-height: 0.7;
  cursor: pointer;
  color: hsl(168deg, 0%, 83%);
}
.datatable-thead-icon:hover, .datatable-thead-icon:active {
  color: hsl(174deg, 100%, 31%);
}

/*------------------------------------
  Dropzone
------------------------------------*/
.dz-dropzone {
  cursor: pointer;
}
.dz-dropzone-boxed {
  width: 100%;
  background-color: hsl(168deg, 0%, 93%);
  border: 0.125rem dashed hsl(168deg, 0%, 89%);
  border-radius: 0.3125rem;
  padding: 3rem 3rem;
  display: flex;
  flex-wrap: wrap;
}
.dz-dropzone .dz-message {
  width: 100%;
  text-align: center;
}
.dz-dropzone .dz-details {
  margin-bottom: 1rem;
}
.dz-dropzone .dz-file-preview {
  background-color: #fff;
  border-radius: 0.3125rem;
  padding: 1rem 1rem;
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}
.dz-dropzone .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dz-dropzone .dz-filename {
  margin-bottom: 0.25rem;
}
.dz-dropzone .dz-img {
  max-width: 3.125rem;
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}
.dz-dropzone .dz-img-inner {
  border-radius: 0.3125rem;
}
.dz-dropzone .dz-close-icon,
.dz-dropzone .dz-size {
  color: hsl(168deg, 0%, 31%);
}
.dz-dropzone .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}
.dz-dropzone .dz-size {
  font-size: 80%;
}
.dz-dropzone .dz-file-initials {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  font-weight: 600;
  font-size: 1rem;
  color: hsl(174deg, 100%, 31%);
  text-align: center;
  background-color: rgba(0, 158, 142, 0.1);
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}
.dz-dropzone [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}
.dz-dropzone .dz-progress {
  margin-bottom: 1rem;
}
.dz-dropzone .dz-processing .dz-error-mark,
.dz-dropzone .dz-processing .dz-success-mark {
  display: none;
}
.dz-dropzone .dz-processing.dz-error .dz-error-mark,
.dz-dropzone .dz-processing.dz-success .dz-success-mark {
  display: block;
}
.dz-dropzone .dz-processing .dz-error-mark {
  color: #de4437;
}
.dz-dropzone .dz-processing .dz-success-mark {
  color: #00c9a7;
}

/*------------------------------------
  Dzsparallaxer
------------------------------------*/
.dzsparallaxer {
  color: inherit;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.fancybox-theme .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}

.fancybox-theme .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}

.fancybox-theme .fancybox-content {
  background-color: transparent;
}

.fancybox-theme .fancybox-bg {
  background-color: #1e2022;
}

.fancybox-theme .fancybox-button svg {
  margin-bottom: 0;
}

.fancybox-theme .fancybox-progress {
  background-color: hsl(174deg, 100%, 31%);
}

.blur-30 {
  filter: blur(30px);
}

/*------------------------------------
  Flatpickr
------------------------------------*/
.flatpickr {
  position: relative;
}
.flatpickr .flatpickr-form-control {
  border: none;
  cursor: pointer;
}
.flatpickr .flatpickr-form-control[readonly] {
  background-color: transparent;
}
.flatpickr .flatpickr-calendar {
  top: calc(1.6em + 1.625rem) !important;
  left: 0 !important;
  width: auto;
  padding: 1rem 1rem;
  box-shadow: 0 5px 9px rgba(0, 158, 142, 0.075), 0 5px 9px rgba(79, 79, 79, 0.075);
}
.flatpickr .flatpickr-calendar::before, .flatpickr .flatpickr-calendar::after {
  display: none;
}
.flatpickr .flatpickr-calendar.animate.open {
  animation: fadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr .flatpickr-months {
  align-items: center;
  margin-bottom: 0.75rem;
}
.flatpickr .flatpickr-current-month,
.flatpickr .flatpickr-prev-month,
.flatpickr .flatpickr-next-month {
  padding: 0;
}
.flatpickr .flatpickr-current-month {
  display: flex;
  align-items: center;
  width: auto;
}
.flatpickr .flatpickr-prev-month,
.flatpickr .flatpickr-next-month {
  position: static;
  color: hsl(168deg, 0%, 31%);
  width: 2.6875rem;
  height: 2.6875rem;
  line-height: 2.6875rem;
  background-color: transparent;
  border-radius: 0.3125rem;
}
.flatpickr .flatpickr-prev-month svg,
.flatpickr .flatpickr-next-month svg {
  width: 0.6125rem;
  height: 0.6125rem;
  margin-bottom: 0;
}
.flatpickr .flatpickr-prev-month svg path,
.flatpickr .flatpickr-next-month svg path {
  fill: hsl(168deg, 0%, 31%);
}
.flatpickr .flatpickr-prev-month:hover,
.flatpickr .flatpickr-next-month:hover {
  background-color: rgba(0, 158, 142, 0.1);
}
.flatpickr .flatpickr-prev-month:hover svg path,
.flatpickr .flatpickr-next-month:hover svg path {
  fill: hsl(174deg, 100%, 31%);
}
.flatpickr .flatpickr-weekday {
  color: hsl(168deg, 0%, 71%);
  text-transform: uppercase;
  font-weight: 600;
}
.flatpickr .rangeMode .flatpickr-day {
  margin-top: 0.25rem;
}
.flatpickr .flatpickr-monthDropdown-months {
  font-size: 1rem;
  font-weight: 600;
}
.flatpickr .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
}
.flatpickr .flatpickr-current-month input.cur-year {
  font-size: 1rem;
  font-weight: 600;
}
.flatpickr .flatpickr-day {
  border-color: transparent;
}
.flatpickr .flatpickr-day:focus, .flatpickr .flatpickr-day:hover {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}
.flatpickr .flatpickr-day.selected, .flatpickr .flatpickr-day.startRange, .flatpickr .flatpickr-day.endRange, .flatpickr .flatpickr-day.selected.inRange, .flatpickr .flatpickr-day.startRange.inRange, .flatpickr .flatpickr-day.endRange.inRange, .flatpickr .flatpickr-day.selected:focus, .flatpickr .flatpickr-day.startRange:focus, .flatpickr .flatpickr-day.endRange:focus, .flatpickr .flatpickr-day.selected:hover, .flatpickr .flatpickr-day.startRange:hover, .flatpickr .flatpickr-day.endRange:hover, .flatpickr .flatpickr-day.selected.prevMonthDay, .flatpickr .flatpickr-day.startRange.prevMonthDay, .flatpickr .flatpickr-day.endRange.prevMonthDay, .flatpickr .flatpickr-day.selected.nextMonthDay, .flatpickr .flatpickr-day.startRange.nextMonthDay, .flatpickr .flatpickr-day.endRange.nextMonthDay {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
}
.flatpickr .flatpickr-day.inRange {
  color: #fff;
  background-color: hsl(174deg, 100%, 31%);
  box-shadow: -0.5rem 0 0 hsl(174deg, 100%, 31%), 0.3125rem 0 0 hsl(174deg, 100%, 31%);
}
.flatpickr .flatpickr-day.inRange.prevMonthDay, .flatpickr .flatpickr-day.inRange.prevMonthDay:focus, .flatpickr .flatpickr-day.inRange.prevMonthDay:hover, .flatpickr .flatpickr-day.inRange.nextMonthDay, .flatpickr .flatpickr-day.inRange.nextMonthDay:focus, .flatpickr .flatpickr-day.inRange.nextMonthDay:hover, .flatpickr .flatpickr-day.selected.prevMonthDay, .flatpickr .flatpickr-day.selected.prevMonthDay:focus, .flatpickr .flatpickr-day.selected.prevMonthDay:hover, .flatpickr .flatpickr-day.selected.nextMonthDay, .flatpickr .flatpickr-day.selected.nextMonthDay:focus, .flatpickr .flatpickr-day.selected.nextMonthDay:hover {
  color: #fff;
  border-color: hsl(174deg, 100%, 31%);
  background-color: hsl(174deg, 100%, 31%);
}
.flatpickr .flatpickr-day.today {
  color: #fff;
  border-color: transparent;
  background-color: hsl(174deg, 100%, 31%);
}
.flatpickr .flatpickr-day.prevMonthDay, .flatpickr .flatpickr-day.nextMonthDay {
  color: hsl(168deg, 0%, 89%);
}
.flatpickr .flatpickr-day.prevMonthDay:focus, .flatpickr .flatpickr-day.prevMonthDay:hover, .flatpickr .flatpickr-day.nextMonthDay:focus, .flatpickr .flatpickr-day.nextMonthDay:hover {
  color: hsl(168deg, 0%, 83%);
  background-color: hsl(168deg, 0%, 89%);
  border-color: hsl(168deg, 0%, 89%);
}
.flatpickr .flatpickr-day.disabled {
  color: hsl(168deg, 0%, 31%);
}
.flatpickr .flatpickr-day.disabled:hover {
  color: hsl(168deg, 0%, 31%);
  background-color: hsl(168deg, 0%, 89%);
  border-color: hsl(168deg, 0%, 89%);
}
.flatpickr .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -0.5rem 0 0 hsl(174deg, 100%, 31%), 0.3125rem 0 0 hsl(174deg, 100%, 31%);
}
.flatpickr .numInputWrapper span.arrowUp,
.flatpickr .numInputWrapper span.arrowDown {
  display: none;
}
.flatpickr .numInputWrapper:hover {
  background-color: transparent;
}

/* Skins */
.flatpickr-white .flatpickr-form-control,
.flatpickr-white .flatpickr-form-control::placeholder {
  color: #fff;
}
.flatpickr-white .input-group-text {
  color: rgba(255, 255, 255, 0.7);
}

/* Positions */
.flatpickr-right .flatpickr-calendar {
  right: 0 !important;
  left: auto !important;
}

/*------------------------------------
  Leaflet
------------------------------------*/
.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  border-radius: 0.3125rem;
}

.leaflet-popup {
  margin-bottom: 3rem;
}

/*------------------------------------
  Range Slider
------------------------------------*/
.range-slider {
  height: 1.25rem;
}
.range-slider .irs {
  height: 1.25rem;
}
.range-slider .irs-line {
  top: 0.5rem;
  height: 0.25rem;
}
.range-slider .irs-line-left {
  left: 0;
  height: 0.25rem;
  background-color: hsl(168deg, 0%, 89%);
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.range-slider .irs-line-mid {
  height: 0.25rem;
  background-color: hsl(168deg, 0%, 89%);
}
.range-slider .irs-line-right {
  right: 0;
  height: 0.25rem;
  background-color: hsl(168deg, 0%, 89%);
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.range-slider .irs-bar {
  top: 0.5rem;
  height: 0.25rem;
  background-color: hsl(174deg, 100%, 31%);
}
.range-slider .irs-bar-edge {
  top: 0.5rem;
  left: 0;
  height: 0.25rem;
  width: 0.875rem;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
  background-color: hsl(174deg, 100%, 31%);
}
.range-slider .irs-slider {
  top: -0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(117, 117, 117, 0.25);
}
.range-slider .irs-slider.state_hover {
  transform: scale(1.3);
}
.range-slider .irs-from,
.range-slider .irs-to,
.range-slider .irs-single {
  display: inline-block;
  min-width: 2.5rem;
  background-color: #fff;
  color: #1e2022;
  font-size: 0.875rem;
  text-shadow: none;
  text-align: center;
  border-radius: 0.3125rem;
  box-shadow: 0px 12px 15px rgba(117, 117, 117, 0.1);
  padding: 0.5rem 0.5rem;
}

/* Indicator */
.range-slider-indicator {
  height: 5rem;
}
.range-slider-indicator .irs {
  height: 5rem;
}
.range-slider-indicator.range-slider-grid {
  height: 8rem;
}
.range-slider-indicator.range-slider-grid .irs {
  height: 8rem;
}
.range-slider-indicator .irs-line {
  top: 3.875rem;
}
.range-slider-indicator .irs-bar {
  top: 3.875rem;
}
.range-slider-indicator .irs-bar-edge {
  top: 3.875rem;
}
.range-slider-indicator .irs-slider {
  top: 3rem;
}

/* Grid */
.range-slider-grid {
  height: 4.5rem;
}
.range-slider-grid .irs {
  height: 4.5rem;
}
.range-slider-grid .irs-grid {
  height: 2.5rem;
}
.range-slider-grid .irs-grid-text {
  display: inline-block;
  min-width: 2.5rem;
  line-height: 1;
  font-size: 0.875rem;
  color: hsl(168deg, 0%, 31%);
  border-radius: 6.1875rem;
  padding: 0.25rem;
}
.range-slider-grid .irs-grid-text.current {
  background: rgba(0, 158, 142, 0.1);
  color: hsl(174deg, 100%, 31%);
}
.range-slider-grid .irs-grid-pol {
  height: 0.9rem;
  background-color: hsl(168deg, 0%, 89%);
}
.range-slider-grid .irs-grid-pol.small {
  display: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*------------------------------------
  Summernote Editor
------------------------------------*/
.note-modal-footer {
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;
}
.note-modal-footer .note-btn {
  float: inherit;
}

.summernote-editor .note-editor.note-frame {
  border-color: hsl(168deg, 0%, 89%);
}
.summernote-editor .note-editor.note-frame .note-statusbar {
  background-color: hsl(168deg, 0%, 93%);
  border-top-color: hsl(168deg, 0%, 89%);
}
.summernote-editor .note-toolbar {
  background-color: hsl(168deg, 0%, 93%);
}
.summernote-editor .note-toolbar > .note-btn-group {
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
}
.summernote-editor .note-toolbar .note-dropdown-menu {
  min-width: 12.5rem;
  border-color: transparent;
  box-shadow: 0 5px 9px rgba(0, 158, 142, 0.075), 0 5px 9px rgba(79, 79, 79, 0.075);
  border-radius: 0.3125rem;
  padding: 1rem 0;
}
.summernote-editor .note-toolbar .note-color .note-dropdown-menu {
  min-width: 21.5rem;
  padding: 1rem 1rem;
}
.summernote-editor .note-toolbar .note-dropdown-item {
  padding: 0.375rem 1.5rem;
}
.summernote-editor .note-btn:focus, .summernote-editor .note-btn:hover {
  background-color: transparent;
}
.summernote-editor .note-dropdown-item:hover {
  color: hsl(174deg, 100%, 31%);
  background-color: transparent;
}
.summernote-editor .dropdown-toggle::after {
  display: none;
}

/*------------------------------------
  Tagify
------------------------------------*/
.tagify {
  --tag-bg: unset;
  --tag-hover: unset;
  align-items: center;
  border-color: hsl(168deg, 0%, 89%);
  border-radius: 0.25rem;
}
.tagify:hover {
  border-color: hsl(168deg, 0%, 89%);
}
.tagify.tagify--focus {
  border-color: rgba(0, 158, 142, 0.4);
}
.tagify__tag {
  background-color: hsl(168deg, 0%, 89%);
  border-radius: 0.25rem;
}
.tagify__tag__removeBtn {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
}
.tagify__tag__removeBtn:hover {
  background-color: #de4437;
}
.tagify__tag__removeBtn:hover + div::before {
  box-shadow: none !important;
}
.tagify__tag__removeBtn:hover + div .tagify__tag-text {
  opacity: 1;
}
.tagify__tag > div {
  color: #1e2022;
  line-height: normal;
  padding: 0.3125rem 2rem 0.3125rem 1rem;
  border-radius: 0.25rem;
}
.tagify__tag > div * {
  display: inline-flex;
  vertical-align: baseline;
}
.tagify__input::before {
  line-height: 1.8;
}
.tagify__input .tagify__tag > div {
  padding: 0.3125rem 2rem 0.3125rem 1rem;
  line-height: normal;
}
.tagify__dropdown__menu {
  border: 0.0625rem solid hsl(168deg, 0%, 89%);
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  margin-top: -1px;
}
.tagify__dropdown__item--active {
  color: hsl(174deg, 100%, 31%);
  background-color: rgba(0, 158, 142, 0.1);
}
.tagify.tagify-form-control-list {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/*------------------------------------
  Typed.js
------------------------------------*/
.typed-cursor {
  opacity: 1;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bg-tertiary {
  background-color: #a2d8d2 !important;
}

p strong {
  font-weight: bold;
  color: hsl(174deg, 100%, 25%);
}

.btn-primary {
  background-color: hsl(174deg, 100%, 25%) !important;
}

@font-face {
  font-family: "BrixSans";
  src: url("../../assets/fonts/BrixSansBold.eot");
  src: url("../../assets/fonts/BrixSansBold.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/BrixSansBold.ttf") format("truetype"), url("../../assets/fonts/BrixSansBold.woff") format("woff"), url("../../assets/fonts/BrixSansBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "BrixSans";
  src: url("../../assets/fonts/BrixSansExtraLight.eot");
  src: url("../../assets/fonts/BrixSansExtraLight.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/BrixSansExtraLight.ttf") format("truetype"), url("../../assets/fonts/BrixSansExtraLight.woff") format("woff"), url("../../assets/fonts/BrixSansExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "BrixSans";
  src: url("../../assets/fonts/BrixSansMedium.eot");
  src: url("../../assets/fonts/BrixSansMedium.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/BrixSansMedium.ttf") format("truetype"), url("../../assets/fonts/BrixSansMedium.woff") format("woff"), url("../../assets/fonts/BrixSansMedium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "KarmaticArcade";
  src: url("../../assets/fonts/ka1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.text-primary {
  color: hsl(174deg, 100%, 31%) !important;
}

.text-tertiary {
  color: #a2d8d2 !important;
}

body.leanbit--new.page--internal .hero-like h1, body.page--amazon-connect #hero h1 {
  line-height: 65px;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 45px;
}

/** Trumbowyg v2.25.2 - A lightweight WYSIWYG editor - alex-d.github.io/Trumbowyg - License MIT - Author : Alexandre Demode (Alex-D) / alex-d.fr */
#trumbowyg-icons, #trumbowyg-icons svg {
  height: 0;
  width: 0;
}

#trumbowyg-icons {
  overflow: hidden;
  visibility: hidden;
}

.trumbowyg-box *, .trumbowyg-box ::after, .trumbowyg-box ::before, .trumbowyg-modal *, .trumbowyg-modal ::after, .trumbowyg-modal ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.trumbowyg-box svg, .trumbowyg-modal svg {
  width: 17px;
  height: 100%;
  fill: #222;
}

.trumbowyg-box, .trumbowyg-editor {
  display: block;
  position: relative;
  border: 1px solid #DDD;
  width: 100%;
  min-height: 300px;
}

.trumbowyg-box .trumbowyg-editor {
  margin: 0 auto;
}

.trumbowyg-box.trumbowyg-fullscreen {
  background: #FEFEFE;
  border: none !important;
}

.trumbowyg-editor, .trumbowyg-textarea {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  min-height: 300px;
  width: 100%;
  border-style: none;
  resize: none;
  outline: 0;
  overflow: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.trumbowyg-editor.trumbowyg-autogrow-on-enter, .trumbowyg-textarea.trumbowyg-autogrow-on-enter {
  -webkit-transition: height 0.3s ease-out;
  -o-transition: height 0.3s ease-out;
  transition: height 0.3s ease-out;
}

.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-box-blur .trumbowyg-editor::before {
  color: transparent !important;
  text-shadow: 0 0 7px #333;
}

@media screen and (min-width: 0 \0 ) {
  .trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(200, 200, 200, 0.6) !important;
  }
}
@supports (-ms-accelerator: true) {
  .trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(200, 200, 200, 0.6) !important;
  }
}
.trumbowyg-box-blur .trumbowyg-editor hr, .trumbowyg-box-blur .trumbowyg-editor img {
  opacity: 0.2;
}

.trumbowyg-textarea {
  position: relative;
  display: block;
  overflow: auto;
  border: none;
  font-size: 14px;
  font-family: Inconsolata, Consolas, Courier, "Courier New", sans-serif;
  line-height: 18px;
}

.trumbowyg-box.trumbowyg-editor-visible .trumbowyg-textarea {
  height: 1px !important;
  width: 25%;
  min-height: 0 !important;
  padding: 0 !important;
  background: 0 0;
  opacity: 0 !important;
}

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-textarea {
  display: block;
  margin-bottom: 1px;
}

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-editor {
  display: none;
}

.trumbowyg-box.trumbowyg-disabled .trumbowyg-textarea {
  opacity: 0.8;
  background: 0 0;
}

.trumbowyg-editor[contenteditable=true]:empty:not(:focus)::before {
  content: attr(placeholder);
  color: #999;
  pointer-events: none;
  white-space: break-spaces;
}

.trumbowyg-button-pane {
  width: 100%;
  min-height: 36px;
  background: #ecf0f1;
  border-bottom: 1px solid #d7e0e2;
  margin: 0;
  padding: 0 5px;
  position: relative;
  list-style-type: none;
  line-height: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 11;
}

.trumbowyg-button-pane::after {
  content: " ";
  display: block;
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #d7e0e2;
}

.trumbowyg-button-pane .trumbowyg-button-group {
  display: inline-block;
}

.trumbowyg-button-pane .trumbowyg-button-group .trumbowyg-fullscreen-button svg {
  color: transparent;
}

.trumbowyg-button-pane .trumbowyg-button-group::after {
  content: " ";
  display: inline-block;
  width: 1px;
  background: #d7e0e2;
  margin: 0 5px;
  height: 35px;
  vertical-align: top;
}

.trumbowyg-button-pane .trumbowyg-button-group:last-child::after {
  content: none;
}

.trumbowyg-button-pane button {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  padding: 1px 6px !important;
  margin-bottom: 1px;
  overflow: hidden;
  border: none;
  cursor: pointer;
  background: 0 0;
  vertical-align: middle;
  -webkit-transition: background-color 150ms, opacity 150ms;
  -o-transition: background-color 150ms, opacity 150ms;
  transition: background-color 150ms, opacity 150ms;
}

.trumbowyg-button-pane button.trumbowyg-textual-button {
  width: auto;
  line-height: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.trumbowyg-button-pane button.trumbowyg-disable, .trumbowyg-button-pane.trumbowyg-disable button:not(.trumbowyg-not-disable):not(.trumbowyg-active), .trumbowyg-disabled .trumbowyg-button-pane button:not(.trumbowyg-not-disable):not(.trumbowyg-viewHTML-button) {
  opacity: 0.2;
  cursor: default;
  pointer-events: none;
}

.trumbowyg-button-pane.trumbowyg-disable .trumbowyg-button-group::before, .trumbowyg-disabled .trumbowyg-button-pane .trumbowyg-button-group::before {
  background: #e3e9eb;
}

.trumbowyg-button-pane button.trumbowyg-active, .trumbowyg-button-pane button:not(.trumbowyg-disable):focus, .trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
  background-color: #FFF;
  outline: 0;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown::after {
  display: block;
  content: " ";
  position: absolute;
  top: 25px;
  right: 3px;
  height: 0;
  width: 0;
  border: 3px solid transparent;
  border-top-color: #555;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown.trumbowyg-textual-button {
  padding-left: 10px !important;
  padding-right: 18px !important;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown.trumbowyg-textual-button::after {
  top: 17px;
  right: 7px;
}

.trumbowyg-button-pane .trumbowyg-right {
  float: right;
}

.trumbowyg-dropdown {
  max-width: 300px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  border: 1px solid #ecf0f1;
  padding: 5px 0;
  border-top: none;
  background: #FFF;
  margin-left: -1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
  z-index: 12;
}

.trumbowyg-dropdown button {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-decoration: none;
  background: #FFF;
  padding: 0 20px 0 10px;
  color: #333 !important;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 15px;
  -webkit-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

.trumbowyg-dropdown button:focus, .trumbowyg-dropdown button:hover {
  background: #ecf0f1;
}

.trumbowyg-dropdown button svg {
  float: left;
  margin-right: 14px;
}

.trumbowyg-modal {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 520px;
  width: 100%;
  height: 350px;
  z-index: 12;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.trumbowyg-modal-box {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 500px;
  width: calc(100% - 20px);
  padding-bottom: 45px;
  z-index: 1;
  background-color: #FFF;
  text-align: center;
  font-size: 14px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.trumbowyg-modal-box .trumbowyg-modal-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 20px;
  padding: 15px 0 13px;
  display: block;
  border-bottom: 1px solid #EEE;
  color: #333;
  background: #fbfcfc;
}

.trumbowyg-modal-box .trumbowyg-progress {
  width: 100%;
  height: 3px;
  position: absolute;
  top: 58px;
}

.trumbowyg-modal-box .trumbowyg-progress .trumbowyg-progress-bar {
  background: #2BC06A;
  width: 0;
  height: 100%;
  -webkit-transition: width 150ms linear;
  -o-transition: width 150ms linear;
  transition: width 150ms linear;
}

.trumbowyg-modal-box .trumbowyg-input-row {
  position: relative;
  margin: 15px 12px;
  border: 1px solid #DEDEDE;
  overflow: hidden;
}

.trumbowyg-modal-box .trumbowyg-input-infos {
  text-align: left;
  -webkit-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
  width: 150px;
  border-right: 1px solid #DEDEDE;
  padding: 0 7px;
  background-color: #fbfcfc;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.trumbowyg-modal-box .trumbowyg-input-infos label {
  color: #69878f;
  overflow: hidden;
}

.trumbowyg-modal-box .trumbowyg-input-infos label, .trumbowyg-modal-box .trumbowyg-input-infos label span {
  display: block;
  height: 27px;
  line-height: 27px;
  -webkit-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

.trumbowyg-modal-box .trumbowyg-input-infos .trumbowyg-msg-error {
  color: #e74c3c;
}

.trumbowyg-modal-box .trumbowyg-input-html {
  padding: 1px 1px 1px 152px;
}

.trumbowyg-modal-box .trumbowyg-input-html, .trumbowyg-modal-box .trumbowyg-input-html input, .trumbowyg-modal-box .trumbowyg-input-html select, .trumbowyg-modal-box .trumbowyg-input-html textarea {
  font-size: 14px;
}

.trumbowyg-modal-box .trumbowyg-input-html input, .trumbowyg-modal-box .trumbowyg-input-html select, .trumbowyg-modal-box .trumbowyg-input-html textarea {
  -webkit-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
  height: 27px;
  line-height: 27px;
  border: 0;
  width: 100%;
  padding: 0 7px;
}

.trumbowyg-modal-box .trumbowyg-input-html input:focus, .trumbowyg-modal-box .trumbowyg-input-html input:hover, .trumbowyg-modal-box .trumbowyg-input-html select:focus, .trumbowyg-modal-box .trumbowyg-input-html select:hover, .trumbowyg-modal-box .trumbowyg-input-html textarea:focus, .trumbowyg-modal-box .trumbowyg-input-html textarea:hover {
  outline: #95a5a6 solid 1px;
}

.trumbowyg-modal-box .trumbowyg-input-html input:focus, .trumbowyg-modal-box .trumbowyg-input-html select:focus, .trumbowyg-modal-box .trumbowyg-input-html textarea:focus {
  background: #fbfcfc;
}

.trumbowyg-modal-box .trumbowyg-input-html input[type=checkbox] {
  width: 16px;
  height: 16px;
  padding: 0;
}

.trumbowyg-modal-box .trumbowyg-input-html-with-checkbox {
  text-align: left;
  padding: 3px 1px 1px 3px;
}

.trumbowyg-modal-box .trumbowyg-input-error input, .trumbowyg-modal-box .trumbowyg-input-error select, .trumbowyg-modal-box .trumbowyg-input-error textarea {
  outline: #e74c3c solid 1px;
}

.trumbowyg-modal-box .trumbowyg-input-error .trumbowyg-input-infos label span:first-child {
  margin-top: -27px;
}

.trumbowyg-modal-box .error {
  margin-top: 25px;
  display: block;
  color: red;
}

.trumbowyg-modal-box .trumbowyg-modal-button {
  position: absolute;
  bottom: 10px;
  right: 0;
  text-decoration: none;
  color: #FFF;
  display: block;
  width: 100px;
  height: 35px;
  line-height: 33px;
  margin: 0 10px;
  background-color: #333;
  border: none;
  cursor: pointer;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif;
  font-size: 16px;
  -webkit-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit {
  right: 110px;
  background: #2bc06a;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:focus, .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:hover {
  background: #40d47e;
  outline: 0;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:active {
  background: #25a25a;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset {
  color: #555;
  background: #e6e6e6;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:focus, .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:hover {
  background: #fbfbfb;
  outline: 0;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:active {
  background: #d5d5d5;
}

.trumbowyg-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  display: none;
  top: 0;
  z-index: 10;
}

body.trumbowyg-body-fullscreen {
  overflow: hidden;
}

.trumbowyg-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 99999;
}

.trumbowyg-fullscreen .trumbowyg-editor, .trumbowyg-fullscreen.trumbowyg-box {
  border: none;
}

.trumbowyg-fullscreen .trumbowyg-editor, .trumbowyg-fullscreen .trumbowyg-textarea {
  height: calc(100% - 37px) !important;
  overflow: auto;
}

.trumbowyg-fullscreen .trumbowyg-overlay {
  height: 100% !important;
}

.trumbowyg-fullscreen .trumbowyg-button-group .trumbowyg-fullscreen-button svg {
  color: #222;
  fill: transparent;
}

.trumbowyg-editor embed, .trumbowyg-editor img, .trumbowyg-editor object, .trumbowyg-editor video {
  max-width: 100%;
}

.trumbowyg-editor img, .trumbowyg-editor video {
  height: auto;
}

.trumbowyg-editor img {
  cursor: move;
}

.trumbowyg-editor canvas:focus {
  outline: 0;
}

.trumbowyg-editor.trumbowyg-reset-css {
  background: #FEFEFE !important;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.45em !important;
  color: #333;
}

.trumbowyg-editor.trumbowyg-reset-css a {
  color: #15c !important;
  text-decoration: underline !important;
}

.trumbowyg-editor.trumbowyg-reset-css blockquote, .trumbowyg-editor.trumbowyg-reset-css div, .trumbowyg-editor.trumbowyg-reset-css ol, .trumbowyg-editor.trumbowyg-reset-css p, .trumbowyg-editor.trumbowyg-reset-css ul {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: 0 0 !important;
  margin: 0 0 15px !important;
  line-height: 1.4em !important;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
  font-size: 14px !important;
  border: none;
}

.trumbowyg-editor.trumbowyg-reset-css hr, .trumbowyg-editor.trumbowyg-reset-css iframe, .trumbowyg-editor.trumbowyg-reset-css object {
  margin-bottom: 15px !important;
}

.trumbowyg-editor.trumbowyg-reset-css blockquote {
  margin-left: 32px !important;
  font-style: italic !important;
  color: #555;
}

.trumbowyg-editor.trumbowyg-reset-css ul {
  list-style: disc;
}

.trumbowyg-editor.trumbowyg-reset-css ol {
  list-style: decimal;
}

.trumbowyg-editor.trumbowyg-reset-css ol, .trumbowyg-editor.trumbowyg-reset-css ul {
  padding-left: 20px !important;
}

.trumbowyg-editor.trumbowyg-reset-css ol ol, .trumbowyg-editor.trumbowyg-reset-css ol ul, .trumbowyg-editor.trumbowyg-reset-css ul ol, .trumbowyg-editor.trumbowyg-reset-css ul ul {
  border: none;
  margin: 2px !important;
  padding: 0 0 0 24px !important;
}

.trumbowyg-editor.trumbowyg-reset-css hr {
  display: block;
  height: 1px;
  border: none;
  border-top: 1px solid #CCC;
}

.trumbowyg-editor.trumbowyg-reset-css h1, .trumbowyg-editor.trumbowyg-reset-css h2, .trumbowyg-editor.trumbowyg-reset-css h3, .trumbowyg-editor.trumbowyg-reset-css h4 {
  color: #111;
  background: 0 0;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 700;
}

.trumbowyg-editor.trumbowyg-reset-css h1 {
  font-size: 32px !important;
  line-height: 38px !important;
  margin-bottom: 20px !important;
}

.trumbowyg-editor.trumbowyg-reset-css h2 {
  font-size: 26px !important;
  line-height: 34px !important;
  margin-bottom: 15px !important;
}

.trumbowyg-editor.trumbowyg-reset-css h3 {
  font-size: 22px !important;
  line-height: 28px !important;
  margin-bottom: 7px !important;
}

.trumbowyg-editor.trumbowyg-reset-css h4 {
  font-size: 16px !important;
  line-height: 22px !important;
  margin-bottom: 7px !important;
}

.trumbowyg-dark .trumbowyg-textarea {
  background: #111;
  color: #ddd;
}

.trumbowyg-dark .trumbowyg-box {
  border: 1px solid #343434;
}

.trumbowyg-dark .trumbowyg-box.trumbowyg-fullscreen {
  background: #111;
}

.trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor::before {
  text-shadow: 0 0 7px #ccc;
}

@media screen and (min-width: 0 \0 ) {
  .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(20, 20, 20, 0.6) !important;
  }
}
@supports (-ms-accelerator: true) {
  .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(20, 20, 20, 0.6) !important;
  }
}
.trumbowyg-dark .trumbowyg-box svg {
  fill: #ecf0f1;
  color: #ecf0f1;
}

.trumbowyg-dark .trumbowyg-button-pane {
  background-color: #222;
  border-bottom-color: #343434;
}

.trumbowyg-dark .trumbowyg-button-pane::after {
  background: #343434;
}

.trumbowyg-dark .trumbowyg-button-pane .trumbowyg-button-group:not(:empty)::after {
  background-color: #343434;
}

.trumbowyg-dark .trumbowyg-button-pane .trumbowyg-button-group:not(:empty) .trumbowyg-fullscreen-button svg {
  color: transparent;
}

.trumbowyg-dark .trumbowyg-button-pane.trumbowyg-disable .trumbowyg-button-group::after {
  background-color: #2a2a2a;
}

.trumbowyg-dark .trumbowyg-button-pane button.trumbowyg-active, .trumbowyg-dark .trumbowyg-button-pane button:not(.trumbowyg-disable):focus, .trumbowyg-dark .trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
  background-color: #333;
}

.trumbowyg-dark .trumbowyg-button-pane .trumbowyg-open-dropdown::after {
  border-top-color: #fff;
}

.trumbowyg-dark .trumbowyg-fullscreen .trumbowyg-button-pane .trumbowyg-button-group:not(:empty) .trumbowyg-fullscreen-button svg {
  color: #ecf0f1;
  fill: transparent;
}

.trumbowyg-dark .trumbowyg-dropdown {
  border-color: #222;
  background: #333;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
}

.trumbowyg-dark .trumbowyg-dropdown button {
  background: #333;
  color: #fff !important;
}

.trumbowyg-dark .trumbowyg-dropdown button:focus, .trumbowyg-dark .trumbowyg-dropdown button:hover {
  background: #222;
}

.trumbowyg-dark .trumbowyg-modal-box {
  background-color: #222;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-title {
  border-bottom: 1px solid #555;
  color: #fff;
  background: #3c3c3c;
}

.trumbowyg-dark .trumbowyg-modal-box label {
  display: block;
  position: relative;
  margin: 15px 12px;
  height: 27px;
  line-height: 27px;
  overflow: hidden;
}

.trumbowyg-dark .trumbowyg-modal-box label .trumbowyg-input-infos span {
  color: #eee;
  background-color: #2f2f2f;
  border-color: #222;
}

.trumbowyg-dark .trumbowyg-modal-box label .trumbowyg-input-infos span.trumbowyg-msg-error {
  color: #e74c3c;
}

.trumbowyg-dark .trumbowyg-modal-box label.trumbowyg-input-error input, .trumbowyg-dark .trumbowyg-modal-box label.trumbowyg-input-error textarea {
  border-color: #e74c3c;
}

.trumbowyg-dark .trumbowyg-modal-box label input {
  border-color: #222;
  color: #eee;
  background: #333;
}

.trumbowyg-dark .trumbowyg-modal-box label input:focus, .trumbowyg-dark .trumbowyg-modal-box label input:hover {
  border-color: #626262;
}

.trumbowyg-dark .trumbowyg-modal-box label input:focus {
  background-color: #2f2f2f;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit {
  background: #1b7943;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:focus, .trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:hover {
  background: #25a25a;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:active {
  background: #176437;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset {
  background: #333;
  color: #ccc;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:focus, .trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:hover {
  background: #444;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:active {
  background: #111;
}

.trumbowyg-dark .trumbowyg-overlay {
  background-color: rgba(15, 15, 15, 0.6);
}

/*# sourceMappingURL=trumbowyg.min.css.map */
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#header .navbar-nav-item a i {
  margin-right: 5px;
}
#header .navbar-nav-item.nav-demo-button .nav-link {
  color: hsl(174deg, 100%, 31%);
}
#header .navbar--always-visible .nav-demo-button .btn {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  #header .navbar-nav-item.nav-demo-button .btn {
    margin-top: 5px;
  }
}
footer {
  background-color: hsl(174deg, 100%, 25%);
  color: #fff;
}
footer .links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  font-size: 14px;
}
footer .links a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  font-size: 14px;
  color: #ffffff;
}
footer .links a:hover, footer .links a:focus, footer .links a:active {
  color: #ffffff;
  text-decoration: underline;
}
footer .links a i {
  margin-right: 5px;
}

@media (min-width: 992px) {
  footer .links {
    display: flex;
    justify-content: center;
  }
  footer .links a {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  footer .links a {
    margin: 0 15px;
  }
}
.fonts-loaded--brix-sans body.smart-working {
  font-family: "BrixSans", sans-serif;
}

body.smart-working {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body.smart-working h1, body.smart-working h2, body.smart-working h3, body.smart-working h4, body.smart-working h5, body.smart-working h6 {
  font-weight: 200;
}
body.smart-working .accordion .card {
  border-radius: 0;
}
body.smart-working .accordion .card-btn {
  color: hsl(174deg, 100%, 31%);
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
}
body.smart-working .accordion .card-header {
  border-bottom: none;
}
body.smart-working .accordion .card-btn-toggle {
  font-size: 30px;
  line-height: 20px;
}
body.smart-working .accordion .card {
  box-shadow: none;
}
body.smart-working .accordion .card-body {
  font-size: 15px;
}
body.smart-working .block {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.smart-working .block-1 {
  padding-top: 170px;
  background-size: cover;
}
body.smart-working .block-1 h1, body.smart-working .block-1 h2, body.smart-working .block-1 h3, body.smart-working .block-1 p {
  color: #ffffff;
}
body.smart-working .block-1 h1 {
  font-size: 50px;
}
body.smart-working .block-1 h3 {
  text-transform: uppercase;
  font-size: 20px;
}
body.smart-working .block-1 h2 {
  font-size: 28px;
}
body.smart-working .block-1 p {
  font-size: 13px;
}
body.smart-working .block-1 #aws-badge img {
  height: 110px;
}
body.smart-working .block-2 p {
  font-size: 20px;
  font-weight: 200;
}
body.smart-working .block-3 {
  background-color: hsl(174deg, 100%, 31%);
}
body.smart-working .block-3 h2 {
  color: #ffffff;
  font-size: 36px;
}
body.smart-working .block-3 .steps {
  text-align: center;
}
body.smart-working .block-3 .steps img {
  margin: 0 auto;
  padding: 30px 0;
  max-height: 450px;
}
body.smart-working .block-3 .steps .step {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-color: #3bafa1;
  color: #ffffff;
  font-size: 14px;
  padding-top: 40px;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  display: inline-block;
}
body.smart-working .block-3 .steps .step.step-big {
  background-color: hsl(174deg, 100%, 31%);
  border: 5px solid #ffffff;
  height: 150px;
  width: 150px;
  border-radius: 75px;
  font-size: 18px;
  padding-top: 32px;
}
body.smart-working .block-3 .steps .step-wrapper {
  position: relative;
  height: 100%;
}
body.smart-working .block-3 .steps .step-wrapper .step-bg {
  position: absolute;
  height: 100%;
  width: 100%;
}
body.smart-working .block-3 .steps .step-wrapper .step-bg::before {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border-left: 1px solid #ffffff;
  content: "";
}
body.smart-working .block-3 .steps .step-wrapper.first .step-bg {
  height: 50%;
  bottom: 0;
}
body.smart-working .block-3 .steps .step-wrapper.last .step-bg {
  height: 50%;
  top: 0;
}
body.smart-working .block-4 {
  background-size: cover;
}
body.smart-working .block-4 nav #nav-tab.nav.nav-tabs a.nav-item.nav-link {
  border-radius: 0;
  color: #ffffff;
  background-color: hsl(174deg, 100%, 31%);
  border-color: hsl(174deg, 100%, 31%);
  text-transform: uppercase;
  min-width: 150px;
  text-align: center;
}
body.smart-working .block-4 nav #nav-tab.nav.nav-tabs a.nav-item.nav-link:hover {
  background-color: #56b4a9;
  border-color: #56b4a9;
}
body.smart-working .block-4 nav #nav-tab.nav.nav-tabs a.nav-item.nav-link.active {
  color: hsl(174deg, 100%, 31%);
  background-color: #e8f2f1;
  cursor: default;
  border-color: #e8f2f1;
}
body.smart-working .block-4 nav #nav-tab.nav.nav-tabs a.nav-item.nav-link.active:hover {
  background-color: #e8f2f1;
}
body.smart-working .block-4 .tab-content {
  background-color: #e8f2f1;
}
body.smart-working .block-4 .tab-content .left-block {
  background-color: #ffffff;
  padding: 30px;
  height: 100%;
}
body.smart-working .block-4 .tab-content .left-block h2 {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 20px;
}
body.smart-working .block-4 .tab-content .left-block h4 {
  text-transform: uppercase;
  font-size: 16px;
  color: hsl(174deg, 100%, 31%);
  font-weight: bold;
  margin-bottom: 15px;
}
body.smart-working .block-4 .tab-content .left-block p {
  font-weight: 200;
  margin-bottom: 5px;
}
body.smart-working .block-4 .tab-content .left-block ul {
  font-weight: 200;
  margin-bottom: 0;
  padding-left: 20px;
}
body.smart-working .block-4 .tab-content .right-block {
  background-color: #e8f2f1;
  padding: 50px;
  height: 100%;
}
body.smart-working .block-4 .tab-content .right-block img {
  margin: 0 auto;
}
body.smart-working .block-5 p {
  color: hsl(174deg, 100%, 31%);
  font-size: 26px;
  font-weight: 200;
}
body.smart-working .block-5 .accordion .card-body {
  padding: 15px 30px 0;
}
body.smart-working .block-6 {
  background-color: #e8f2f1;
}
body.smart-working .block-6 h2 {
  color: hsl(174deg, 100%, 31%);
  font-size: 36px;
}
body.smart-working .block-6 .small-check {
  width: 40px;
}
body.smart-working .block-7 h3 {
  color: hsl(174deg, 100%, 31%);
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
}
body.smart-working .block-7 .left-icon {
  height: auto;
  width: 100%;
}
@media (min-width: 576px) {
  body.smart-working .block-7 .left-icon {
    height: 70px;
    width: auto;
  }
}
body.smart-working .block-7 .ipad-image {
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
}
body.smart-working .block-7 .accordion .card-btn {
  padding: 0;
  font-size: 26px;
  padding: 15px 0;
  font-weight: 200;
}
body.smart-working .block-7 .accordion .card-header.card-collapse:hover {
  background-color: #ffffff;
}
body.smart-working .block-7 .accordion .card {
  border-bottom: 1px solid hsl(174deg, 100%, 31%);
}
body.smart-working .block-7 .accordion .card-body {
  padding: 5px 0 15px;
}
body.smart-working .block-personas {
  background-color: #f3f8f8;
}
body.smart-working .block-personas h2 {
  color: hsl(174deg, 100%, 31%);
}
body.smart-working .block-personas .persona img {
  width: 100%;
  display: block;
}
body.smart-working .block-personas .persona .body {
  padding: 25px;
}
body.smart-working .block-personas .persona .body h3 {
  color: #ffffff;
  font-size: 28px;
  margin-bottom: 0;
}
body.smart-working .block-personas .persona .body h4 {
  color: #ffffff;
  font-size: 15px;
  line-height: 24px;
  height: 24px;
}
body.smart-working .block-personas .persona .body .btn {
  border-radius: 0;
}
body.smart-working .block-personas .persona.romolo {
  background-color: #3b445a;
}
body.smart-working .block-personas .persona.laura {
  background-color: #d45438;
}
body.smart-working .block-persona {
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: none;
}
body.smart-working .block-persona .close-persona {
  color: #bbbbbb;
  font-size: 24px;
  cursor: pointer;
}
body.smart-working .block-persona .close-persona:hover {
  color: #ffffff;
}
body.smart-working .block-persona h4 {
  color: #ffffff;
  font-size: 30px;
}
body.smart-working .block-persona p {
  color: #ffffff;
  font-weight: 200;
}
body.smart-working .sendgrid-iframe iframe {
  width: 100%;
  height: 600px;
  border: none;
}

body.page--event h1,
body.page--event h2,
body.page--event h3,
body.page--event h4,
body.page--event h5,
body.page--event h6,
body.page--event .h1,
body.page--event .h2,
body.page--event .h3,
body.page--event .h4,
body.page--event .h5,
body.page--event .h6 {
  color: hsl(174deg, 100%, 31%);
}
body.page--event .image.image--rounded {
  border-radius: 6px;
  overflow: hidden;
}
body.page--event .image.image--shadow {
  box-shadow: 0px 6px 14px -5px hsla(169deg, 100%, 11%, 0.22);
}

body.page--event .row.row--edge-to-edge {
  position: relative;
  margin-left: -50vw;
  left: 50%;
  width: 100%;
  height: 100%;
}
body.page--event .bg--primary {
  background: hsl(174deg, 100%, 31%);
  color: #fff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
body.page--event .bg--primary h1,
body.page--event .bg--primary h2,
body.page--event .bg--primary h3,
body.page--event .bg--primary h4,
body.page--event .bg--primary h5,
body.page--event .bg--primary h6,
body.page--event .bg--primary .h1,
body.page--event .bg--primary .h2,
body.page--event .bg--primary .h3,
body.page--event .bg--primary .h4,
body.page--event .bg--primary .h5,
body.page--event .bg--primary .h6,
body.page--event .bg--primary strong,
body.page--event .bg--primary p {
  color: #fff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
body.page--event .bg--primary .image.image--shadow {
  box-shadow: 0px 12px 17px -10px hsla(170deg, 89%, 11%, 0.41);
}

body.page--event #company-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.page--event .event__date-and-time,
body.page--event .event__date-and-time span {
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 767.98px) {
  body.page--event .h1--smaller {
    font-size: 27px;
  }
  body.page--event p.lead {
    font-size: 16px;
  }
  body.page--event #company-logo img {
    width: 70%;
  }
  body.page--event #hero-image {
    position: relative;
    z-index: 1;
  }
  body.page--event .badge--evento-online {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  body.page--event .h1--smaller {
    font-size: 32px;
  }
  body.page--event #company-logo {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  body.page--event #company-logo img {
    width: 50%;
  }
  body.page--event #hero-image {
    position: relative;
    z-index: 1;
  }
}

body.page--event #hero-container {
  background: linear-gradient(-45deg, transparent 5%, #008073 0%, #008073 12%, transparent 0, transparent 70%, #008073 70%, #008073 80%, transparent 0, transparent 100%);
  box-shadow: inset 0 -18px 50px -50px;
  padding-bottom: 40px;
}
body.page--event #hero__callout {
  background: white;
  padding: 30px;
  /* border: 2px solid $primary; */
  box-shadow: 0 10px 36px -15px rgba(0, 0, 0, 0.32);
  border-radius: 20px;
}

body.page--event .btn--cta {
  font-size: 20px;
}
body.page--event .btn--special {
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
}
body.page--event .btn--special:hover {
  box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
}

body.page--event .w--fit-content {
  width: fit-content;
}
body.page--event .w--max-content {
  width: max-content;
}

body.page--event {
  font-size: 18px;
}
@media (max-width: 991.98px) {
  body.page--event {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  body.page--event {
    font-size: 15px;
  }
}

body.page--event .js-slick-carousel .js-slide .card svg #accent-color * {
  fill: #ffc646;
}
body.page--event .js-slick-carousel .js-slide .card svg #base-color * {
  fill: hsl(168deg, 0%, 31%);
}

@media (max-width: 991.98px) {
  body.page--event .js-slick-carousel img {
    height: 100px;
    object-fit: scale-down;
  }
  body.page--event .js-slick-carousel .js-slide .card {
    height: 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 8px 50px -5px rgba(0, 0, 0, 0.18);
  }
  body.page--event .js-slick-carousel .js-slide figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body.page--event .js-slick-carousel__background {
    opacity: 0;
  }
}

body.page--event .badge--evento-online {
  font-size: 14px;
}

@media (min-width: 992px) {
  body.page--event .js-slick-carousel {
    display: flex;
    justify-content: space-between;
  }
  body.page--event .js-slick-carousel img {
    height: 100px;
    object-fit: scale-down;
  }
  body.page--event .js-slick-carousel .js-slide {
    width: 30%;
    font-size: 16px;
    height: auto;
  }
  body.page--event .js-slick-carousel .js-slide .card {
    height: 100%;
    box-shadow: 0 8px 145px -5px rgba(0, 0, 0, 0.18);
  }
  body.page--event .js-slick-carousel .js-slide figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body.page--event .js-slick-carousel__background {
    box-shadow: 0px 50px 270px -80px hsla(160deg, 65%, 28%, 0.5);
  }
}

body.page--san-marco-2-dicembre {
  overflow-x: hidden;
}
body.page--san-marco-2-dicembre h1, body.page--san-marco-2-dicembre h2, body.page--san-marco-2-dicembre h3, body.page--san-marco-2-dicembre h4, body.page--san-marco-2-dicembre h5, body.page--san-marco-2-dicembre h6, body.page--san-marco-2-dicembre .h1, body.page--san-marco-2-dicembre .h2, body.page--san-marco-2-dicembre .h3, body.page--san-marco-2-dicembre .h4, body.page--san-marco-2-dicembre .h5, body.page--san-marco-2-dicembre .h6 {
  color: #485653;
}
body.page--san-marco-2-dicembre .image.image--rounded {
  border-radius: 6px;
  overflow: hidden;
}
body.page--san-marco-2-dicembre .image.image--shadow {
  box-shadow: 0px 6px 14px -5px hsla(169deg, 100%, 11%, 0.22);
}
body.page--san-marco-2-dicembre .row.row--edge-to-edge {
  position: relative;
  margin-left: -50vw;
  left: 50%;
  width: 100vw;
  height: 100%;
}
body.page--san-marco-2-dicembre .row--green-bg {
  background: #E4F4F0;
}
body.page--san-marco-2-dicembre .row--green-bg .image.image--shadow {
  box-shadow: 0px 12px 17px -10px hsla(170deg, 89%, 11%, 0.41);
}
body.page--san-marco-2-dicembre #san-marco-group-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.page--san-marco-2-dicembre #heading-subscribe-row {
  display: flex;
  align-items: center;
  padding: 40px 0;
}

@media (max-width: 767.98px) {
  body.page--san-marco-2-dicembre .h1--smaller {
    font-size: 27px;
  }
  body.page--san-marco-2-dicembre p.lead {
    font-size: 16px;
  }
  body.page--san-marco-2-dicembre #san-marco-group-image img {
    width: 70%;
  }
  body.page--san-marco-2-dicembre #people-with-computers-image {
    position: relative;
    z-index: 1;
    margin-bottom: -70px;
  }
  body.page--san-marco-2-dicembre #heading-subscribe-row .btn {
    margin-top: 25px;
  }
  body.page--san-marco-2-dicembre #first-post {
    display: grid;
    grid-template-areas: "icon" "first-question" "second-question";
    width: 80%;
    margin: 50px auto;
  }
  body.page--san-marco-2-dicembre #first-post #icon {
    grid-area: icon;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 92px;
    height: 150px;
    border-top: 6px solid #E4F4F0;
    color: #E4F4F0;
  }
  body.page--san-marco-2-dicembre #first-post .question {
    padding: 20px 35px;
  }
  body.page--san-marco-2-dicembre #first-post #first-question {
    grid-area: first-question;
    background: #E4F4F0;
  }
  body.page--san-marco-2-dicembre #first-post #second-question {
    grid-area: second-question;
    background: #c0e5dc;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel img {
    height: 100px;
    object-fit: scale-down;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel .js-slide .card {
    height: 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel .js-slide figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel__background {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  body.page--san-marco-2-dicembre .h1--smaller {
    font-size: 32px;
  }
  body.page--san-marco-2-dicembre #san-marco-group-image {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  body.page--san-marco-2-dicembre #san-marco-group-image img {
    width: 50%;
  }
  body.page--san-marco-2-dicembre #people-with-computers-image {
    position: relative;
    z-index: 1;
    margin-bottom: -70px;
  }
  body.page--san-marco-2-dicembre #first-post {
    display: grid;
    grid-template-areas: "icon first-question" "icon second-question";
    width: 70%;
    margin: 50px auto;
  }
  body.page--san-marco-2-dicembre #first-post #icon {
    grid-area: icon;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 92px;
    width: 150px;
    border-left: 6px solid #E4F4F0;
    color: #E4F4F0;
  }
  body.page--san-marco-2-dicembre #first-post .question {
    padding: 20px 35px;
  }
  body.page--san-marco-2-dicembre #first-post #first-question {
    grid-area: first-question;
    background: #E4F4F0;
  }
  body.page--san-marco-2-dicembre #first-post #second-question {
    grid-area: second-question;
    background: #c0e5dc;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel {
    display: flex;
    justify-content: space-between;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel img {
    height: 100px;
    object-fit: scale-down;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel .js-slide {
    width: 30%;
    font-size: 14px;
    height: 100%;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel .js-slide .card {
    height: 100%;
  }
  body.page--san-marco-2-dicembre .js-slick-carousel .js-slide figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page--event.page--offcar-10-giugno-2021 #first-post {
  width: 100%;
  display: flex;
}
.page--event.page--offcar-10-giugno-2021 #first-post #icon {
  border: none;
}

.page--event.page--offcar-10-giugno-2021 footer {
  padding: 15px;
  background: linear-gradient(45deg, #009e8e, hsl(188deg, 100%, 31%));
}
.page--event.page--offcar-10-giugno-2021 footer .links {
  display: flex;
  justify-content: center;
}
.page--event.page--offcar-10-giugno-2021 footer .links i {
  font-size: 28px;
  margin: 0;
}
.page--event.page--offcar-10-giugno-2021 footer .links a:hover {
  text-decoration: none;
}

.fonts-loaded--brix-sans body.nosco-home {
  font-family: "BrixSans", sans-serif;
}

body.nosco-home {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body.nosco-home h1, body.nosco-home h2, body.nosco-home h3, body.nosco-home h4, body.nosco-home h5, body.nosco-home h6, body.nosco-home p {
  font-weight: 100;
}
body.nosco-home .center {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.nosco-home .accordion .card {
  background-color: transparent !important;
  border-radius: 0;
}
body.nosco-home .accordion .card-btn {
  color: hsl(174deg, 100%, 31%);
  font-size: 20px;
  line-height: 22px;
}
body.nosco-home .accordion .card-header {
  background-color: transparent !important;
  border-bottom: none;
}
body.nosco-home .accordion .card-btn-toggle {
  font-size: 30px;
  line-height: 20px;
}
body.nosco-home .accordion .card {
  box-shadow: none;
}
body.nosco-home .accordion .card-body {
  font-size: 15px;
}
body.nosco-home .hidden {
  display: none;
}
body.nosco-home .block {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.nosco-home .block-1 {
  background-color: hsl(174deg, 100%, 31%);
  padding-bottom: 0;
}
body.nosco-home .block-1 h1, body.nosco-home .block-1 h2, body.nosco-home .block-1 h3, body.nosco-home .block-1 h4, body.nosco-home .block-1 h5, body.nosco-home .block-1 h6, body.nosco-home .block-1 p {
  color: white;
}
body.nosco-home .block-1 h1 {
  font-size: 8vw;
}
body.nosco-home .block-1 h2 {
  font-size: 4vw;
}
body.nosco-home .block-1 .persona {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  overflow: hidden;
}
@media (min-width: 1000px) {
  body.nosco-home .block-1 h1 {
    font-size: 100px;
  }
  body.nosco-home .block-1 h2 {
    font-size: 60px;
  }
}
@media (max-width: 1000px) {
  body.nosco-home .block-1 .persona {
    height: 240px;
  }
}
@media (max-width: 560px) {
  body.nosco-home .block-1 .persona {
    height: 200px;
  }
}
body.nosco-home .block-1 .personas * {
  margin: 0;
  padding: 0;
}
body.nosco-home .block-1 .personas img {
  width: 100%;
  display: block;
}
body.nosco-home .block-2 {
  background-size: cover;
}
body.nosco-home .block-2 h1, body.nosco-home .block-2 h2, body.nosco-home .block-2 h3, body.nosco-home .block-2 h4, body.nosco-home .block-2 h5, body.nosco-home .block-2 h6, body.nosco-home .block-2 p, body.nosco-home .block-2 i {
  color: white;
}
body.nosco-home .block-2 h2, body.nosco-home .block-2 i {
  font-size: 45px;
}
body.nosco-home .block-2 a {
  cursor: pointer;
}
body.nosco-home .block-2 p {
  font-size: 25px;
}
body.nosco-home .block-2 strong {
  color: hsl(174deg, 100%, 38%);
}
body.nosco-home .block-2 .cloud-io-img {
  max-height: 300px;
  margin: auto;
}
body.nosco-home .block-2 .slick-slide.slick-active > div {
  width: 100%;
}
body.nosco-home .block-3 h1, body.nosco-home .block-3 h2, body.nosco-home .block-3 h3, body.nosco-home .block-3 h4, body.nosco-home .block-3 h5, body.nosco-home .block-3 h6, body.nosco-home .block-3 p {
  color: hsl(174deg, 100%, 31%);
}
body.nosco-home .block-3 .brochure {
  color: hsl(174deg, 100%, 31%);
}
body.nosco-home .block-4 {
  background-color: #e8f2f1;
}
body.nosco-home .block-4 a {
  cursor: pointer;
  font-weight: 100;
}
body.nosco-home .block-4 .btn:hover {
  color: white;
}
body.nosco-home .block-4 h2 {
  color: hsl(174deg, 100%, 31%);
}
body.nosco-home .block-4 .card-header {
  border-top: 1px solid hsl(174deg, 100%, 31%);
}
body.nosco-home .block-4 .card-body .card-image {
  max-height: 50px;
  margin-bottom: 10px;
}
body.nosco-home .block-4 .brochure {
  color: hsl(174deg, 100%, 31%);
  font-weight: normal;
}
body.nosco-home .block-5 {
  background-size: cover;
}
body.nosco-home .block-5 h2 strong {
  color: hsl(174deg, 100%, 38%);
}
body.nosco-home .block-5 strong {
  color: hsl(174deg, 100%, 31%);
}
body.nosco-home .block-5 .prephrases h1, body.nosco-home .block-5 .prephrases h2, body.nosco-home .block-5 .prephrases h3, body.nosco-home .block-5 .prephrases h4, body.nosco-home .block-5 .prephrases h5, body.nosco-home .block-5 .prephrases h6, body.nosco-home .block-5 .prephrases p {
  color: white;
}
body.nosco-home .block-5 .system-description {
  padding: 35px 30px 0 30px;
}
body.nosco-home .block-5 .system-description .arrows {
  position: absolute;
  bottom: 10px;
}
@media (max-width: 1000px) {
  body.nosco-home .block-5 .system-description .arrows {
    position: relative;
  }
}
body.nosco-home .block-5 .system-description .persona {
  height: 200px;
}
body.nosco-home .block-5 .system-description i {
  font-size: 30px;
}
body.nosco-home .block-5 .system-image {
  background-color: #e8f2f1;
}
body.nosco-home .block-5 .brochure {
  border-color: white;
  color: white;
  margin: auto;
}
body.nosco-home .block-6 h2 {
  color: hsl(174deg, 100%, 31%);
}
body.nosco-home .block-6 h1, body.nosco-home .block-6 h3, body.nosco-home .block-6 h4, body.nosco-home .block-6 h5, body.nosco-home .block-6 h6, body.nosco-home .block-6 p {
  color: white;
}
body.nosco-home .block-6 .persona .picture {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
}
body.nosco-home .block-6 .persona .picture img {
  width: 100%;
}
body.nosco-home .block-6 .persona .body {
  padding: 25px;
}
body.nosco-home .block-6 .persona .body h3 {
  font-size: 28px;
  margin-bottom: 0;
}
body.nosco-home .block-6 .persona .body h4 {
  font-size: 15px;
  line-height: 24px;
  height: 24px;
}
body.nosco-home .block-6 .persona .body .btn {
  border-radius: 0;
}
body.nosco-home .block-6 .persona .body.yellow-bg {
  background-color: #e39c45 !important;
}
body.nosco-home .block-6 .persona .body.green-bg {
  background-color: #19a191;
}
body.nosco-home .block-6 .persona .body.orange-bg {
  background-color: #d45438;
}
body.nosco-home .block-6 .persona .body.blue-bg {
  background-color: #3b445a;
}
body.nosco-home .block-6 .brochure {
  color: hsl(174deg, 100%, 31%);
}
body.nosco-home .block-persona {
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: none;
  position: relative;
}
body.nosco-home .block-persona .close-persona {
  color: #bbbbbb;
  font-size: 24px;
  cursor: pointer;
}
body.nosco-home .block-persona .close-persona:hover {
  color: #ffffff;
}
body.nosco-home .block-persona h4 {
  color: #ffffff;
  font-size: 30px;
}
body.nosco-home .block-persona p {
  color: #ffffff;
  font-weight: 200;
}

body.nosco-home#nosco-home .block-3 .btn:hover {
  background-color: #eaf0ef;
}
body.nosco-home#nosco-home .block-6 .btn:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}

body.blog h1, body.blog h2, body.blog h3, body.blog h4, body.blog h5, body.blog h6 {
  font-weight: 600;
}
body.blog p {
  font-weight: 200;
}
body.blog .list-article {
  font-weight: 200;
}

/* NB: order matters */
:root {
  --header-height: 70px;
}

.fonts-loaded--titillium body.leanbit--new {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

@media (max-width: 768px) {
  body.leanbit .pagination .page-item :not([rel=prev]):not([rel=next]) {
    display: none;
  }
}
body.leanbit .block {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.leanbit .white-background {
  background-color: rgba(255, 255, 255, 0.3333333333);
}
body.leanbit .white {
  color: white;
}
body.leanbit .max-width-8 {
  max-width: 3.5rem;
}

body.leanbit--new {
  padding-top: var(--header-height);
}
body.leanbit--new h1,
body.leanbit--new h2,
body.leanbit--new h3,
body.leanbit--new h4,
body.leanbit--new h5,
body.leanbit--new h6,
body.leanbit--new .h1,
body.leanbit--new .h2,
body.leanbit--new .h3,
body.leanbit--new .h4,
body.leanbit--new .h5,
body.leanbit--new .h6 {
  color: #009E8E;
}
body.leanbit--new .bg-dark,
body.leanbit--new .bg-primary,
body.leanbit--new .bg--primary-dark {
  color: #fff;
}
body.leanbit--new .bg-dark h1,
body.leanbit--new .bg-dark h2,
body.leanbit--new .bg-dark h3,
body.leanbit--new .bg-dark h4,
body.leanbit--new .bg-dark h5,
body.leanbit--new .bg-dark h6,
body.leanbit--new .bg-dark .h1,
body.leanbit--new .bg-dark .h2,
body.leanbit--new .bg-dark .h3,
body.leanbit--new .bg-dark .h4,
body.leanbit--new .bg-dark .h5,
body.leanbit--new .bg-dark .h6,
body.leanbit--new .bg-primary h1,
body.leanbit--new .bg-primary h2,
body.leanbit--new .bg-primary h3,
body.leanbit--new .bg-primary h4,
body.leanbit--new .bg-primary h5,
body.leanbit--new .bg-primary h6,
body.leanbit--new .bg-primary .h1,
body.leanbit--new .bg-primary .h2,
body.leanbit--new .bg-primary .h3,
body.leanbit--new .bg-primary .h4,
body.leanbit--new .bg-primary .h5,
body.leanbit--new .bg-primary .h6,
body.leanbit--new .bg--primary-dark h1,
body.leanbit--new .bg--primary-dark h2,
body.leanbit--new .bg--primary-dark h3,
body.leanbit--new .bg--primary-dark h4,
body.leanbit--new .bg--primary-dark h5,
body.leanbit--new .bg--primary-dark h6,
body.leanbit--new .bg--primary-dark .h1,
body.leanbit--new .bg--primary-dark .h2,
body.leanbit--new .bg--primary-dark .h3,
body.leanbit--new .bg--primary-dark .h4,
body.leanbit--new .bg--primary-dark .h5,
body.leanbit--new .bg--primary-dark .h6 {
  color: #fff;
}
body.leanbit--new .bg-light h1,
body.leanbit--new .bg-light h2,
body.leanbit--new .bg-light h3,
body.leanbit--new .bg-light h4,
body.leanbit--new .bg-light h5,
body.leanbit--new .bg-light h6,
body.leanbit--new .bg-light .h1,
body.leanbit--new .bg-light .h2,
body.leanbit--new .bg-light .h3,
body.leanbit--new .bg-light .h4,
body.leanbit--new .bg-light .h5,
body.leanbit--new .bg-light .h6,
body.leanbit--new .bg--light h1,
body.leanbit--new .bg--light h2,
body.leanbit--new .bg--light h3,
body.leanbit--new .bg--light h4,
body.leanbit--new .bg--light h5,
body.leanbit--new .bg--light h6,
body.leanbit--new .bg--light .h1,
body.leanbit--new .bg--light .h2,
body.leanbit--new .bg--light .h3,
body.leanbit--new .bg--light .h4,
body.leanbit--new .bg--light .h5,
body.leanbit--new .bg--light .h6,
body.leanbit--new .bg--primary-light h1,
body.leanbit--new .bg--primary-light h2,
body.leanbit--new .bg--primary-light h3,
body.leanbit--new .bg--primary-light h4,
body.leanbit--new .bg--primary-light h5,
body.leanbit--new .bg--primary-light h6,
body.leanbit--new .bg--primary-light .h1,
body.leanbit--new .bg--primary-light .h2,
body.leanbit--new .bg--primary-light .h3,
body.leanbit--new .bg--primary-light .h4,
body.leanbit--new .bg--primary-light .h5,
body.leanbit--new .bg--primary-light .h6 {
  color: #009E8E;
}
body.leanbit--new .card {
  color: hsl(0deg, 0%, 38%);
}
body.leanbit--new .card h1,
body.leanbit--new .card h2,
body.leanbit--new .card h3,
body.leanbit--new .card h4,
body.leanbit--new .card h5,
body.leanbit--new .card h6,
body.leanbit--new .card .h1,
body.leanbit--new .card .h2,
body.leanbit--new .card .h3,
body.leanbit--new .card .h4,
body.leanbit--new .card .h5,
body.leanbit--new .card .h6 {
  color: #009E8E;
}
body.leanbit--new .bg--light {
  background-color: #e5f3f0;
}
body.leanbit--new .bg--primary-light {
  background-color: #69BEAF;
}
body.leanbit--new .bg--primary-dark {
  background-color: #177568;
}

body.leanbit--new p strong {
  font-weight: 600;
  color: inherit;
}

.fonts-loaded--titillium body.leanbit--new,
.fonts-loaded--titillium body.leanbit--nosco {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}
.fonts-loaded--titillium body.leanbit--new h1,
.fonts-loaded--titillium body.leanbit--new h2,
.fonts-loaded--titillium body.leanbit--new h3,
.fonts-loaded--titillium body.leanbit--new h4,
.fonts-loaded--titillium body.leanbit--new h5,
.fonts-loaded--titillium body.leanbit--new h6,
.fonts-loaded--titillium body.leanbit--new .h1,
.fonts-loaded--titillium body.leanbit--new .h2,
.fonts-loaded--titillium body.leanbit--new .h3,
.fonts-loaded--titillium body.leanbit--new .h4,
.fonts-loaded--titillium body.leanbit--new .h5,
.fonts-loaded--titillium body.leanbit--new .h6 {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.leanbit--new,
body.leanbit--nosco {
  letter-spacing: 0.5px;
}

body.leanbit--new {
  font-size: 18px;
}
body.leanbit--new h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

body.leanbit--new .btn.btn--cta {
  letter-spacing: 0.5px;
  font-size: 22px;
}
body.leanbit--new .btn.btn--cta.btn--special {
  background: #009E8E;
  box-shadow: 0 4px 14px rgba(56, 137, 152, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
}
body.leanbit--new .btn.btn--cta.btn--special:hover {
  background: linear-gradient(45deg, hsl(184deg, 100%, 94%), white);
  box-shadow: 0 4px 28px rgba(48, 255, 252, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
  color: #009E8E;
}
body.leanbit--new [class*=btn-outline] {
  border-width: 2px;
}
body.leanbit--new .btn-outline-light:hover {
  color: #009E8E;
}

body.leanbit--new .carousel {
  --indicators-panel-height: 45px;
}
body.leanbit--new .carousel .carousel-indicators {
  margin: 0;
  padding: 0;
  height: var(--indicators-panel-height);
  background: #009E8E;
  align-items: center;
}
body.leanbit--new .carousel .carousel-indicators li {
  --indicator-size: 11px;
  --indicator-margin-x: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--indicator-size);
  height: var(--indicator-size);
  margin-left: var(--indicator-margin-x);
  margin-right: var(--indicator-margin-x);
  border-radius: 100px;
  background: #fff;
  box-sizing: border-box;
  border: none;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit--new .carousel .carousel-item {
    background: linear-gradient(90deg, #0c685d, #0e7568, #0e7568);
    min-height: max(75vh, 60vw);
  }
  body.leanbit--new .carousel .carousel-item .carousel-caption h2 {
    font-size: 24px;
  }
  body.leanbit--new .carousel .carousel-item img {
    position: absolute;
    bottom: calc(0px + var(--indicators-panel-height));
    right: 0px;
    height: 60%;
  }
}
/*---- END MOBILE ----*/
/*---- MOBILE LANDSCAPE ----*/
@media (max-width: 768px) and (orientation: landscape) {
  body.leanbit--new .carousel .carousel-item {
    min-height: max(75vh, 50vw);
  }
  body.leanbit--new .carousel .carousel-item img {
    height: 90%;
  }
}
/*---- END MOBILE LANDSCAPE ----*/
body.leanbit--new .container-fluid.container--boxed, body.leanbit--new .container--boxed.container-sm, body.leanbit--new .container--boxed.container-md, body.leanbit--new .container--boxed.container-lg, body.leanbit--new .container--boxed.container-xl,
body.leanbit--nosco .container-fluid.container--boxed,
body.leanbit--nosco .container--boxed.container-sm,
body.leanbit--nosco .container--boxed.container-md,
body.leanbit--nosco .container--boxed.container-lg,
body.leanbit--nosco .container--boxed.container-xl,
body.leanbit--solution .container-fluid.container--boxed,
body.leanbit--solution .container--boxed.container-sm,
body.leanbit--solution .container--boxed.container-md,
body.leanbit--solution .container--boxed.container-lg,
body.leanbit--solution .container--boxed.container-xl,
body.leanbit--cases .container-fluid.container--boxed,
body.leanbit--cases .container--boxed.container-sm,
body.leanbit--cases .container--boxed.container-md,
body.leanbit--cases .container--boxed.container-lg,
body.leanbit--cases .container--boxed.container-xl {
  --padding-x: 50px;
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit--new .container-fluid.container--boxed, body.leanbit--new .container--boxed.container-sm, body.leanbit--new .container--boxed.container-md, body.leanbit--new .container--boxed.container-lg, body.leanbit--new .container--boxed.container-xl,
body.leanbit--nosco .container-fluid.container--boxed,
body.leanbit--nosco .container--boxed.container-sm,
body.leanbit--nosco .container--boxed.container-md,
body.leanbit--nosco .container--boxed.container-lg,
body.leanbit--nosco .container--boxed.container-xl,
body.leanbit--solution .container-fluid.container--boxed,
body.leanbit--solution .container--boxed.container-sm,
body.leanbit--solution .container--boxed.container-md,
body.leanbit--solution .container--boxed.container-lg,
body.leanbit--solution .container--boxed.container-xl,
body.leanbit--cases .container-fluid.container--boxed,
body.leanbit--cases .container--boxed.container-sm,
body.leanbit--cases .container--boxed.container-md,
body.leanbit--cases .container--boxed.container-lg,
body.leanbit--cases .container--boxed.container-xl {
    --padding-x: 15px;
  }
}
/*---- END MOBILE ----*/
body.leanbit--new .block.cta-section {
  background: none;
}
body.leanbit--new .block.cta-section .container > div {
  width: 100% !important;
}
body.leanbit--new .block.cta-section .btn {
  border-radius: 10px;
}
body.leanbit--new .block.cta-section .btn.btn--cta {
  font-weight: bold;
  text-transform: uppercase;
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}
body.leanbit--new .block.cta-section .btn.btn--cta.btn--special {
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
}
body.leanbit--new .block.cta-section .btn.btn--cta.btn--special:hover {
  box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
}

#explore-nosco-features .card {
  overflow: hidden;
}
#explore-nosco-features .card-img-top {
  padding-left: 10px;
  padding-right: 10px;
  height: 225px;
  object-fit: contain;
  border-top: 1px solid hsl(168deg, 0%, 89%);
}
#explore-nosco-features .card-body {
  background: #009E8E;
}
#explore-nosco-features .card-body h4 {
  color: #009E8E;
  color: #fff;
  margin-bottom: 0px;
  text-transform: uppercase;
}
#explore-nosco-features .nosco-logo {
  width: 80px;
  margin: 0px auto;
}

body.leanbit .sendgrid-iframe iframe {
  width: 100%;
  height: 600px;
  border: none;
}

body.leanbit svg#unox .unox-3 {
  fill: #fff;
}

.fonts-loaded--titillium body.leanbit--new header.header,
.fonts-loaded--titillium body.leanbit--nosco header.header,
.fonts-loaded--titillium body.leanbit--solution header.header,
.fonts-loaded--titillium body.leanbit--cases header.header,
.fonts-loaded--titillium body.leanbit--blog header.header {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.leanbit--new header.header,
body.leanbit--nosco header.header,
body.leanbit--solution header.header,
body.leanbit--cases header.header,
body.leanbit--blog header.header {
  height: var(--header-height);
}
body.leanbit--new header.header .navbar,
body.leanbit--nosco header.header .navbar,
body.leanbit--solution header.header .navbar,
body.leanbit--cases header.header .navbar,
body.leanbit--blog header.header .navbar {
  height: var(--header-height);
}
body.leanbit--new header.header .navbar a,
body.leanbit--nosco header.header .navbar a,
body.leanbit--solution header.header .navbar a,
body.leanbit--cases header.header .navbar a,
body.leanbit--blog header.header .navbar a {
  display: flex;
  align-items: center;
  height: 100%;
}
body.leanbit--new header.header .navbar .nav-link,
body.leanbit--nosco header.header .navbar .nav-link,
body.leanbit--solution header.header .navbar .nav-link,
body.leanbit--cases header.header .navbar .nav-link,
body.leanbit--blog header.header .navbar .nav-link {
  height: var(--header-height);
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
}
body.leanbit--new header.header .navbar .nav-link.active,
body.leanbit--nosco header.header .navbar .nav-link.active,
body.leanbit--solution header.header .navbar .nav-link.active,
body.leanbit--cases header.header .navbar .nav-link.active,
body.leanbit--blog header.header .navbar .nav-link.active {
  font-weight: 600;
}
body.leanbit--new header.header .navbar .nav-link.dropdown-toggle::after,
body.leanbit--nosco header.header .navbar .nav-link.dropdown-toggle::after,
body.leanbit--solution header.header .navbar .nav-link.dropdown-toggle::after,
body.leanbit--cases header.header .navbar .nav-link.dropdown-toggle::after,
body.leanbit--blog header.header .navbar .nav-link.dropdown-toggle::after {
  margin-top: 3px;
  font-family: "Font Awesome 6 Free";
}
body.leanbit--new header.header #navBar .navbar-body,
body.leanbit--nosco header.header #navBar .navbar-body,
body.leanbit--solution header.header #navBar .navbar-body,
body.leanbit--cases header.header #navBar .navbar-body,
body.leanbit--blog header.header #navBar .navbar-body {
  margin-right: auto;
  margin-left: 100px;
}

/*---- DESKTOP ----*/
@media (min-width: 992px) {
  body.leanbit--new .nav-link,
body.leanbit--nosco .nav-link,
body.leanbit--solution .nav-link,
body.leanbit--cases .nav-link {
    padding: 0px 20px;
  }
}
/*---- END DESKTOP ----*/
.fonts-loaded--titillium body.leanbit--new footer,
.fonts-loaded--titillium body.leanbit--nosco footer {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.leanbit footer {
  font-size: 18px;
  background-color: #177568;
}
body.leanbit footer svg.brand #text {
  fill: #fff;
}
body.leanbit footer svg.brand #payoff {
  fill: #fff;
  opacity: 0.8;
}
body.leanbit footer .links {
  display: flex;
  justify-content: center;
  grid-auto-flow: column;
  font-size: 14px;
}
body.leanbit footer .links a:hover {
  text-decoration: none;
}
body.leanbit footer .links.links--social i {
  font-size: 28px;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit footer .links.links--social {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0 auto;
  }
  body.leanbit footer .links.links--social a {
    justify-content: flex-start;
  }
  body.leanbit footer .links.links--social a i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
  }
  body.leanbit footer .links.links--social a .link__text {
    margin-left: 5px;
  }
}
/*---- END MOBILE ----*/
.fonts-loaded--titillium body.page--amazon-connect h1 {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}
.fonts-loaded--titillium body.page--amazon-connect #case-studies .card .card-title {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.page--amazon-connect #hero {
  color: #fff;
  padding: 40px;
  font-size: 22px;
  background-image: linear-gradient(45deg, #173d52, transparent);
  /* alternative 1 */
  /* alternative 2 (requires 1)*/
  /* alternative 3 (requires 1)*/
}
body.page--amazon-connect #hero p.lead {
  font-size: 24px;
  padding-bottom: 10px;
}
body.page--amazon-connect #hero ul {
  list-style-type: square;
}
body.page--amazon-connect #hero ul li {
  padding-bottom: 8px;
}
body.page--amazon-connect #hero p,
body.page--amazon-connect #hero li {
  letter-spacing: 0.5px;
}
body.page--amazon-connect .amazon-partner-image.amazon-partner-image--with-shadow {
  box-shadow: 0px 2px 14px -1px rgba(0, 0, 0, 0.18);
  margin: 0px 45px;
}

body.page--amazon-connect #values {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "excellence-text excellence-image" "reliability-image reliability-text";
}
body.page--amazon-connect #values #excellence-text {
  grid-area: excellence-text;
}
body.page--amazon-connect #values #excellence-image {
  grid-area: excellence-image;
}
body.page--amazon-connect #values #reliability-text {
  grid-area: reliability-text;
}
body.page--amazon-connect #values #reliability-image {
  grid-area: reliability-image;
}
body.page--amazon-connect #values .checker__box {
  width: 100%;
}
body.page--amazon-connect #values .checker__box::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
body.page--amazon-connect #values .checker__box::after {
  content: "";
  display: table;
  clear: both;
}
body.page--amazon-connect #values .checker__box:nth-child(odd) {
  text-align: right;
}
body.page--amazon-connect #values .checker__box--text {
  padding: 40px;
  color: hsl(0deg, 0%, 38%);
}
body.page--amazon-connect #values .checker__box--text .text__content {
  transform: translateY(50%);
}
body.page--amazon-connect #values .checker__box--text h2 {
  text-transform: uppercase;
}
body.page--amazon-connect #values .checker__box--image figure {
  height: 100%;
  width: 100%;
}
body.page--amazon-connect #values .checker__box--image figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

body.page--amazon-connect #nosco {
  position: relative;
  z-index: 1;
}
body.page--amazon-connect #nosco p {
  font-size: 21px;
}
body.page--amazon-connect #nosco .hexagon--outline {
  height: 30vw;
  position: absolute;
  right: 0px;
  bottom: calc(100px - 25vw);
}
body.page--amazon-connect #nosco .hexagon--outline img {
  height: 100%;
}
body.page--amazon-connect #nosco-features {
  margin-top: -100px;
  padding-top: 100px;
  position: relative;
}
body.page--amazon-connect #nosco-features .hexagon--omnichannel-inbox {
  position: absolute;
  width: 26vw;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  filter: drop-shadow(2px 3px 10px rgba(0, 0, 0, 0.11));
}
body.page--amazon-connect #nosco-features .nosco-feature h4 {
  position: relative;
  font-size: 50px;
  font-weight: 700;
}
body.page--amazon-connect #nosco-features .nosco-feature h4:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: calc(50% + 2px);
  background-image: url("../svg/logos/nosco_logo_symbol.svg");
  transform: translate(calc(-100% - 10px), -50%);
  height: 30px;
  width: 30px;
}
body.page--amazon-connect #nosco-features .nosco-feature p {
  font-size: 20px;
}
body.page--amazon-connect #nosco-features .nosco-features__cta-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body.page--amazon-connect #nosco-features .nosco-features__cta-line p {
  font-size: 22px;
}
body.page--amazon-connect #nosco-features .nosco-features__cta-line .btn {
  width: max-content;
  white-space: nowrap;
}

body.page--amazon-connect #case-studies {
  background-image: linear-gradient(0deg, #69BEAF, #69BEAF 60%, transparent 0%);
}
body.page--amazon-connect #case-studies h2 {
  font-size: 56px;
}
body.page--amazon-connect #case-studies .card {
  color: hsl(0deg, 0%, 38%);
  border-radius: 0px;
}
body.page--amazon-connect #case-studies .card h1,
body.page--amazon-connect #case-studies .card h2,
body.page--amazon-connect #case-studies .card h3,
body.page--amazon-connect #case-studies .card h4,
body.page--amazon-connect #case-studies .card h5,
body.page--amazon-connect #case-studies .card h6,
body.page--amazon-connect #case-studies .card .h1,
body.page--amazon-connect #case-studies .card .h2,
body.page--amazon-connect #case-studies .card .h3,
body.page--amazon-connect #case-studies .card .h4,
body.page--amazon-connect #case-studies .card .h5,
body.page--amazon-connect #case-studies .card .h6 {
  color: #009E8E;
}
body.page--amazon-connect #case-studies .card .card-title {
  text-transform: uppercase;
  font-size: 18px;
}
body.page--amazon-connect #case-studies .card .card-text {
  font-size: 16px;
}
body.page--amazon-connect #case-studies .card figure img {
  height: 150px;
  width: 100%;
  object-fit: contain;
}
body.page--amazon-connect #event {
  background: #98D0C3;
}
body.page--amazon-connect #event h2 {
  color: #177568;
}
body.page--amazon-connect #event p,
body.page--amazon-connect #event li {
  font-size: 16px;
}
body.page--amazon-connect #event figure {
  position: relative;
  overflow: hidden;
  width: 100%;
}
body.page--amazon-connect #event figure::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #d0e6e1;
  z-index: 1;
  mix-blend-mode: soft-light;
  opacity: 1;
}
body.page--amazon-connect #event img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.3);
  filter: grayscale(0.8) brightness(1.1) contrast(0.9);
}

body.page--amazon-connect #cta-block p {
  font-size: 27px;
  font-weight: 400;
}
body.page--amazon-connect #cta-block a {
  font-weight: 700;
}

/*---- DESKTOP XL ----*/
@media (min-width: 1200px) {
  body.page--amazon-connect #nosco .hexagon--outline {
    position: absolute;
  }
  body.page--amazon-connect #nosco .hexagon--outline img {
    height: 100%;
  }
}
/*---- END DESKTOP XL ----*/
/*---- TABLET DOWN ----*/
@media (max-width: 991.98px) {
  body.page--amazon-connect #nosco-features .hexagon--omnichannel-inbox {
    position: relative;
    width: 95%;
    margin: auto;
    left: 0px;
    top: 0px;
    transform: unset;
    filter: drop-shadow(2px 3px 10px rgba(0, 0, 0, 0.11));
  }
}
/*---- END TABLET DOWN ----*/
/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.page--amazon-connect #hero {
    min-height: calc(100vh - var(--header-height));
    height: auto;
    padding: 20px 0px;
    font-size: 16px;
  }
  body.page--amazon-connect #hero h1 {
    line-height: 1.2;
    font-size: 28px;
    letter-spacing: 1.2px;
    margin-bottom: 30px;
  }
  body.page--amazon-connect #hero p.lead {
    font-size: 16px;
    padding-bottom: 10px;
  }
}
@media (max-width: 767.98px) {
  body.page--amazon-connect #values {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "excellence-text" "excellence-image" "reliability-text" "reliability-image";
  }
  body.page--amazon-connect #values .checker__box:nth-child(odd) {
    text-align: center;
  }
  body.page--amazon-connect #values .checker__box--text .text__content {
    transform: translateY(10%);
  }
}
@media (max-width: 767.98px) {
  body.page--amazon-connect #nosco h2 {
    font-size: 35px;
    line-height: 1.2;
  }
  body.page--amazon-connect #nosco p {
    font-size: 18px;
  }
  body.page--amazon-connect #nosco-features .nosco-feature {
    margin-bottom: 30px;
  }
  body.page--amazon-connect #nosco-features .nosco-feature h4 {
    font-size: 35px;
    line-height: 1.2;
  }
  body.page--amazon-connect #nosco-features .nosco-features__cta-line {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  body.page--amazon-connect #case-studies .card:nth-child(odd) figure {
    float: none;
    padding: 0px 0px 24px 0px;
  }
}
@media (max-width: 767.98px) {
  body.page--amazon-connect #cta-block p {
    font-size: 24px;
    font-weight: 400;
  }
  body.page--amazon-connect #cta-block a {
    font-weight: 700;
  }
}
/*---- END MOBILE ----*/
body.leanbit--new.leanbit--cases .bg-img-hero {
  height: 50vh;
}
body.leanbit--new.leanbit--cases h1,
body.leanbit--new.leanbit--cases h2 {
  margin-top: 30px;
  margin-bottom: 15px;
}
body.leanbit--new.leanbit--cases .cta-section {
  background: whitesmoke;
  font-size: 1.3rem;
}
body.leanbit--new.leanbit--cases .cta-section .lead {
  font-size: 1.3rem;
}
body.leanbit--new.leanbit--cases .cta-section .btn.btn-cta {
  font-size: 1.3rem;
}
body.leanbit--new.leanbit--cases .card-img-top.wp-post-image {
  object-fit: cover;
  object-position: center;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit--new.leanbit--cases .bg-img-hero {
    height: 30vh;
  }
}
/*---- END MOBILE ----*/
body.leanbit--cases.leanbit--cases-offcar .bg-img-hero {
  background-image: url("../../assets/images/wallpapers/offcar_wallpaper.jpg");
  background-size: contain;
  background-position: top left;
  background-color: #0c0d0f;
  height: 50vh;
}
body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container {
  display: flex;
  align-items: center;
  height: 100%;
}
body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container h3 {
  font-size: 80px;
  text-transform: uppercase;
  text-shadow: 0px 0px 10px black;
  margin-bottom: 0px;
  line-height: 1;
}

.fonts-loaded--source-sans-pro body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container h3 {
  font-family: "Source Sans Pro", Verdana, Geneva, Tahoma, sans-serif;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container {
    align-items: flex-end;
  }
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container h3 {
    font-size: min(8vh, 16vw);
    line-height: 1.2;
  }
}
/*---- END MOBILE ----*/
/*---- MOBILE LANDSCAPE ----*/
@media (max-width: 768px) and (orientation: landscape) {
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero {
    height: 70vh;
  }
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container {
    padding-left: 40vw;
    align-items: center;
  }
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container h3 {
    font-size: min(15vh, 8vw);
    line-height: 1.2;
  }
}
/*---- END MOBILE LANDSCAPE ----*/
/*---- TABLET ----*/
@media (min-width: 768px) and (max-width: 991.98px) {
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero {
    height: 40vh;
  }
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container {
    align-items: center;
  }
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container h3 {
    font-size: 60px;
  }
}
/*---- END TABLET ----*/
/*---- TABLET LANDSCAPE ----*/
@media (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero {
    height: 70vh;
  }
  body.leanbit--cases.leanbit--cases-offcar .bg-img-hero .container h3 {
    font-size: min(13vh, 7vw);
    line-height: 1.2;
  }
}
/*---- END TABLET LANDSCAPE ----*/
body.leanbit--cases.leanbit--cases-disenia .bg-img-hero {
  background-image: url("../../assets/images/wallpapers/disenia_wallpaper.jpg");
  background-size: contain;
  background-position: top left;
  background-color: #0c0d0f;
  height: 50vh;
}
body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container {
  display: flex;
  align-items: center;
  height: 100%;
}
body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container h3 {
  font-size: 45px;
  text-transform: uppercase;
  text-shadow: 0px 0px 10px black;
  margin-bottom: 0px;
  line-height: 1;
}

.fonts-loaded--source-sans-pro body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container h3 {
  font-family: "Source Sans Pro", Verdana, Geneva, Tahoma, sans-serif;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container {
    align-items: flex-end;
  }
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container h3 {
    font-size: min(4vh, 16vw);
    line-height: 1.2;
  }
}
/*---- END MOBILE ----*/
/*---- MOBILE LANDSCAPE ----*/
@media (max-width: 768px) and (orientation: landscape) {
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero {
    height: 70vh;
  }
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container {
    padding-left: 40vw;
    align-items: center;
  }
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container h3 {
    font-size: min(7vh, 8vw);
    line-height: 1.2;
  }
}
/*---- END MOBILE LANDSCAPE ----*/
/*---- TABLET ----*/
@media (min-width: 768px) and (max-width: 991.98px) {
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero {
    height: 40vh;
  }
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container {
    align-items: center;
  }
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container h3 {
    font-size: 60px;
  }
}
/*---- END TABLET ----*/
/*---- TABLET LANDSCAPE ----*/
@media (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero {
    height: 70vh;
  }
  body.leanbit--cases.leanbit--cases-disenia .bg-img-hero .container h3 {
    font-size: min(6vh, 5vw);
    line-height: 1.2;
  }
}
/*---- END TABLET LANDSCAPE ----*/
body.leanbit--cases.leanbit--cases-san-marco .bg-img-hero {
  background-position: center 37%;
}

body.page--leanbit-home h2 {
  font-size: 62px;
  font-weight: 700;
}
body.page--leanbit-home p.lead {
  font-size: 28px;
}

body.page--leanbit-home #home-carousel {
  position: relative;
  z-index: 50;
}
body.page--leanbit-home #home-carousel .carousel-caption {
  width: 48%;
  text-align: left;
  top: 35%;
  height: auto;
  left: 10%;
  transform: translateY(-50%);
}
body.page--leanbit-home #home-carousel h2 {
  line-height: 1.2;
  font-size: 4vw;
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 20px;
  width: 90%;
}
body.page--leanbit-home #home-carousel p.lead {
  font-size: 24px;
  width: 90%;
}
body.page--leanbit-home #home-carousel .btn--cta {
  margin-top: 20px;
}
body.page--leanbit-home .container--screen-tall {
  min-height: calc(100vh - var(--header-height));
}

body.page--leanbit-home #nosco-platform {
  position: relative;
  background: linear-gradient(-180deg, #f0f0f0, #fff);
  z-index: 40;
}
body.page--leanbit-home #nosco-platform h2,
body.page--leanbit-home #nosco-platform p,
body.page--leanbit-home #nosco-platform .btn {
  position: relative;
}
body.page--leanbit-home #nosco-platform #nosco-interface-1 {
  position: absolute;
  width: 35%;
  bottom: -10%;
  left: -10%;
}
body.page--leanbit-home #nosco-platform #nosco-interface-1 img {
  width: 100%;
}
body.page--leanbit-home #nosco-platform #nosco-interface-2 {
  position: absolute;
  top: -20%;
  right: 0px;
}
body.page--leanbit-home #nosco-platform #nosco-interface-2 img {
  width: 100%;
}

body.page--leanbit-home #consulting {
  position: relative;
  background: #E5F3F0;
}
body.page--leanbit-home #consulting .content {
  z-index: 1;
}
body.page--leanbit-home #consulting #leanbit-abstract-3d {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 0;
  width: min(40vw, 40vh);
}
body.page--leanbit-home #consulting #leanbit-abstract-3d img {
  height: 100%;
  width: 100%;
}

body.page--leanbit-home #cloud {
  position: relative;
  z-index: 20;
}
body.page--leanbit-home #cloud #cloud-girl {
  position: absolute;
  width: 35%;
  bottom: -20%;
  left: -3%;
}
body.page--leanbit-home #cloud #cloud-girl img {
  width: 100%;
}
body.page--leanbit-home #cloud #cloud-image {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}
body.page--leanbit-home #cloud #cloud-image img {
  width: 100%;
}

body.page--leanbit-home #blog-posts {
  --card-img-top-height: 20vw;
  background: #98D0C3;
}
body.page--leanbit-home #blog-posts .card-deck {
  margin-top: calc(-1 * var(--card-img-top-height));
  width: 100%;
}
body.page--leanbit-home #blog-posts .card-wrapper {
  flex: 1 1 calc(25% - 10px);
}
body.page--leanbit-home #blog-posts .card .card-img-top {
  height: var(--card-img-top-height);
  position: relative;
}
body.page--leanbit-home #blog-posts .card .card-img-top::before {
  content: "";
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0px 0px 40px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px 0px #98D0C3;
}
body.page--leanbit-home #blog-posts .card .card-img-top img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
body.page--leanbit-home #blog-posts .card .card-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
}
body.page--leanbit-home #blog-posts .card .card-text {
  font-size: 14px;
}
/*---- DESKTOP XL ----*/
@media (min-width: 1200px) {
  body.page--leanbit-home #blog-posts {
    --card-img-top-height: 18vw;
  }
  body.page--leanbit-home #blog-posts .card-deck {
    margin-top: calc(-1 * var(--card-img-top-height));
    width: 90%;
  }
  body.page--leanbit-home #blog-posts .card-wrapper {
    flex: 1 1 calc(25% - 10px);
  }
  body.page--leanbit-home #blog-posts .card .card-img-top {
    height: var(--card-img-top-height);
  }
}
/*---- END DESKTOP XL ----*/
/*---- TABLET DOWN ----*/
@media (max-width: 991.98px) {
  body.page--leanbit-home #home-carousel .carousel-caption {
    width: 50%;
  }
  body.page--leanbit-home #home-carousel h2 {
    line-height: 30px;
    width: 100%;
    font-size: 28px;
  }
  body.page--leanbit-home #home-carousel p.lead {
    width: 90%;
    font-size: 18px;
  }
  body.page--leanbit-home #home-carousel .btn--cta {
    margin-top: 0px;
  }
  body.page--leanbit-home #nosco-platform #nosco-interface-2 {
    width: 50%;
  }
  body.page--leanbit-home #blog-posts {
    --card-img-top-height: 30vw;
  }
  body.page--leanbit-home #blog-posts .card-deck {
    margin-top: calc(-1 * var(--card-img-top-height));
    width: 90%;
  }
  body.page--leanbit-home #blog-posts .card-wrapper {
    flex: 1 1 calc(50% - 10px);
  }
  body.page--leanbit-home #blog-posts .card .card-img-top {
    height: var(--card-img-top-height);
  }
}
/*---- END TABLET DOWN ----*/
/*---- TABLET LANDSCAPE ----*/
@media (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  body.page--leanbit-home #nosco-platform {
    padding-bottom: 250px !important;
  }
  body.page--leanbit-home #nosco-platform #nosco-interface-2 {
    width: 30%;
  }
  body.page--leanbit-home #consulting {
    padding-top: 100px !important;
    padding-bottom: 150px !important;
  }
}
/*---- END TABLET LANDSCAPE ----*/
/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.page--leanbit-home h2 {
    font-size: 42px;
    font-weight: 700;
  }
  body.page--leanbit-home p.lead {
    font-size: 28px;
  }
  body.page--leanbit-home #consulting #leanbit-abstract-3d {
    width: min(60vw, 60vh);
  }
  body.page--leanbit-home #home-carousel #carousel-solutions,
body.page--leanbit-home #home-carousel #carousel-cloud {
    background: linear-gradient(90deg, #5da99c, #69beaf, #69beaf);
  }
  body.page--leanbit-home #home-carousel .carousel-caption {
    position: relative;
    width: 90%;
    text-align: left;
    top: 0px;
    left: 0px;
    height: auto;
    transform: none;
    margin: 0 auto;
    padding-bottom: calc(20px + var(--indicators-panel-height));
  }
  body.page--leanbit-home #home-carousel .carousel-caption h2 {
    font-size: 28px;
  }
  body.page--leanbit-home #home-carousel .carousel-caption p.lead {
    font-size: 18px;
  }
  body.page--leanbit-home #home-carousel .carousel-caption .btn--cta {
    margin-top: 20px;
  }
  body.page--leanbit-home #home-carousel #carousel-nosco img {
    right: 10%;
  }
  body.page--leanbit-home #home-carousel #carousel-solutions img {
    height: 40%;
  }
  body.page--leanbit-home #blog-posts {
    --card-img-top-height: calc(400px - 5vw);
  }
  body.page--leanbit-home #blog-posts .card-deck {
    margin-top: calc(-1 * var(--card-img-top-height));
    width: calc(400px - 5vw);
  }
  body.page--leanbit-home #blog-posts .card-wrapper {
    flex: 1 1 calc(50% - 10px);
  }
  body.page--leanbit-home #blog-posts .card .card-img-top {
    height: var(--card-img-top-height);
  }
}
/*---- END MOBILE ----*/
/*---- MOBILE LANDSCAPE ----*/
@media (max-width: 768px) and (orientation: landscape) {
  body.page--leanbit-home #home-carousel .carousel-caption {
    width: 70%;
  }
  body.page--leanbit-home #home-carousel .carousel-caption h2 {
    font-size: 28px;
  }
  body.page--leanbit-home #home-carousel .carousel-caption p.lead {
    width: 70%;
    font-size: 18px;
  }
  body.page--leanbit-home #home-carousel .carousel-caption .btn--cta {
    margin-top: 20px;
  }
  body.page--leanbit-home #home-carousel #carousel-nosco img {
    height: 80%;
    right: 5%;
  }
  body.page--leanbit-home #home-carousel #carousel-solutions img {
    height: 60%;
  }
  body.page--leanbit-home #home-carousel #carousel-cloud img {
    height: 80%;
  }
}
/*---- END MOBILE LANDSCAPE ----*/
/*---- MOBILE XS ----*/
@media (max-width: 575.98px) {
  body.page--leanbit-home #home-carousel #carousel-nosco img {
    right: calc(10% - 50px);
  }
  body.page--leanbit-home #home-carousel .carousel-caption {
    width: 90%;
  }
}
/*---- END MOBILE XS ----*/
.fonts-loaded--titillium body.leanbit--new.page--internal h1 {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.leanbit--new.page--internal .hero-like {
  background-image: url("../images/leanbit_company/azienda_bg.png");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}
body.leanbit--new.page--internal .hero-like h1 {
  font-size: 72px;
  margin-bottom: 0px;
}
body.leanbit--new.page--internal p.lead.lead--important {
  color: #009E8E;
  font-size: 32px;
  font-weight: 600;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.leanbit--new.page--internal .hero-like {
    background-size: 200%;
  }
  body.leanbit--new.page--internal .hero-like h1 {
    font-size: 60px;
  }
  body.leanbit--new.page--internal .hero-like p.lead {
    letter-spacing: 0.2px;
  }
  body.leanbit--new.page--internal p.lead.lead--important {
    font-size: 26px;
    text-align: left;
  }
}
.fonts-loaded--brix-sans body.leanbit--nosco h1,
.fonts-loaded--brix-sans body.leanbit--nosco h2,
.fonts-loaded--brix-sans body.leanbit--nosco h3,
.fonts-loaded--brix-sans body.leanbit--nosco h4,
.fonts-loaded--brix-sans body.leanbit--nosco h5,
.fonts-loaded--brix-sans body.leanbit--nosco h6,
.fonts-loaded--brix-sans body.leanbit--nosco .h1,
.fonts-loaded--brix-sans body.leanbit--nosco .h2,
.fonts-loaded--brix-sans body.leanbit--nosco .h3,
.fonts-loaded--brix-sans body.leanbit--nosco .h4,
.fonts-loaded--brix-sans body.leanbit--nosco .h5,
.fonts-loaded--brix-sans body.leanbit--nosco .h6 {
  font-family: "BrixSans", sans-serif;
}

body.page--nosco-overview #overview-image-block {
  background: linear-gradient(to bottom, #e6efee, rgba(0, 0, 0, 0) 20%, rgba(25, 161, 145, 0.2) 100%), repeating-linear-gradient(150deg, #e6efee, #e6efee 1px, transparent 2px, transparent 80px) 0 0, repeating-linear-gradient(30deg, #e6efee, #e6efee 1px, transparent 2px, transparent 80px) 0 0;
  background-size: 100%, 100%, 160px 93px, 160px 93px;
  background-color: hsl(168deg, 0%, 98%);
  box-shadow: inset 0px 0px 200px 0 rgba(0, 0, 0, 0.12);
}

body.page--nosco-overview {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body.page--nosco-overview h1,
body.page--nosco-overview h2,
body.page--nosco-overview h3,
body.page--nosco-overview h4,
body.page--nosco-overview h5,
body.page--nosco-overview h6,
body.page--nosco-overview p {
  font-weight: 100;
}
body.page--nosco-overview h2 {
  margin-bottom: 8px;
  font-size: 30px;
}
body.page--nosco-overview .center {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.page--nosco-overview .accordion .card {
  background-color: transparent !important;
  border-radius: 0;
}
body.page--nosco-overview .accordion .card-btn {
  color: #009E8E;
  font-size: 20px;
  line-height: 22px;
}
body.page--nosco-overview .accordion .card-header {
  background-color: transparent !important;
  border-bottom: none;
}
body.page--nosco-overview .accordion .card-btn-toggle {
  font-size: 30px;
  line-height: 20px;
}
body.page--nosco-overview .accordion .card {
  box-shadow: none;
}
body.page--nosco-overview .accordion .card-body {
  font-size: 15px;
}
body.page--nosco-overview .hidden {
  display: none;
}
body.page--nosco-overview .block {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.page--nosco-overview .block-1 {
  background-color: #009E8E;
  padding-bottom: 0;
}
body.page--nosco-overview .block-1 h1,
body.page--nosco-overview .block-1 h2,
body.page--nosco-overview .block-1 h3,
body.page--nosco-overview .block-1 h4,
body.page--nosco-overview .block-1 h5,
body.page--nosco-overview .block-1 h6,
body.page--nosco-overview .block-1 p {
  color: white;
}
body.page--nosco-overview .block-1 h1 {
  font-size: 8vw;
}
body.page--nosco-overview .block-1 h2 {
  font-size: 4vw;
}
body.page--nosco-overview .block-1 .persona {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  overflow: hidden;
}
@media (min-width: 1000px) {
  body.page--nosco-overview .block-1 h1 {
    font-size: 100px;
  }
  body.page--nosco-overview .block-1 h2 {
    font-size: 60px;
  }
}
@media (max-width: 1000px) {
  body.page--nosco-overview .block-1 .persona {
    height: 240px;
  }
}
@media (max-width: 560px) {
  body.page--nosco-overview .block-1 .persona {
    height: 200px;
  }
}
body.page--nosco-overview .block-1 .personas * {
  margin: 0;
  padding: 0;
}
body.page--nosco-overview .block-1 .personas img {
  width: 100%;
  display: block;
}
body.page--nosco-overview .block-2 {
  background-size: cover;
}
body.page--nosco-overview .block-2 h1,
body.page--nosco-overview .block-2 h2,
body.page--nosco-overview .block-2 h3,
body.page--nosco-overview .block-2 h4,
body.page--nosco-overview .block-2 h5,
body.page--nosco-overview .block-2 h6,
body.page--nosco-overview .block-2 p,
body.page--nosco-overview .block-2 i {
  color: white;
}
body.page--nosco-overview .block-2 h2,
body.page--nosco-overview .block-2 i {
  font-size: 45px;
}
body.page--nosco-overview .block-2 a {
  cursor: pointer;
}
body.page--nosco-overview .block-2 p {
  font-size: 25px;
}
body.page--nosco-overview .block-2 strong {
  color: hsl(174deg, 100%, 38%);
}
body.page--nosco-overview .block-2 .cloud-io-img {
  max-height: 300px;
  margin: auto;
}
body.page--nosco-overview .block-2 .slick-slide.slick-active > div {
  width: 100%;
}
body.page--nosco-overview .block-3 h1,
body.page--nosco-overview .block-3 h2,
body.page--nosco-overview .block-3 h3,
body.page--nosco-overview .block-3 h4,
body.page--nosco-overview .block-3 h5,
body.page--nosco-overview .block-3 h6,
body.page--nosco-overview .block-3 p {
  color: #009E8E;
}
body.page--nosco-overview .block-3 .brochure {
  color: #009E8E;
}
body.page--nosco-overview .block-4 {
  background-color: #e8f2f1;
}
body.page--nosco-overview .block-4 a {
  cursor: pointer;
  font-weight: 100;
}
body.page--nosco-overview .block-4 .btn:hover {
  color: white;
}
body.page--nosco-overview .block-4 h2 {
  color: #009E8E;
}
body.page--nosco-overview .block-4 .card-header {
  border-top: 1px solid #009E8E;
}
body.page--nosco-overview .block-4 .card-body .card-image {
  max-height: 50px;
  margin-bottom: 10px;
}
body.page--nosco-overview .block-4 .brochure {
  color: #009E8E;
  font-weight: normal;
}
body.page--nosco-overview .block-5 {
  background-size: cover;
}
body.page--nosco-overview .block-5 h2 strong {
  color: hsl(174deg, 100%, 38%);
}
body.page--nosco-overview .block-5 strong {
  color: #009E8E;
}
body.page--nosco-overview .block-5 .prephrases h1,
body.page--nosco-overview .block-5 .prephrases h2,
body.page--nosco-overview .block-5 .prephrases h3,
body.page--nosco-overview .block-5 .prephrases h4,
body.page--nosco-overview .block-5 .prephrases h5,
body.page--nosco-overview .block-5 .prephrases h6,
body.page--nosco-overview .block-5 .prephrases p {
  color: white;
}
body.page--nosco-overview .block-5 .system-description {
  padding: 35px 30px 0 30px;
}
body.page--nosco-overview .block-5 .system-description .arrows {
  position: absolute;
  bottom: 10px;
}
@media (max-width: 1000px) {
  body.page--nosco-overview .block-5 .system-description .arrows {
    position: relative;
  }
}
body.page--nosco-overview .block-5 .system-description .persona {
  height: 200px;
}
body.page--nosco-overview .block-5 .system-description i {
  font-size: 30px;
}
body.page--nosco-overview .block-5 .system-image {
  background-color: #e8f2f1;
}
body.page--nosco-overview .block-5 .brochure {
  border-color: white;
  color: white;
  margin: auto;
}
body.page--nosco-overview .block-6 h2 {
  color: #009E8E;
}
body.page--nosco-overview .block-6 h1,
body.page--nosco-overview .block-6 h3,
body.page--nosco-overview .block-6 h4,
body.page--nosco-overview .block-6 h5,
body.page--nosco-overview .block-6 h6,
body.page--nosco-overview .block-6 p {
  color: white;
}
body.page--nosco-overview .block-6 .persona .picture {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
}
body.page--nosco-overview .block-6 .persona .picture img {
  width: 100%;
}
body.page--nosco-overview .block-6 .persona .body {
  padding: 25px;
}
body.page--nosco-overview .block-6 .persona .body h3 {
  font-size: 28px;
  margin-bottom: 0;
}
body.page--nosco-overview .block-6 .persona .body h4 {
  font-size: 15px;
  line-height: 24px;
  height: 24px;
}
body.page--nosco-overview .block-6 .persona .body .btn {
  border-radius: 0;
}
body.page--nosco-overview .block-6 .persona .body.yellow-bg {
  background-color: #e39c45 !important;
}
body.page--nosco-overview .block-6 .persona .body.green-bg {
  background-color: #19a191;
}
body.page--nosco-overview .block-6 .persona .body.orange-bg {
  background-color: #d45438;
}
body.page--nosco-overview .block-6 .persona .body.blue-bg {
  background-color: #3b445a;
}
body.page--nosco-overview .block-6 .brochure {
  color: #009E8E;
}
body.page--nosco-overview .block-persona {
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: none;
  position: relative;
}
body.page--nosco-overview .block-persona .close-persona {
  color: #bbbbbb;
  font-size: 24px;
  cursor: pointer;
}
body.page--nosco-overview .block-persona .close-persona:hover {
  color: #ffffff;
}
body.page--nosco-overview .block-persona h4 {
  color: #ffffff;
  font-size: 30px;
}
body.page--nosco-overview .block-persona p {
  color: #ffffff;
  font-weight: 200;
}

body.page--nosco-overview#nosco-home .block-3 .btn:hover {
  background-color: #eaf0ef;
}
body.page--nosco-overview#nosco-home .block-6 .btn:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}

body.leanbit.bulloni_digitali footer {
  background-color: white;
  color: gray;
  font-size: 0.5em;
  margin-top: 3rem;
}
body.leanbit.bulloni_digitali .more {
  font-family: cursive;
  color: #9179EF;
}
body.leanbit.bulloni_digitali h1.h1 {
  margin-top: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: #9179EF;
  font-family: "KarmaticArcade", magrai;
}
body.leanbit.bulloni_digitali header.header #navBar .navbar-body {
  margin-left: auto;
  margin-right: 0;
}
body.leanbit.bulloni_digitali header.header .navbar .nav-link {
  color: #9179EF;
}
body.leanbit.bulloni_digitali .navbar-brand, body.leanbit.bulloni_digitali .navbar-brand > img {
  width: 15rem;
}
body.leanbit.bulloni_digitali main#content {
  min-height: 460px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element {
  overflow: unset;
  background: #fff;
  border-bottom: none;
  margin-bottom: 15px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__avatar {
  grid-area: avatar;
  margin-top: 10px;
  width: 36px;
  height: 36px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20%;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__name {
  grid-area: name;
  white-space: nowrap;
  margin-top: 10px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__text {
  grid-area: text;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__time {
  grid-area: time;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: hsl(168deg, 0%, 46%);
  font-size: 13px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__time .time__wrapper {
  width: max-content;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions {
  grid-area: actions;
  position: relative;
  float: none;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn {
  height: 32px;
  width: 32px;
  max-width: 32px;
  border-radius: 1000px;
  background: transparent;
  color: hsl(0deg, 0%, 38%);
  border: none;
  padding: 0;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:hover, body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:focus {
  background: rgba(212, 212, 212, 0.5);
  color: hsl(0deg, 0%, 38%);
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:hover.btn-danger, body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:focus.btn-danger {
  background: rgba(222, 68, 55, 0.2);
  color: #de4437;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:hover.btn-warning, body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:focus.btn-warning {
  background: rgba(245, 202, 153, 0.2);
  color: #f5ca99;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:hover.btn-info, body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:focus.btn-info {
  background: rgba(9, 165, 190, 0.2);
  color: #09a5be;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:hover.btn-success, body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:focus.btn-success {
  background: rgba(0, 201, 167, 0.2);
  color: #00c9a7;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:hover.btn-primary, body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn:focus.btn-primary {
  background: rgba(0, 158, 142, 0.2);
  color: #009E8E;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions .btn.btn--no-max-width {
  width: unset;
  max-width: unset;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__actions i::before {
  color: hsl(168deg, 0%, 71%);
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element.chat__element--editing .element__actions {
  display: none;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element.chat__element--editing .element__text {
  display: none;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element.chat__element--editing .element__form {
  grid-area: text;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element.chat__element--editing .element__form form {
  margin-bottom: 10px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element.chat__element--editing .element__form .form-group {
  margin-bottom: 0px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element + .chat__element {
  border-top: 1px solid hsl(168deg, 0%, 89%);
  margin-top: 0px;
  padding-top: 10px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element .element__text p:last-child {
  margin-bottom: 0px;
}
body.leanbit.bulloni_digitali .chat--gigi-mario .chat__element {
  display: grid;
  grid-template-areas: "avatar name time actions" "avatar text text text";
  grid-template-columns: max-content min-content 1fr max-content;
  grid-gap: 0px 10px;
  position: relative;
}

body.landing #blog-posts .card-img-top {
  height: 215px;
  object-fit: cover;
}

body.landing .screen {
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
  box-shadow: 0px 6px 14px -5px hsla(169deg, 100%, 11%, 0.22), 0 0 3px hsla(169deg, 100%, 11%, 0.22);
}

body.landing #engine-title {
  display: flex;
  align-items: center;
}
body.landing #engine-title #engine-title__logo img {
  width: 32px;
  height: 32px;
}
body.landing #engine-title #engine-title__name {
  color: #fff;
}
body.landing #engine-title #engine-title__logo + #engine-title__name {
  font-size: 18px;
  margin-left: 10px;
}

body.landing #engine-logo--big {
  filter: drop-shadow(0px 6px 18px hsla(169deg, 100%, 11%, 0.22));
}
body.landing #engine-logo--big img {
  max-height: 240px;
}

body.landing .hero-container {
  background-image: url("../images/landings/hexagon_15deg.svg");
  background-repeat: no-repeat;
  background-position: 65% bottom;
  background-size: 400%;
}

body.landing #hero h1,
body.landing #hero p.lead {
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 85, 72, 0.3);
}

.fonts-loaded--titillium body.landing .block .btn.btn--cta {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.landing .btn {
  border-radius: 10px;
}
body.landing .btn.btn--cta {
  font-weight: bold;
  text-transform: uppercase;
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body.landing .btn.btn--cta.btn--special {
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
}
body.landing .btn.btn--cta.btn--special:hover {
  box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
}

.fonts-loaded--titillium body.landing p {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.landing h1 {
  font-size: 46px;
}
body.landing p.lead {
  font-size: 24px;
}
body.landing p.lead.lead--subtitle {
  color: #929292;
  font-weight: bolder;
}
body.landing p {
  font-size: 18px;
}
body.landing p {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body.landing h2 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 10px;
}

@media (max-width: 767.98px) {
  body.landing #engine-title {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
@media (min-width: 576px) {
  body.landing #hero p.lead span {
    /* NOTE: this makes text inside span tags
             go to new line */
    display: block;
  }
}
body.landing .cta-section {
  background: whitesmoke;
}

body.landing .cta-section span.new-line--md-only {
  /* NOTE: this makes text inside span tags
           go to new line */
  display: inline;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.landing .cta-section span.new-line--md-only {
    /* NOTE: this makes text inside span tags
             go to new line */
    display: block;
  }
}
@media (min-width: 768px) {
  body.landing #engine-title {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  body.landing .cta-section span {
    /* NOTE: this makes text inside span tags
             go to new line */
    display: block;
  }
}
@media (min-width: 1200px) {
  body.landing #hero > [class*=col-]:first-child {
    max-width: 600px;
  }
  body.landing #hero > [class*=col-]:nth-child(2) {
    max-width: 1200px;
  }
}
.fonts-loaded--brix-sans body.leanbit--solution h1,
.fonts-loaded--brix-sans body.leanbit--solution h2,
.fonts-loaded--brix-sans body.leanbit--solution h3,
.fonts-loaded--brix-sans body.leanbit--solution h4,
.fonts-loaded--brix-sans body.leanbit--solution h5,
.fonts-loaded--brix-sans body.leanbit--solution h6,
.fonts-loaded--brix-sans body.leanbit--solution .h1,
.fonts-loaded--brix-sans body.leanbit--solution .h2,
.fonts-loaded--brix-sans body.leanbit--solution .h3,
.fonts-loaded--brix-sans body.leanbit--solution .h4,
.fonts-loaded--brix-sans body.leanbit--solution .h5,
.fonts-loaded--brix-sans body.leanbit--solution .h6 {
  font-family: "BrixSans", sans-serif;
}

.fonts-loaded--titillium body.leanbit--solution p {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}
.fonts-loaded--titillium body.leanbit--solution .block.cta-section .btn.btn--cta {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body.leanbit--solution #blog-posts .card-img-top {
  height: 215px;
  object-fit: cover;
}

body.leanbit--solution {
  background: hsl(168deg, 0%, 93%);
}

body.leanbit--solution #hero {
  background: #fff;
  position: relative;
  z-index: 1;
  min-width: 95vw;
  box-shadow: 0 15px 36px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991.98px) {
  body.leanbit--solution #hero {
    margin-right: auto;
    min-width: auto;
  }
}
body.leanbit--solution #hero p.lead {
  font-size: 20px;
}
body.leanbit--solution #hero #hero__image-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  body.leanbit--solution #hero #hero__image-wrapper img {
    margin-top: 20px;
  }
}
body.leanbit--solution #hero #hero__image-wrapper svg {
  height: 260px;
}
body.leanbit--solution #hero #hero__image-wrapper svg path {
  fill: #009E8E;
}

body.leanbit--solution #subhero p {
  font-size: 26px;
}

body.leanbit--solution h2 {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 10px;
}
body.leanbit--solution h1 {
  color: #009E8E;
  font-size: 38px;
}
body.leanbit--solution p.lead {
  font-size: 24px;
}
body.leanbit--solution p.lead.lead--subtitle {
  color: #929292;
  font-weight: bolder;
}
body.leanbit--solution p {
  font-size: 18px;
}
body.leanbit--solution p strong {
  font-weight: bold;
  color: #009E8E;
}
body.leanbit--solution p {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body.leanbit--solution p.quote {
  padding: 15px 12px;
  border-left: 12px solid hsl(168deg, 0%, 89%);
  font-style: italic;
  font-size: 20px;
}
body.leanbit--solution #problems p.quote {
  border-color: #595577;
}
body.leanbit--solution #answers p.quote {
  font-style: normal;
  font-weight: 600;
  color: #009E8E;
  border-color: #009E8E;
}

body.leanbit--solution .block--raised {
  position: relative;
  z-index: 1;
}
body.leanbit--solution .block--beneath {
  position: relative;
  z-index: 0;
}

body.leanbit--solution #numbers .card-header h4 {
  font-size: 58px;
  font-weight: 600;
}
body.leanbit--solution #business-critical, body.leanbit--solution .container--right-angled-band {
  background-image: linear-gradient(-60deg, #009e8e 50%, transparent 0%);
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: right top;
}
@media (max-width: 1199.98px) {
  body.leanbit--solution #business-critical, body.leanbit--solution .container--right-angled-band {
    background-size: 50%;
  }
}
@media (max-width: 991.98px) {
  body.leanbit--solution #business-critical, body.leanbit--solution .container--right-angled-band {
    background-image: none;
  }
}

body.leanbit--solution #problems > .row {
  background-image: linear-gradient(90deg, #595577 15%, transparent 0%);
}
@media (max-width: 575.98px) {
  body.leanbit--solution #problems > .row {
    background-image: none;
  }
}

body.leanbit--solution #answers > .row {
  background-image: linear-gradient(90deg, transparent 85%, #009E8E 0%);
}
@media (max-width: 575.98px) {
  body.leanbit--solution #answers > .row {
    background-image: none;
  }
}

body.leanbit--solution #digital-tools {
  background-image: linear-gradient(60deg, #009e8e 30%, transparent 0%);
}
@media (max-width: 991.98px) {
  body.leanbit--solution #digital-tools {
    background-image: none;
  }
}

body.leanbit--solution .isometric-grid-bg {
  background: radial-gradient(ellipse at center, rgba(0, 158, 142, 0) 0%, white 80%), repeating-linear-gradient(150deg, #e6efee, #e6efee 1px, transparent 2px, transparent 80px) 0 0, repeating-linear-gradient(30deg, #e6efee, #e6efee 1px, transparent 2px, transparent 80px) 0 0;
}

body.leanbit--solution .block.cta-section {
  background: none;
}
body.leanbit--solution .block.cta-section .container > div {
  width: 100% !important;
}
body.leanbit--solution .block.cta-section .btn {
  border-radius: 10px;
}
body.leanbit--solution .block.cta-section .btn.btn--cta {
  font-weight: bold;
  text-transform: uppercase;
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body.leanbit--solution .block.cta-section .btn.btn--cta.btn--special {
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
}
body.leanbit--solution .block.cta-section .btn.btn--cta.btn--special:hover {
  box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  body.page--customer-service #hero {
    margin-right: auto;
    min-width: auto;
  }
}
body.page--customer-service #hero #hero__image-wrapper img {
  height: 260px;
  transform: scale(2);
}
@media (max-width: 991.98px) {
  body.page--customer-service #hero #hero__image-wrapper img {
    transform: scale(1.3);
  }
}

body.page--effective-communication #right-information {
  background-image: linear-gradient(-80deg, #009e8e 50%, transparent 0%);
  background-size: 60%;
}
@media (max-width: 991.98px) {
  body.page--effective-communication #right-information {
    background-image: none;
  }
}

@media (max-width: 991.98px) {
  body.page--smart-working #hero {
    margin-right: auto;
    min-width: auto;
  }
}
body.page--smart-working #hero #hero__image-wrapper img {
  height: 260px;
  transform: scale(2);
}
@media (max-width: 991.98px) {
  body.page--smart-working #hero #hero__image-wrapper img {
    transform: scale(1.5);
  }
}

.fonts-loaded--titillium body#nosco-landing h2,
.fonts-loaded--titillium body#nosco-landing p,
.fonts-loaded--titillium body#nosco-landing .btn {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body#nosco-landing .block {
  padding-top: 60px;
  padding-bottom: 60px;
}
body#nosco-landing .btn {
  border-radius: 8px;
}
body#nosco-landing .btn.btn-warning {
  color: #806a6a;
}
body#nosco-landing h2,
body#nosco-landing p,
body#nosco-landing .btn {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body#nosco-landing p {
  color: #555555;
}
body#nosco-landing h1 {
  font-size: min(8vw, 6vh);
}
body#nosco-landing h1,
body#nosco-landing h2,
body#nosco-landing h3,
body#nosco-landing h4,
body#nosco-landing h5,
body#nosco-landing h6,
body#nosco-landing p {
  font-weight: 400;
}
body#nosco-landing h1 {
  line-height: 1.2;
}
body#nosco-landing h2 {
  font-weight: 400;
}
body#nosco-landing .hero__title {
  margin-top: 40px;
  margin-bottom: 30px;
}
body#nosco-landing .hero__subtitle {
  margin-bottom: 20px;
}
body#nosco-landing .hero__logo-wrapper {
  display: inline-flex;
  height: 35px;
  padding: 6px 0px;
  margin-right: 2px;
  border-radius: 100px;
  vertical-align: -4px;
}
body#nosco-landing .hero__logo-wrapper img {
  height: 100%;
}
body#nosco-landing .row.row--edge-to-edge {
  position: relative;
}
body#nosco-landing .js-slick-carousel .js-slide .card {
  min-height: 270px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 25px 20px rgba(75, 75, 75, 0.2);
  background: #fff;
  border: 1px solid #efefe0;
}
body#nosco-landing .js-slick-carousel .js-slide .card h3 {
  color: hsl(174deg, 100%, 25%);
  font-size: 28px;
}
body#nosco-landing .js-slick-carousel .js-slide .card h4 {
  color: hsl(174deg, 100%, 25%);
}
body#nosco-landing .js-slick-carousel .js-slide .card b, body#nosco-landing .js-slick-carousel .js-slide .card li {
  font-size: 18px;
  font-weight: 400;
  color: #555555;
}
body#nosco-landing .js-slick-carousel .js-slick-carousel__background {
  opacity: 0;
}

body#nosco-landing .navbar-toggler {
  margin-left: 10px;
}

body#nosco-landing .screen {
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
  box-shadow: 0px 6px 14px -5px hsla(169deg, 100%, 11%, 0.22), 0 0 3px hsla(169deg, 100%, 11%, 0.22);
}

body#nosco-landing .btn.btn--special {
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
}
body#nosco-landing .btn.btn--special:hover {
  box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
}

.fonts-loaded--titillium body#nosco-landing .block-1 .benefits {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body#nosco-landing .block-1 {
  background-position: center 32px;
  background-size: 150vw 100vw;
  background-image: url("../images/wallpapers/hexagon.png");
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}
body#nosco-landing .block-1::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, hsla(218deg, 96%, 9%, 0.65), transparent);
}
body#nosco-landing .block-1 .container {
  position: relative;
  z-index: 1;
}
body#nosco-landing .block-1 .text-shadow {
  text-shadow: 1px 1px 1px rgb(0, 84, 69);
}
body#nosco-landing .block-1 .benefit__icon {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 14px -5px hsl(169deg, 100%, 11%);
  margin-bottom: 25px;
  position: relative;
}
body#nosco-landing .block-1 .benefit__icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 14%;
  left: 14%;
  width: 72%;
  height: 22%;
  background: linear-gradient(0, rgba(255, 255, 255, 0.3), transparent);
  border-radius: 0 0 25px 25px;
}
body#nosco-landing .block-1 .benefit__icon img,
body#nosco-landing .block-1 .benefit__icon svg {
  width: 80%;
  height: 80%;
  padding: 18px;
  border-radius: 8px;
  background: linear-gradient(0deg, hsl(0deg, 0%, 92%), hsl(0deg, 0%, 98%));
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.168627451);
  margin: 0;
}
body#nosco-landing .block-1 .benefit__icon svg #accent-color * {
  fill: hsl(170deg, 60%, 69%);
}
body#nosco-landing .block-1 .benefit__icon svg #base-color * {
  fill: hsl(168deg, 0%, 31%);
}
body#nosco-landing .block-1 .hero__action-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  width: 100%;
  z-index: 1;
}
body#nosco-landing .block-1 .hero__action-wrapper .hero__action {
  border-radius: 8px;
  padding: 20px 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 18px rgba(255, 155, 41, 0.45);
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  opacity: 1;
}
body#nosco-landing .block-1 .hero__action-wrapper .hero__action:hover {
  background: linear-gradient(45deg, hsl(49deg, 100%, 94%), white);
  box-shadow: 0 4px 28px rgba(255, 155, 41, 0.45);
  color: orange;
}
body#nosco-landing .block-1 .hero__action-wrapper .hero__action + .hero__action {
  margin-left: 30px;
}
body#nosco-landing .block-1 .benefits {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body#nosco-landing .block-1 .benefit {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}
body#nosco-landing .block-1 .benefit span {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

body#nosco-landing #san-marco-case-block .figure {
  display: flex;
  align-items: center;
  justify-content: center;
}
body#nosco-landing #san-marco-case-block .figure img {
  border-radius: 10px;
  filter: opacity(0.9) sepia(0);
  box-shadow: 0px 6px 34px -5px hsla(0deg, 0%, 59%, 0.62);
  max-height: 60vh;
  width: 80%;
  object-fit: cover;
  object-position: bottom;
  border: 4px solid #009E8E;
}

body#nosco-landing .block-2 h1,
body#nosco-landing .block-2 h2,
body#nosco-landing .block-2 h3,
body#nosco-landing .block-2 h4,
body#nosco-landing .block-2 h5,
body#nosco-landing .block-2 h6,
body#nosco-landing .block-3 h1,
body#nosco-landing .block-3 h2,
body#nosco-landing .block-3 h3,
body#nosco-landing .block-3 h4,
body#nosco-landing .block-3 h5,
body#nosco-landing .block-3 h6 {
  color: #009E8E;
}
body#nosco-landing .block-2 h2,
body#nosco-landing .block-2 i,
body#nosco-landing .block-3 h2,
body#nosco-landing .block-3 i {
  font-size: 30px;
}
body#nosco-landing .block-2 h2,
body#nosco-landing .block-3 h2 {
  color: hsl(174deg, 100%, 25%);
}
body#nosco-landing .block-2 h2.slogan,
body#nosco-landing .block-3 h2.slogan {
  text-align: center;
  font-size: 34px;
}
body#nosco-landing .block-2 p, body#nosco-landing .block-2 td,
body#nosco-landing .block-3 p,
body#nosco-landing .block-3 td {
  font-size: 18px;
}
body#nosco-landing .block-2 strong,
body#nosco-landing .block-3 strong {
  color: hsl(174deg, 100%, 25%);
}
body#nosco-landing .block-3 {
  background-color: #f5f9f9;
}

@media (min-width: 1199px) {
  body#nosco-landing .block-1 h1 {
    font-size: 60px;
  }
  body#nosco-landing .block-1 h2 {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  body#nosco-landing .hero {
    padding-bottom: 70px;
  }
  body#nosco-landing .hero .hero__title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  body#nosco-landing .hero .hero__subtitle {
    font-size: 26px;
    margin-bottom: 0;
  }
  body#nosco-landing .hero .benefit__icon {
    height: 120px;
    width: 120px;
  }
  body#nosco-landing .hero .benefit__icon svg {
    height: 90%;
    width: 90%;
  }
  body#nosco-landing .js-slick-carousel {
    display: flex;
    justify-content: space-between;
  }
  body#nosco-landing .js-slick-carousel .js-slide {
    width: 30%;
    font-size: 14px;
    height: 100%;
  }
  body#nosco-landing .js-slick-carousel .js-slide .card {
    height: 100%;
  }
  body#nosco-landing .js-slick-carousel .js-slide .card h3 {
    font-weight: 600;
  }
}
@media (max-width: 991px) {
  body#nosco-landing .block-1 h1 {
    font-size: 30px;
  }
  body#nosco-landing .block-1 h2 {
    font-size: 30px;
  }
  body#nosco-landing .block-1 {
    background-size: 150vh 100vh;
    padding-bottom: 30px;
  }
  body#nosco-landing .block-1 .benefit {
    height: 200px;
  }
  body#nosco-landing .block-1 .hero__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  body#nosco-landing .block-1 .hero__subtitle {
    margin-bottom: 0;
    font-size: 20px;
  }
  body#nosco-landing .block-1 .hero__logo-wrapper {
    height: 30px;
    vertical-align: -3px;
  }
  body#nosco-landing .block-1 .benefit {
    font-size: 18px;
    font-weight: 600;
    height: auto;
    margin-bottom: 20px;
  }
  body#nosco-landing .block-1 .benefit .benefit__icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  body#nosco-landing .block-1 .benefit .benefit__icon img,
body#nosco-landing .block-1 .benefit .benefit__icon svg {
    width: 90%;
    height: 90%;
    padding: 12px;
    border-radius: 8px;
    background: linear-gradient(0deg, hsl(0deg, 0%, 92%), hsl(0deg, 0%, 98%));
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.168627451);
  }
  body#nosco-landing .block-1 .benefit .benefit__icon::after {
    display: block;
    content: "";
    position: absolute;
    top: 14%;
    left: 14%;
    width: 72%;
    height: 22%;
    background: linear-gradient(0, rgba(255, 255, 255, 0.3), transparent);
    border-radius: 0 0 25px 25px;
  }
  body#nosco-landing .block-1 .benefit span strong {
    font-size: 18px;
    padding-bottom: 4px;
  }
  body#nosco-landing .block-2 p,
body#nosco-landing .block-3 p {
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body#nosco-landing .block-1 h1 {
    font-size: 40px;
  }
  body#nosco-landing .block-1 h2 {
    font-size: 30px;
  }
  body#nosco-landing .block-1 .hero__subtitle {
    margin-bottom: 0;
    font-size: 16px;
  }
  body#nosco-landing .block-1 .hero__logo-wrapper {
    height: 26px;
    vertical-align: -2px;
  }
  body#nosco-landing .block-1 .benefit {
    font-size: 12px;
  }
}
@media (min-width: 576px) {
  body#nosco-landing .hero span {
    /* NOTE: this makes text inside span tags
              go to new line */
    display: block;
  }
  body#nosco-landing .hero .hero__title,
body#nosco-landing .hero .hero__subtitle {
    text-align: center;
  }
  body#nosco-landing .hero .hero__subtitle {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  body#nosco-landing .container,
body#nosco-landing .container-sm {
    max-width: min(100%, 620px);
  }
}
@media (max-width: 576px) {
  body#nosco-landing .block-1 .benefits {
    margin-bottom: 60px;
  }
  body#nosco-landing .block-1 .benefits .benefit {
    font-size: 18px;
    margin-bottom: 30px;
  }
  body#nosco-landing .block-1 .benefits .benefit strong {
    font-size: 24px;
  }
  body#nosco-landing .block-1 .hero__action-wrapper {
    flex-direction: column;
    margin-bottom: 20px;
  }
  body#nosco-landing .block-1 .hero__action-wrapper .hero__action {
    min-width: 307px;
    margin-top: 10px;
  }
  body#nosco-landing .block-1 .hero__action-wrapper .hero__action + .hero__action {
    margin-left: 0px;
  }
}
body#nosco-home #thank-you-block h1,
body#nosco-home #thank-you-block h2,
body#nosco-home #thank-you-block h3,
body#nosco-home #thank-you-block h4,
body#nosco-home #thank-you-block h5,
body#nosco-home #thank-you-block h6,
body#nosco-home #thank-you-block p,
body#nosco-home #thank-you-block i {
  color: #009E8E;
}
body#nosco-home #thank-you-block h1 {
  font-weight: 400;
  color: #009e8e;
}
body#nosco-home #thank-you-block p.lead {
  color: #555555;
  line-height: 1.2;
  font-size: 18px;
}
body#nosco-home #thank-you-block p.lead.justify {
  text-align: justify;
}
body#nosco-home #thank-you-block #latest-posts .card .card-img-top {
  height: 150px;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  body#nosco-home #thank-you-block #latest-posts .card {
    display: flex;
    flex-direction: row;
  }
  body#nosco-home #thank-you-block #latest-posts .card .card-img-top {
    height: 100%;
    width: 210px;
    border-radius: 0.25rem 0 0 0.25rem;
  }
}

@media (max-width: 767.98px) {
  body#nosco-landing footer .links {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  body#nosco-landing footer .links {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
.fonts-loaded--titillium body#nosco-pricing h2,
.fonts-loaded--titillium body#nosco-pricing p,
.fonts-loaded--titillium body#nosco-pricing .btn {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body#nosco-pricing .block {
  padding-top: 60px;
  padding-bottom: 60px;
}
body#nosco-pricing .btn {
  border-radius: 8px;
}
body#nosco-pricing .btn.btn-warning {
  color: #806a6a;
}
body#nosco-pricing h2,
body#nosco-pricing p,
body#nosco-pricing .btn {
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body#nosco-pricing p {
  color: #555555;
}
body#nosco-pricing h1 {
  font-size: min(8vw, 6vh);
}
body#nosco-pricing h1,
body#nosco-pricing h2,
body#nosco-pricing h3,
body#nosco-pricing h4,
body#nosco-pricing h5,
body#nosco-pricing h6,
body#nosco-pricing p {
  font-weight: 400;
}
body#nosco-pricing h1 {
  line-height: 1.2;
}
body#nosco-pricing h2 {
  font-weight: 400;
}
body#nosco-pricing .hero__title {
  margin-top: 40px;
  margin-bottom: 30px;
}
body#nosco-pricing .hero__subtitle {
  margin-bottom: 20px;
}
body#nosco-pricing .hero__logo-wrapper {
  display: inline-flex;
  height: 35px;
  padding: 6px 0px;
  margin-right: 2px;
  border-radius: 100px;
  vertical-align: -4px;
}
body#nosco-pricing .hero__logo-wrapper img {
  height: 100%;
}
body#nosco-pricing .row.row--edge-to-edge {
  position: relative;
}
body#nosco-pricing .js-slick-carousel .js-slide .card {
  min-height: 270px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 25px 20px rgba(75, 75, 75, 0.2);
  background: #fff;
  border: 1px solid #efefe0;
}
body#nosco-pricing .js-slick-carousel .js-slide .card h3 {
  color: hsl(174deg, 100%, 25%);
  font-size: 28px;
}
body#nosco-pricing .js-slick-carousel .js-slide .card h4 {
  color: hsl(174deg, 100%, 25%);
}
body#nosco-pricing .js-slick-carousel .js-slide .card b, body#nosco-pricing .js-slick-carousel .js-slide .card li {
  font-size: 18px;
  font-weight: 200;
  color: #555555;
}
body#nosco-pricing .js-slick-carousel .js-slick-carousel__background {
  opacity: 0;
}

body#nosco-pricing .navbar-toggler {
  margin-left: 10px;
}

body#nosco-pricing .screen {
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
  box-shadow: 0px 6px 14px -5px hsla(169deg, 100%, 11%, 0.22), 0 0 3px hsla(169deg, 100%, 11%, 0.22);
}

body#nosco-pricing .btn.btn--special {
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
  border: none;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  color: #fff;
}
body#nosco-pricing .btn.btn--special:hover {
  box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
}

.fonts-loaded--titillium body#nosco-pricing .block-1 .benefits {
  font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
}

body#nosco-pricing .block-1 {
  background-position: center 32px;
  background-size: 150vw 100vw;
  background-image: url("../images/wallpapers/hexagon.png");
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}
body#nosco-pricing .block-1::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, hsla(218deg, 96%, 9%, 0.65), transparent);
}
body#nosco-pricing .block-1 .container {
  position: relative;
  z-index: 1;
}
body#nosco-pricing .block-1 .text-shadow {
  text-shadow: 1px 1px 1px rgb(0, 84, 69);
}
body#nosco-pricing .block-1 .benefit__icon {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 14px -5px hsl(169deg, 100%, 11%);
  margin-bottom: 25px;
  position: relative;
}
body#nosco-pricing .block-1 .benefit__icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 14%;
  left: 14%;
  width: 72%;
  height: 22%;
  background: linear-gradient(0, rgba(255, 255, 255, 0.3), transparent);
  border-radius: 0 0 25px 25px;
}
body#nosco-pricing .block-1 .benefit__icon img,
body#nosco-pricing .block-1 .benefit__icon svg {
  width: 80%;
  height: 80%;
  padding: 18px;
  border-radius: 8px;
  background: linear-gradient(0deg, hsl(0deg, 0%, 92%), hsl(0deg, 0%, 98%));
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.168627451);
  margin: 0;
}
body#nosco-pricing .block-1 .benefit__icon svg #accent-color * {
  fill: hsl(170deg, 60%, 69%);
}
body#nosco-pricing .block-1 .benefit__icon svg #base-color * {
  fill: hsl(168deg, 0%, 31%);
}
body#nosco-pricing .block-1 .hero__action-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  width: 100%;
  z-index: 1;
}
body#nosco-pricing .block-1 .hero__action-wrapper .hero__action {
  border-radius: 8px;
  padding: 20px 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: linear-gradient(45deg, #ec8f23, #ffc646);
  box-shadow: 0 4px 18px rgba(255, 155, 41, 0.45);
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
  opacity: 1;
}
body#nosco-pricing .block-1 .hero__action-wrapper .hero__action:hover {
  background: linear-gradient(45deg, hsl(49deg, 100%, 94%), white);
  box-shadow: 0 4px 28px rgba(255, 155, 41, 0.45);
  color: orange;
}
body#nosco-pricing .block-1 .hero__action-wrapper .hero__action + .hero__action {
  margin-left: 30px;
}
body#nosco-pricing .block-1 .benefits {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body#nosco-pricing .block-1 .benefit {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}
body#nosco-pricing .block-1 .benefit span {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

body#nosco-pricing #san-marco-case-block .figure {
  display: flex;
  align-items: center;
  justify-content: center;
}
body#nosco-pricing #san-marco-case-block .figure img {
  border-radius: 10px;
  filter: opacity(0.9) sepia(0);
  box-shadow: 0px 6px 34px -5px hsla(0deg, 0%, 59%, 0.62);
  max-height: 60vh;
  width: 80%;
  object-fit: cover;
  object-position: bottom;
  border: 4px solid #009E8E;
}

body#nosco-pricing .block-2 h1,
body#nosco-pricing .block-2 h2,
body#nosco-pricing .block-2 h3,
body#nosco-pricing .block-2 h4,
body#nosco-pricing .block-2 h5,
body#nosco-pricing .block-2 h6,
body#nosco-pricing .block-3 h1,
body#nosco-pricing .block-3 h2,
body#nosco-pricing .block-3 h3,
body#nosco-pricing .block-3 h4,
body#nosco-pricing .block-3 h5,
body#nosco-pricing .block-3 h6 {
  color: #009E8E;
}
body#nosco-pricing .block-2 h2,
body#nosco-pricing .block-2 i,
body#nosco-pricing .block-3 h2,
body#nosco-pricing .block-3 i {
  font-size: 30px;
}
body#nosco-pricing .block-2 h2,
body#nosco-pricing .block-3 h2 {
  color: hsl(174deg, 100%, 25%);
}
body#nosco-pricing .block-2 h2.slogan,
body#nosco-pricing .block-3 h2.slogan {
  text-align: center;
  font-size: 34px;
}
body#nosco-pricing .block-2 p, body#nosco-pricing .block-2 td,
body#nosco-pricing .block-3 p,
body#nosco-pricing .block-3 td {
  font-size: 18px;
}
body#nosco-pricing .block-2 strong,
body#nosco-pricing .block-3 strong {
  color: hsl(174deg, 100%, 25%);
}
body#nosco-pricing .block-3 {
  background-color: #f5f9f9;
}

@media (min-width: 1199px) {
  body#nosco-pricing .block-1 h1 {
    font-size: 60px;
  }
  body#nosco-pricing .block-1 h2 {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  body#nosco-pricing .hero {
    padding-bottom: 70px;
  }
  body#nosco-pricing .hero .hero__title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  body#nosco-pricing .hero .hero__subtitle {
    font-size: 26px;
    margin-bottom: 0;
  }
  body#nosco-pricing .hero .benefit__icon {
    height: 120px;
    width: 120px;
  }
  body#nosco-pricing .hero .benefit__icon svg {
    height: 90%;
    width: 90%;
  }
  body#nosco-pricing .js-slick-carousel {
    display: flex;
    justify-content: space-between;
  }
  body#nosco-pricing .js-slick-carousel .js-slide {
    width: 50%;
    font-size: 14px;
    height: 100%;
  }
  body#nosco-pricing .js-slick-carousel .js-slide .card {
    height: 100%;
  }
  body#nosco-pricing .js-slick-carousel .js-slide .card h3 {
    font-weight: 600;
  }
}
@media (max-width: 991px) {
  body#nosco-pricing .block-1 h1 {
    font-size: 30px;
  }
  body#nosco-pricing .block-1 h2 {
    font-size: 30px;
  }
  body#nosco-pricing .block-1 {
    background-size: 150vh 100vh;
    padding-bottom: 30px;
  }
  body#nosco-pricing .block-1 .benefit {
    height: 200px;
  }
  body#nosco-pricing .block-1 .hero__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  body#nosco-pricing .block-1 .hero__subtitle {
    margin-bottom: 0;
    font-size: 20px;
  }
  body#nosco-pricing .block-1 .hero__logo-wrapper {
    height: 30px;
    vertical-align: -3px;
  }
  body#nosco-pricing .block-1 .benefit {
    font-size: 18px;
    font-weight: 600;
    height: auto;
    margin-bottom: 20px;
  }
  body#nosco-pricing .block-1 .benefit .benefit__icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  body#nosco-pricing .block-1 .benefit .benefit__icon img,
body#nosco-pricing .block-1 .benefit .benefit__icon svg {
    width: 90%;
    height: 90%;
    padding: 12px;
    border-radius: 8px;
    background: linear-gradient(0deg, hsl(0deg, 0%, 92%), hsl(0deg, 0%, 98%));
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.168627451);
  }
  body#nosco-pricing .block-1 .benefit .benefit__icon::after {
    display: block;
    content: "";
    position: absolute;
    top: 14%;
    left: 14%;
    width: 72%;
    height: 22%;
    background: linear-gradient(0, rgba(255, 255, 255, 0.3), transparent);
    border-radius: 0 0 25px 25px;
  }
  body#nosco-pricing .block-1 .benefit span strong {
    font-size: 18px;
    padding-bottom: 4px;
  }
  body#nosco-pricing .block-2 p,
body#nosco-pricing .block-3 p {
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body#nosco-pricing .block-1 h1 {
    font-size: 40px;
  }
  body#nosco-pricing .block-1 h2 {
    font-size: 30px;
  }
  body#nosco-pricing .block-1 .hero__subtitle {
    margin-bottom: 0;
    font-size: 16px;
  }
  body#nosco-pricing .block-1 .hero__logo-wrapper {
    height: 26px;
    vertical-align: -2px;
  }
  body#nosco-pricing .block-1 .benefit {
    font-size: 12px;
  }
}
@media (min-width: 576px) {
  body#nosco-pricing .hero span {
    /* NOTE: this makes text inside span tags
              go to new line */
    display: block;
  }
  body#nosco-pricing .hero .hero__title,
body#nosco-pricing .hero .hero__subtitle {
    text-align: center;
  }
  body#nosco-pricing .hero .hero__subtitle {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  body#nosco-pricing .container,
body#nosco-pricing .container-sm {
    max-width: min(100%, 620px);
  }
}
@media (max-width: 576px) {
  body#nosco-pricing .block-1 .benefits {
    margin-bottom: 60px;
  }
  body#nosco-pricing .block-1 .benefits .benefit {
    font-size: 18px;
    margin-bottom: 30px;
  }
  body#nosco-pricing .block-1 .benefits .benefit strong {
    font-size: 24px;
  }
  body#nosco-pricing .block-1 .hero__action-wrapper {
    flex-direction: column;
    margin-bottom: 20px;
  }
  body#nosco-pricing .block-1 .hero__action-wrapper .hero__action {
    min-width: 307px;
    margin-top: 10px;
  }
  body#nosco-pricing .block-1 .hero__action-wrapper .hero__action + .hero__action {
    margin-left: 0px;
  }
}
body#nosco-home #thank-you-block h1,
body#nosco-home #thank-you-block h2,
body#nosco-home #thank-you-block h3,
body#nosco-home #thank-you-block h4,
body#nosco-home #thank-you-block h5,
body#nosco-home #thank-you-block h6,
body#nosco-home #thank-you-block p,
body#nosco-home #thank-you-block i {
  color: #009E8E;
}
body#nosco-home #thank-you-block h1 {
  font-weight: 400;
  color: #009e8e;
}
body#nosco-home #thank-you-block p.lead {
  color: #555555;
  line-height: 1.2;
  font-size: 18px;
}
body#nosco-home #thank-you-block p.lead.justify {
  text-align: justify;
}
body#nosco-home #thank-you-block #latest-posts .card .card-img-top {
  height: 150px;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  body#nosco-home #thank-you-block #latest-posts .card {
    display: flex;
    flex-direction: row;
  }
  body#nosco-home #thank-you-block #latest-posts .card .card-img-top {
    height: 100%;
    width: 210px;
    border-radius: 0.25rem 0 0 0.25rem;
  }
}

@media (max-width: 767.98px) {
  body#nosco-pricing footer .links {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  body#nosco-pricing footer .links {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
#contact_center #omnichannel-inbox-detail {
  background: linear-gradient(0deg, hsl(168deg, 0%, 93%), 30%, transparent);
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}
#contact_center #omnichannel-inbox-detail__image-wrapper {
  max-height: 80vh;
}
#contact_center #omnichannel-inbox-detail__image-wrapper img {
  height: 120vh;
  max-width: unset;
}

body.page--nosco-kitchen .hero-container {
  background-image: none;
  background: #272e3a;
  border-bottom: 20px solid #e7270c;
}
body.page--nosco-kitchen .btn--cta {
  border-radius: 2px;
}
body.page--nosco-kitchen #engine-title #engine-title__logo + #engine-title__name {
  font-size: 22px;
}
body.page--nosco-kitchen #engine-title__logo svg {
  height: 40px;
}
body.page--nosco-kitchen #hero-screen {
  border-radius: 10px;
}
body.page--nosco-kitchen .img--with-shadow {
  border-radius: 2px;
  box-shadow: 0px 25px 40px -25px;
}

/*---- MOBILE ----*/
@media (max-width: 767.98px) {
  body.page--nosco-kitchen .hero-container h1 {
    font-size: 36px;
  }
}
/*---- END MOBILE ----*/
body.page--workforce #omnichannel-inbox-detail {
  background: linear-gradient(0deg, hsl(168deg, 0%, 93%), 30%, transparent);
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}
body.page--workforce #omnichannel-inbox-detail__image-wrapper {
  max-height: 80vh;
}
body.page--workforce #omnichannel-inbox-detail__image-wrapper img {
  height: 80vh;
  max-width: unset;
}
body.page--workforce #worklog-reminder-wrapper {
  background: #d9efec;
  border-radius: 10px;
}
body.page--workforce #worklog-reminder-wrapper img {
  box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.1);
}

body#iot .label--titleish {
  font-size: 20px;
  background: #009E8E;
  width: max-content;
  padding: 5px 15px;
  border-radius: 0px 0px 10px 10px;
}
body#iot .label--titleish strong {
  color: #fff;
}

body#iot #hero__image-wrapper {
  position: relative;
}
body#iot #hero__image-wrapper #dashboard-charts-screen {
  width: 90%;
}
body#iot #hero__image-wrapper #softphone-screen {
  position: absolute;
  top: 60%;
  left: 35%;
  width: 60%;
}

@media (max-width: 767.98px) {
  body#iot .label--titleish {
    margin-top: 10px;
    border-radius: 10px;
  }
}
@media (min-width: 768px) {
  body#iot .label--titleish {
    margin-top: -17px;
  }
}
.product-keywords-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.product-keywords-list .product-keyword + .product-keyword {
  display: flex;
  margin-left: 10px;
}
.product-keywords-list .product-keyword + .product-keyword::before {
  content: "/";
  display: flex;
  margin-right: 10px;
}