body.leanbit--new {
  .block.cta-section {
    background: none;

    .container > div {
      width: 100% !important;
    }

    .btn {
      border-radius: 10px;
    }

    .btn.btn--cta {
      font-weight: bold;
      text-transform: uppercase;
      font-family: $default-font-family;
      font-size: 1rem;

      &.btn--special {
        background: linear-gradient(45deg, #ec8f23, #ffc646);
        box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
        border: none;
        transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
        color: $white;

        &:hover {
          box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
