.fonts-loaded--titillium {
  body.leanbit--new.page--internal {
    h1 {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body.leanbit--new.page--internal {
  .hero-like {
    background-image: url("../images/leanbit_company/azienda_bg.png");
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;

    h1 {
      @extend %great-heading;
      font-size: 72px;
      margin-bottom: 0px;
    }
  }

  p.lead.lead--important {
    color: $primary;
    font-size: 32px;
    font-weight: 600;
  }
}

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.leanbit--new.page--internal {
    .hero-like {
      background-size: 200%;

      h1 {
        font-size: 60px;
      }


      p.lead {
        letter-spacing: 0.2px;
      }
    }

    p.lead.lead--important {
      font-size: 26px;
      text-align: left;
    }
  }
}
