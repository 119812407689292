.bg-tertiary {
  background-color: $tertiary !important;
}

p{
  strong {
    font-weight: bold;
    color: $primary-darker;
  }
}


.btn-primary {
  background-color: $primary-darker !important;
}
