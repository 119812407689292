@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @content;
  }
}

@mixin btn-circle($size: 32px) {
  .btn {
    height: $size;
    width: $size;
    max-width: $size;
    border-radius: 1000px;
    background: transparent;
    color: $text-color;
    border: none;
    padding: 0;

    &:hover,
    &:focus {
      background: transparentize($gray-400, 0.5);
      color: $text-color;

      &.btn-danger {
        background: transparentize($danger, 0.8);
        color: $danger;
      }
      &.btn-warning {
        background: transparentize($warning, 0.8);
        color: $warning;
      }
      &.btn-info {
        background: transparentize($info, 0.8);
        color: $info;
      }
      &.btn-success {
        background: transparentize($success, 0.8);
        color: $success;
      }
      &.btn-primary {
        background: transparentize($primary, 0.8);
        color: $primary;
      }
    }
  }
  .btn.btn--no-max-width {
    width: unset;
    max-width: unset;
  }
}
