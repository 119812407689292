@font-face {
  font-family: 'BrixSans';
  src: url('../../assets/fonts/BrixSansBold.eot');
  src: url('../../assets/fonts/BrixSansBold.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/BrixSansBold.ttf') format('truetype'),
       url('../../assets/fonts/BrixSansBold.woff') format('woff'),
       url('../../assets/fonts/BrixSansBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BrixSans';
  src: url('../../assets/fonts/BrixSansExtraLight.eot');
  src: url('../../assets/fonts/BrixSansExtraLight.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/BrixSansExtraLight.ttf') format('truetype'),
       url('../../assets/fonts/BrixSansExtraLight.woff') format('woff'),
       url('../../assets/fonts/BrixSansExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BrixSans';
  src: url('../../assets/fonts/BrixSansMedium.eot');
  src: url('../../assets/fonts/BrixSansMedium.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/BrixSansMedium.ttf') format('truetype'),
       url('../../assets/fonts/BrixSansMedium.woff') format('woff'),
       url('../../assets/fonts/BrixSansMedium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KarmaticArcade';
  src: url('../../assets/fonts/ka1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$default-font-family: system-ui, -asystem, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
$modern-paragraph-font-family: "Titillium Web", Verdana, Geneva, Tahoma, sans-serif;
$title-font-family: "BrixSans", sans-serif;
$offcar-heading: "Source Sans Pro", Verdana, Geneva, Tahoma, sans-serif;

.text-primary {
  color: $primary !important;
}

.text-tertiary {
  color: $tertiary !important;
}

%great-heading {
  line-height: 65px;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 45px;
}
