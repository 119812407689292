body.landing #blog-posts {
  .card-img-top {
    height: 215px;
    object-fit: cover;
  }
}

body.landing .screen {
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
  box-shadow: 0px 6px 14px -5px hsla(169, 100%, 11%, 0.22), 0 0 3px hsla(169, 100%, 11%, 0.22);
}

body.landing #engine-title {
  display: flex;
  align-items: center;

  #engine-title__logo {
    img {
      @include size(32px);
    }
  }

  #engine-title__name {
    color: $white;
  }

  #engine-title__logo + #engine-title__name {
    font-size: 18px;
    margin-left: 10px;
  }
}

body.landing #engine-logo--big {
  filter: drop-shadow(0px 6px 18px hsla(169, 100%, 11%, 0.22));
  img {
    max-height: 240px;
  }
}

body.landing .hero-container {
  background-image: url("../images/landings/hexagon_15deg.svg");
  background-repeat: no-repeat;
  background-position: 65% bottom;
  background-size: 400%;
}

body.landing #hero {
  h1,
  p.lead {
    color: $white;
    text-shadow: 1px 1px 3px rgba(#005548, 0.3);
  }
}

.fonts-loaded--titillium {
  body.landing {
    .block {
      .btn.btn--cta {
        font-family: $modern-paragraph-font-family;
      }
    }
  }
}

body.landing {
  .btn {
    border-radius: 10px;
  }

  .btn.btn--cta {
    font-weight: bold;
    text-transform: uppercase;
    font-family: $default-font-family;

    &.btn--special {
      background: linear-gradient(45deg, #ec8f23, #ffc646);
      box-shadow: 0 4px 14px rgba(255, 155, 41, 0.3);
      border: none;
      transition: all 0.2s ease-in-out, opacity 0.4s ease-in-out, color 0.5s ease-in-out;
      color: $white;

      &:hover {
        // background: linear-gradient(45deg, hsl(49, 100%, 94%), white);
        // box-shadow: 0 4px 28px rgba(255, 155, 41, 0.45)
        box-shadow: 0 4px 28px rgba(255, 155, 48, 0.55), 0 8px 14px -8px rgba(0, 0, 0, 0.1);
        // color: orange;
      }
    }
  }
}

.fonts-loaded--titillium {
  body.landing {
    p {
      font-family: $modern-paragraph-font-family;
    }
  }
}

body.landing {
  h1 {
    font-size: 46px;
  }

  p.lead {
    font-size: 24px;

    &.lead--subtitle {
      color: $subtitle-gray;
      font-weight: bolder;
    }
  }

  p {
    font-size: 18px;
  }

  p {
    font-family: $default-font-family;
  }
}

body.landing {
  h2 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 10px;
  }
}

@include media-breakpoint-down(sm) {
  body.landing #engine-title {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

@include media-breakpoint-up(sm) {
  body.landing #hero p.lead span {
    /* NOTE: this makes text inside span tags
             go to new line */
    display: block;
  }
}

body.landing .cta-section {
  background: whitesmoke;
}

body.landing .cta-section span.new-line--md-only {
  /* NOTE: this makes text inside span tags
           go to new line */
  display: inline;
}

@include media-breakpoint-only(md) {
  body.landing .cta-section span.new-line--md-only {
    /* NOTE: this makes text inside span tags
             go to new line */
    display: block;
  }
}

@include media-breakpoint-up(md) {
  body.landing #engine-title {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  body.landing .cta-section span {
    /* NOTE: this makes text inside span tags
             go to new line */
    display: block;
  }
}

@include media-breakpoint-up(xl) {
  body.landing #hero {
    > [class*="col-"]:first-child {
      max-width: 600px;
    }
    > [class*="col-"]:nth-child(2) {
      max-width: 1200px;
    }
  }
}
