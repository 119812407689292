body.page--san-marco-2-dicembre
  overflow-x: hidden

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
    color: #485653

  .image
    &.image--rounded
      border-radius: 6px
      overflow: hidden

    &.image--shadow
      box-shadow: 0px 6px 14px -5px hsla(169, 100%, 11%, 0.22)

  .row.row--edge-to-edge
    position: relative
    // position: absolute
    margin-left: -50vw
    left: 50%
    width: 100vw
    height: 100%

  .row--green-bg
    background: #E4F4F0

    .image
      &.image--shadow
        box-shadow: 0px 12px 17px -10px hsla(170deg, 89%, 11%, 0.41)

  #san-marco-group-image
    display: flex
    align-items: center
    justify-content: center

  #heading-subscribe-row
    display: flex
    align-items: center
    padding: 40px 0

body.page--san-marco-2-dicembre
  +media-breakpoint-down(sm)
    .h1--smaller
      font-size: 27px

    p.lead
      font-size: 16px

    #san-marco-group-image
      img
        width: 70%

    #people-with-computers-image
      position: relative
      z-index: 1
      margin-bottom: -70px

    #heading-subscribe-row
      .btn
        margin-top: 25px

    #first-post
      display: grid
      grid-template-areas: 'icon' 'first-question' 'second-question'
      width: 80%
      margin: 50px auto

      #icon
        grid-area: icon
        display: flex
        align-items: center
        justify-content: center
        font-size: 92px
        height: 150px
        border-top: 6px solid #E4F4F0
        color: #E4F4F0

      .question
        padding: 20px 35px

      #first-question
        grid-area: first-question
        background: #E4F4F0

      #second-question
        grid-area: second-question
        background: darken(#E4F4F0, 10%)

    .js-slick-carousel
      img
        height: 100px
        object-fit: scale-down

      .js-slide

        .card
          height: 100%
          width: 90%
          margin-left: auto
          margin-right: auto

        figure
          display: flex
          align-items: center
          justify-content: center

    .js-slick-carousel__background
      opacity: 0

body.page--san-marco-2-dicembre
  +media-breakpoint-down(md)

body.page--san-marco-2-dicembre
  +media-breakpoint-up(md)
    .h1--smaller
      font-size: 32px

    #san-marco-group-image
      padding-top: 40px
      padding-bottom: 30px

      img
        width: 50%

    #people-with-computers-image
      position: relative
      z-index: 1
      margin-bottom: -70px

    #first-post
      display: grid
      grid-template-areas: 'icon first-question' 'icon second-question'
      width: 70%
      margin: 50px auto

      #icon
        grid-area: icon
        display: flex
        align-items: center
        justify-content: center
        font-size: 92px
        width: 150px
        border-left: 6px solid #E4F4F0
        color: #E4F4F0

      .question
        padding: 20px 35px

      #first-question
        grid-area: first-question
        background: #E4F4F0

      #second-question
        grid-area: second-question
        background: darken(#E4F4F0, 10%)

    .js-slick-carousel
      display: flex
      justify-content: space-between

      img
        height: 100px
        object-fit: scale-down

      .js-slide
        width: 30%
        font-size: 14px
        height: 100%

        .card
          height: 100%

        figure
          display: flex
          align-items: center
          justify-content: center

