body.page--leanbit-home {
  h2 {
    font-size: 62px;
    font-weight: 700;
  }

  p.lead {
    font-size: 28px;
  }
}

body.page--leanbit-home {
  #home-carousel {
    position: relative;
    z-index: z(home-carousel);

    .carousel-caption {
      width: 48%;
      text-align: left;
      top: 35%;
      height: auto;
      left: 10%;
      transform: translateY(-50%);
    }

    h2 {
      line-height: 1.2;
      font-size: 4vw;
      font-family: $modern-paragraph-font-family;
      font-weight: 300;
      letter-spacing: 2px;
      margin-bottom: 20px;
      width: 90%;
    }

    p.lead {
      font-size: 24px;
      width: 90%;
      // margin-bottom: 45px;
    }

    .btn--cta {
      margin-top: 20px;
    }
  }

  .container--screen-tall {
    min-height: calc(100vh - var(--header-height));
  }
}

body.page--leanbit-home {
  #nosco-platform {
    position: relative;
    background: linear-gradient(-180deg, #f0f0f0, $white);
    z-index: z(home-nosco-platform);

    h2,
    p,
    .btn {
      position: relative;
    }

    #nosco-interface-1 {
      position: absolute;
      width: 35%;
      bottom: -10%;
      left: -10%;

      img {
        width: 100%;
      }
    }

    #nosco-interface-2 {
      position: absolute;
      top: -20%;
      right: 0px;

      img {
        width: 100%;
      }
    }
  }
}

body.page--leanbit-home {
  #consulting {
    position: relative;
    background: $teal--light-300;

    .content {
      z-index: 1;
    }

    #leanbit-abstract-3d {
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: 0;
      width: unquote("min(40vw, 40vh)");

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

body.page--leanbit-home {
  #cloud {
    position: relative;
    z-index: z(home-cloud);

    #cloud-girl {
      position: absolute;
      width: 35%;
      bottom: -20%;
      left: -3%;

      img {
        width: 100%;
      }
    }

    #cloud-image {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);

      img {
        width: 100%;
      }
    }
  }
}

body.page--leanbit-home {
  #blog-posts {
    --card-img-top-height: 20vw;

    background: $teal--light-200;

    .card-deck {
      margin-top: calc(-1 * var(--card-img-top-height));
      width: 100%;
    }

    .card-wrapper {
      flex: 1 1 calc(100% / 4 - 10px);
    }

    .card {
      .card-img-top {
        height: var(--card-img-top-height);
        position: relative;

        &::before {
          content: "";
          display: flex;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: inset 0px 0px 40px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px 0px $teal--light-200;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .card-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 400;
      }

      .card-text {
        font-size: 14px;
      }

      .card-body {
        // background: #f6f9f9;
        // border-top: 1px solid $teal--light-200;
        // margin-top: -1px;
      }
    }
  }
}

/*---- DESKTOP XL ----*/
@include media-breakpoint-up(xl) {
  body.page--leanbit-home {
    #blog-posts {
      --card-img-top-height: 18vw;

      .card-deck {
        margin-top: calc(-1 * var(--card-img-top-height));
        width: 90%;
      }

      .card-wrapper {
        flex: 1 1 calc(100% / 4 - 10px);
      }

      .card {
        .card-img-top {
          height: var(--card-img-top-height);
        }
      }
    }
  }
}
/*---- END DESKTOP XL ----*/

/*---- TABLET DOWN ----*/
@include media-breakpoint-down(md) {
  body.page--leanbit-home {
    #home-carousel {
      .carousel-caption {
        width: 50%;
      }

      h2 {
        line-height: 30px;
        width: 100%;
        font-size: 28px;
      }

      p.lead {
        width: 90%;
        font-size: 18px;
      }

      .btn--cta {
        margin-top: 0px;
      }
    }

    #nosco-platform {
      #nosco-interface-2 {
        width: 50%;
      }
    }

    #consulting {
      .content {
        // margin-bottom: 380px;
      }
    }

    #blog-posts {
      --card-img-top-height: 30vw;

      .card-deck {
        margin-top: calc(-1 * var(--card-img-top-height));
        width: 90%;
      }

      .card-wrapper {
        flex: 1 1 calc(100% / 2 - 10px);
      }

      .card {
        .card-img-top {
          height: var(--card-img-top-height);
        }
      }
    }
  }
}
/*---- END TABLET DOWN ----*/

/*---- TABLET LANDSCAPE ----*/
@media (min-width: map_get($grid-breakpoints, md)) and (max-width: map_get($grid-breakpoints, lg)) and (orientation: landscape) {
  body.page--leanbit-home {
    #nosco-platform {
      padding-bottom: 250px !important;

      #nosco-interface-2 {
        width: 30%;
      }
    }

    #consulting {
      padding-top: 100px !important;
      padding-bottom: 150px !important;
    }
  }
}
/*---- END TABLET LANDSCAPE ----*/

/*---- MOBILE ----*/
@include media-breakpoint-down(sm) {
  body.page--leanbit-home {
    h2 {
      font-size: 42px;
      font-weight: 700;
    }

    p.lead {
      font-size: 28px;
    }

    #consulting {
      #leanbit-abstract-3d {
        width: unquote("min(60vw, 60vh)");
      }
    }
  }

  body.page--leanbit-home {
    #home-carousel {
      #carousel-solutions,
      #carousel-cloud {
        background: linear-gradient(90deg, #5da99c, #69beaf, #69beaf);
      }

      .carousel-caption {
        position: relative;
        width: 90%;
        text-align: left;
        top: 0px;
        left: 0px;
        height: auto;
        transform: none;
        margin: 0 auto;
        padding-bottom: calc(20px + var(--indicators-panel-height));

        h2 {
          font-size: 28px;
        }

        p.lead {
          font-size: 18px;
        }

        .btn--cta {
          margin-top: 20px;
        }
      }

      #carousel-nosco {
        img {
          right: 10%;
        }
      }

      #carousel-solutions {
        img {
          height: 40%;
        }
      }
    }

    #blog-posts {
      --card-img-top-height: calc(400px - 5vw);

      .card-deck {
        margin-top: calc(-1 * var(--card-img-top-height));
        width: calc(400px - 5vw);
      }

      .card-wrapper {
        flex: 1 1 calc(100% / 2 - 10px);
      }

      .card {
        .card-img-top {
          height: var(--card-img-top-height);
        }
      }
    }
  }
}
/*---- END MOBILE ----*/

/*---- MOBILE LANDSCAPE ----*/
@media (max-width: map_get($grid-breakpoints, md)) and (orientation: landscape) {
  body.page--leanbit-home {
    #home-carousel {
      .carousel-caption {
        width: 70%;

        h2 {
          font-size: 28px;
        }

        p.lead {
          width: 70%;
          font-size: 18px;
        }

        .btn--cta {
          margin-top: 20px;
        }
      }

      #carousel-nosco {
        img {
          height: 80%;
          right: 5%;
        }
      }

      #carousel-solutions {
        img {
          height: 60%;
        }
      }

      #carousel-cloud {
        img {
          height: 80%;
        }
      }
    }
  }
}
/*---- END MOBILE LANDSCAPE ----*/

/*---- MOBILE XS ----*/
@include media-breakpoint-down(xs) {
  body.page--leanbit-home {
    #home-carousel {
      #carousel-nosco {
        img {
          right: calc(10% - 50px);
        }
      }

      .carousel-caption {
        width: 90%;
      }
    }
  }
}
/*---- END MOBILE XS ----*/
